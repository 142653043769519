@use '../base/mixins';
@use '../base/variables';

.staff_listing {
	padding: 3rem 1.5rem;

	h2 {
		margin-bottom: 2.25rem;
		@include mixins.flex(1 1 100%);
	}

	$breakpoint_staff_to_2col : 30em;
	$breakpoint_staff_to_3col : 50em;
	.col3_staff {
		@media (min-width: $breakpoint_staff_to_2col) {
			@include mixins.flexbox();
			@include mixins.flex-wrap(wrap);
		}

		.col {
			display: block;
			width: 100%;
			margin-bottom: variables.$margin_rows;

			@media (min-width: $breakpoint_staff_to_2col) {
				@include mixins.flex(1 1 auto);
				width: calc((100% - #{variables.$margin_columns}) / 2.0001);
				max-width: calc((100% - #{variables.$margin_columns}) / 2.0001);

				&:nth-of-type(2n + 1) {
					margin-right: variables.$margin_columns;
				}
			}

			@media (min-width: $breakpoint_staff_to_3col) {
				width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);
				max-width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);

				&:nth-of-type(2n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: variables.$margin_columns;
				}
			}
		}
	}

	.staff_col {
		figure.aspect {
			padding-bottom: 106.686%;
			background-color: variables.$color_white;
			border: 1px solid variables.$color_alto;

			img {
				@include mixins.object-fit(cover, 50% 0);
			}
		}

		header {
			margin-top: 1.5rem;

			span {
				display: block;
				letter-spacing: .0316667em;
				text-align: center;

				&.title {
					color: variables.$color_comet;
					line-height: 1.5;
					@include mixins.scale(font-size, .875rem, 20em, 1.2rem, 40em);
				}

				&.position {
					color: variables.$color_dove_gray;
					font-style: italic;
					font-weight: 600;
					line-height: 1.5;
					@include mixins.scale(font-size, .75rem, 20em, 1rem, 40em);
				}

				&.company {
					color: variables.$color_dove_gray;
					font-weight: 700;
					line-height: 1.5;
					@include mixins.scale(font-size, .75rem, 20em, 1rem, 40em);
				}

				&.email {
					margin-top: .625rem;
				}

				&.phone {
					a {
						font-weight: 500;
					}
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	.staff_listing .staff_col header span:nth-of-type(1),
	.staff_listing .staff_col header span:nth-of-type(2) {
		color: variables.$color_white;
	}
}