@use '../base/variables';
@use '../base/mixins';
@use '../base/placeholders' as *;
@use 'sass:color';

/**
 *		JUST THE BASICS: OVERRIDE BASE STYLES
 */
html {
	&[data-font-size='lg'] {
		font-size: 150%;
	}

	&[data-font-size='xl'] {
		font-size: 200%;
	}
}

body {
	&[data-contrast-mode='on'] {
		color: variables.$color_white;
		background: variables.$color_black;

		a {
			color: variables.$color_white;
		}

		h1, h2, h3, h4, h5, h6 {
			color: variables.$color_white;
		}

		blockquote {
			color: variables.$color_white;

			&:before {
				background-color: variables.$color_white;
				background-image: none !important;
			}
		}
	}
}

/**
 *		TOOLBAR
 */
nav.accessibility {
	@extend %clearfix;
	display: none;
	text-align: right;
	background-color: variables.$color_comet;

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	&.is_shown {
		display: block;
	}

	a {
		display: block;
		padding: 0 1.75rem;
		color: variables.$color_white;
		font-weight: 700;
		font-size: .875rem;
		line-height: 3rem;
		letter-spacing: .03143em;
		text-align: center;
		text-transform: uppercase;
		border-bottom: 1px solid variables.$color_mulled_wine;

		@media (min-width: 30em) {
			display: inline-block;
		}

		@media (min-width: 50em) {
			height: 4rem;
			line-height: 4rem;
			border-bottom: 0;
			border-left: 1px solid variables.$color_mulled_wine;
		}

		&:hover {
			background-color: variables.$color_mulled_wine;
		}

		svg {
			position: relative;
			top: -.125rem;
			margin-left: .5rem;

			polygon {
				fill: variables.$color_white;
			}
		}
	}

	@media (min-width: 30em) {
		& > a {
			float: left;
			width: 50%;

			&:nth-of-type(1) {
				border-right: 1px solid variables.$color_mulled_wine;
			}
		}
	}

	@media (min-width: 50em) {
		line-height: 4rem;

		& > a {
			float: none;
			width: auto;
		}

		& > a:nth-of-type(-n + 2) {
			float: left;
			border-left: 0;
			border-right: 1px solid variables.$color_mulled_wine;
		}
	}

	& > div {
		position: relative;
		display: block;
		line-height: 3rem;

		@media (min-width: 30em) {
			float: left;
			width: 50%;
			text-align: center;

			&:nth-of-type(1) {
				border-right: 1px solid variables.$color_mulled_wine;
			}

			& > a {
				display: block;
				width: 100%;
			}
		}

		@media (min-width: 50em) {
			float: right;
			display: inline-block;
			width: auto;
			height: 4rem;
			line-height: 4rem;

			&:nth-of-type(1) {
				border-right: 0;
			}
		}

		ul {
			// Fix IE/Edge
			list-style: none url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
			position: absolute;
			top: 100%;
			right: 0;
			display: none;
			min-width: 100%;
			margin: 0;
			padding: 0;
			background-color: variables.$color_comet;
			z-index: 25;

			li {
				margin: 0;

				a {
					display: block;
					width: 100%;
					text-align: left;
					white-space: nowrap;
					border: 0;
					border-top: 1px solid variables.$color_mulled_wine;

					&[data-font-size=lg] {
						font-size: 1.3125rem;
					}

					&[data-font-size=xl] {
						font-size: 1.75rem;
					}
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	nav.accessibility {
		background-color: variables.$color_black;

		@media (min-width: 50em) {
			border-bottom: 1px solid variables.$color_white;
		}

		& > div ul {
			background-color: variables.$color_black;
			border: 1px solid variables.$color_white;
			border-top: 0;
		}

		& > div:nth-of-type(1) {
			border-color: variables.$color_white;
		}

		a {
			border-color: variables.$color_white;

			&:hover {
				background-color: variables.$color_black;
			}
		}
	}
}

/**
 *		ACCESSIBILITY PAGE
 */
.site_header .toggle_accessibility_toolbar {
	@include mixins.flexbox();
	@include mixins.flex-wrap(wrap);
	@include mixins.justify-content(center);

	@media (min-width: 35em) {
		@include mixins.flex-wrap(nowrap);
		@include mixins.justify-content(flex-start);
	}

	& > svg {
		margin-right: 1rem;
	}

	& > span {
		font-weight: 600;
		@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 35em);
	}

	label {
		width: auto;
		margin: 2rem 0 0;
		cursor: pointer;
		@include mixins.flex(1 1 100%);
		@include mixins.flexbox();
		@include mixins.justify-content(center);

		@media (min-width: 35em) {
			margin-top: 0;
			margin-left: 2rem;
			@include mixins.flex(0 1 auto);
		}

		span {
			font-weight: 700;
			@include mixins.flex(0 1 2.25rem);


			&:nth-of-type(1) {
				color: variables.$color_steel_gray;
			}

			&:nth-of-type(2) {
				color: variables.$color_white;
			}
		}

		input {
			position: absolute;
			opacity: 0;
		}

		.toggle {
			position: relative;
			min-width: 5.25rem;
			width: 5.25rem;
			height: 1.875rem;
			margin: 0 1rem;
			background: variables.$color_steel_gray;
			border-radius: 100rem;

			.switch {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 2.4375rem;
				height: 2.4375rem;
				background: variables.$color_white;
				border-radius: 50%;
				@include mixins.transform(translate(-110%, -50%));
				@include mixins.transition(.25s all);
				@include mixins.flexbox();
				@include mixins.justify-content(center);
				@include mixins.align-items(center);

				svg {
					&:nth-of-type(2) {
						display: none;
					}
				}
			}
		}

		input:checked + .toggle .switch {
			@include mixins.transform(translate(10%, -50%));

			svg {
				&:nth-of-type(1) {
					display: none;
				}

				&:nth-of-type(2) {
					display: block;
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	.site_header .toggle_accessibility_toolbar label {
		span:nth-of-type(1) {
			color: variables.$color_white;
		}

		.toggle {
			background-color: variables.$color_black;
			border: 1px solid variables.$color_white;
		}
	}
}

.page_accessibility {
	@extend %clearfix;
	padding: 1rem 1.5rem 3rem 1.5rem;

	.font_sizes,
	.contrast_modes {
		text-align: center;

		@media (min-width: 45em) {
			float: left;
			width: 48%;
		}

		h2 + p {
			margin: 1.5rem 0 3rem 0;
		}

		.button {
			width: 100%;
			margin-bottom: 1.75rem;
			color: variables.$color_dusty_gray;
			text-transform: none;
			background: variables.$color_white;
			border: 1px solid variables.$color_alto;
			box-shadow: 0 .1875rem .5rem variables.$color_alto;

			&.active {
				color: variables.$color_white;
				background: variables.$color_mantis;
				border-color: variables.$color_mantis;
			}

			&:hover {
				color: variables.$color_white;
				background: color.adjust(variables.$color_mantis, $lightness: -12%);
				border-color: color.adjust(variables.$color_mantis, $lightness: -12%);
			}
		}
	}

	@media (min-width: 45em) {
		.contrast_modes {
			margin-left: 4%;
		}
	}

	button {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	& + article {
		padding-top: 1.5rem !important;
		background-color: variables.$color_alabaster;
		border-top: 1px solid variables.$color_alto;
	}
}

body[data-contrast-mode=on] {
	.page_accessibility {
		& + article {
			background-color: variables.$color_black;
		}
	}
}