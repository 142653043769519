@use '../base/mixins';
@use '../base/variables';

body.proclaim {
	.provider_search_form + .ctas {
		border-top: 1px solid variables.$color_alto;

		.cta {
			@media (min-width: 50em) {
				@include mixins.flex(1 1 50%);
			}
		}

		p {
			@include mixins.scale(font-size, .87rem, 20rem, 1rem, 32rem);

			&.comet {
				margin-bottom: .5rem;
				color: variables.$color_comet;
			}
		}

		a {
			color: inherit;
			font-weight: 400;

			&:hover,
			&:focus {
				color: variables.$color_carrot_orange;
			}
		}
	}

	.site_footer .max_content_width .flex {
		@media (min-width: 35em) {
			@include mixins.flexbox();
			@include mixins.justify-content(space-between);
			@include mixins.align-items(center);
		}

		.sitemap {
			@media (min-width: 35em) {
				@include mixins.column-count(1);
				text-align: left;
			}

			@media (min-width: 54em) {
				@include mixins.column-count(1);
			}

			@media (min-width: 72em) {
				@include mixins.column-count(1);
			}
		}

		.back {
			padding-bottom: 3rem;
			text-align: center;
		}
	}
}