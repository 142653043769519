@use '../base/variables';

body.contact .ctas {
	border-top: 1px solid variables.$color_alto;
}

.page_contact {
	padding: 3rem 1.5rem 1.5rem 1.5rem;
	background-color: variables.$color_alabaster;

	@media (min-width: 45em) {
		label.text {
			float: left;
			width: 48%;

			&:nth-of-type(odd) {
				clear: both;
				margin-right: 4%;
			}
		}

		label.textarea {
			clear: both;
			float: left;
		}
	}

	& + .faq_listing {
		padding-bottom: 4rem;
		background-color: variables.$color_white;
		border-top: 1px solid variables.$color_alto;
	}

	.g-recaptcha {
		clear: both;
		margin-bottom: 1.5rem;
	}
}

body[data-contrast-mode=on] {
	body.contact .ctas {
		border-color: variables.$color_white;
	}

	.page_contact {
		background-color: variables.$color_black;
		border-top: 1px solid variables.$color_white;

		label {
			color: variables.$color_white;
		}

		& + .faq_listing {
			background-color: variables.$color_black;
			border-color: variables.$color_white;
		}
	}
}