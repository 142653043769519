@use '../base/mixins';
@use '../base/variables';
@use 'sass:color';

.site_footer {
	padding: 0 1.5rem;
	color: variables.$color_white;
	background-color: color.adjust(variables.$color_comet, $hue: -0.29376deg, $saturation: -5.03937 * 1%, $lightness: 7.64706 * 1%);

	$breakpoint_columns_to2: 35rem;
	$breakpoint_columns_to3: 54rem;
	$breakpoint_columns_to4: 72rem;

	.sitemap {
		padding: 2.5rem 0 1.5rem 0;

		@media (min-width: $breakpoint_columns_to2) {
			@include mixins.column-count(2);
		}

		@media (min-width: $breakpoint_columns_to3) {
			@include mixins.column-count(3);
		}

		@media (min-width: $breakpoint_columns_to4) {
			@include mixins.column-count(4);
		}

		div {
			margin-bottom: .5rem;
			padding-bottom: .5rem;

			&:not(:last-of-type) {
				border-bottom: 1px solid variables.$color_rum;
			}

			@media (min-width: $breakpoint_columns_to2) {
				padding-bottom: 1rem;
				@include mixins.break-inside(avoid);

				&:not(:last-of-type) {
					border-bottom: 0;
				}
			}

			header {
				margin-bottom: .3125rem;
				@include mixins.flexbox();
				@include mixins.justify-content(space-between);
				@include mixins.align-items(center);

				@media (min-width: $breakpoint_columns_to2) {
					@include mixins.justify-content(center);
				}

				@media (min-width: $breakpoint_columns_to3) {
					@include mixins.justify-content(flex-start);
				}

				a {
					color: variables.$color_white;
					font-weight: 400;
					font-size: 1.125rem;
					letter-spacing: .040625em;
					text-transform: none;
					cursor: pointer;

					&:hover {
						color: variables.$color_charade;
					}

					&.toggle {
						padding-left: 1.25rem;

						svg {
							@include mixins.transition(.25s all);

							path {
								fill: variables.$color_white;
							}
						}

						&.on {
							svg {
								@include mixins.transform(rotate(180deg));
							}
						}
					}

					@media (min-width: $breakpoint_columns_to2) {
						&.toggle {
							display: none;
						}
					}
				}
			}

			ul {
				// Fix IE/Edge
				list-style: none url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				display: none;
				margin: 0;
				padding: 0;

				@media (min-width: $breakpoint_columns_to2) {
					display: block !important;
					text-align: center;
				}

				@media (min-width: $breakpoint_columns_to3) {
					text-align: left;
				}
			}

			a {
				display: block;
				padding: .1875rem 0;
				color: variables.$color_athens_gray;
				font-weight: 700;
				font-size: .875rem;
				line-height: 2;
				letter-spacing: .0275em;
				text-transform: uppercase;

				&.address {
					margin-bottom: .25rem;
					padding-bottom: .25rem;
					border-bottom: 1px solid rgba(white, .2);
				}

				@media (min-width: $breakpoint_columns_to2) {
					padding: 0;
					font-size: .75rem;
					text-align: center;
				}

				@media (min-width: $breakpoint_columns_to3) {
					text-align: left;
				}

				&:hover {
					color: variables.$color_charade;
				}

				&[data-before]:before {
					content: attr(data-before);
					font-weight: 400;
					text-transform: none;
				}

				&[href^=mailto] {
					text-transform: lowercase;
				}
			}

			&:last-of-type {
				text-align: center;

				header {
					@include mixins.justify-content(center);

					@media (min-width: $breakpoint_columns_to3) {
						@include mixins.justify-content(flex-start);
					}
				}
			}
		}
	}

	.accred_link {
		font-size: .75rem;
		line-height: 1.5;
		padding: 1rem 0;
		text-align: center;

		@media (min-width: 54em) {
			text-align: left;
		}

		a {
			display: inline-block;
			color: variables.$color_white;
			font-size: .75rem;
			line-height: 1.5;

			svg {
				width: .6875rem;
				height: .625rem;
				margin-left: .25rem;

				path {
					fill: variables.$color_white;
					@include mixins.transition(.25s all);
				}
			}

			&:hover {
				color: variables.$color_charade;

				svg path {
					fill: variables.$color_charade;
				}
			}
		}
	}

	$breakpoint_credits: 45em;

	.credits {
		padding: .75rem 0 1.375rem 0;
		text-align: center;
		border-top: 1px solid rgba(variables.$color_white, .12);

		@media (min-width: $breakpoint_credits) {
			@include mixins.flexbox();
			@include mixins.align-items(center);
		}

		span {
			display: block;
			margin-bottom: 1rem;
			font-size: .75rem;
			letter-spacing: .0275em;

			@media (min-width: $breakpoint_credits) {
				margin-bottom: 0;
			}
		}

		a {
			@media (min-width: $breakpoint_credits) {
				display: block;

				&:first-of-type {
					margin-left: auto;
				}
			}

			svg {
				@media (min-width: $breakpoint_credits) {
					display: block;
				}

				path {
					@include mixins.transition(.25s all);
				}
			}

			&:hover {
				svg {
					path:nth-of-type(1) {
						fill: variables.$color_white;
					}

					path:nth-of-type(2) {
						fill: variables.$color_comet;
					}
				}
			}

			& + a {
				margin-left: .75rem;
			}
		}
	}

}

body[data-contrast-mode=on] {
	.site_footer {
		background-color: variables.$color_black;
		background-image: none;
		border-top: 1px solid variables.$color_white;

		.sitemap div a:hover {
			color: variables.$color_white;
		}

		.accred_link a:hover {
			color: variables.$color_white;

			svg path {
				fill: variables.$color_white;
			}
		}

		.credits a:hover svg path:nth-of-type(2) {
			fill: variables.$color_black;
		}
	}
}