@use '../base/variables';
@use '../base/mixins';

.ctas {
	background-color: variables.$color_white;

	@media (min-width: 50em) {
		@include mixins.flexbox();
	}

	.cta {
		padding-top: 5.5rem;
		text-align: center;
		padding-bottom: 5.5rem;
		@include mixins.scale(padding-right, 1.5rem, 20em, 4rem, 50em);
		@include mixins.scale(padding-left, 1.5rem, 20em, 4rem, 50em);


		@media (min-width: 50em) {
			padding: 5.5rem 2.5rem;
			@include mixins.flex(1 1 auto);
		}

		header {
			margin-bottom: .625rem;
			color: variables.$color_comet;
			font-size: 1.75rem;
			letter-spacing: .0314em;
		}

		p {
			max-width: 36.25rem;
			margin: 0 auto;
			font-size: 1.125rem;
			line-height: 1.5556em;
		}

		.button {
			margin-top: 2.75rem;
			cursor: pointer;
		}

		& + .cta {
			border-top: 1px solid variables.$color_alto;

			@media (min-width: 50em) {
				border-top: 0;
				border-left: 1px solid variables.$color_alto;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.ctas {
		background-color: variables.$color_black;

		.cta {
			color: variables.$color_white;

			header {
				color: variables.$color_white;
			}

			& + .cta {
				border-color: variables.$color_white;

				@media (min-width: 50em) {
					border-color: variables.$color_white;
				}
			}
		}
	}
}