@use '../base/variables';
@use '../base/mixins';
@use 'sass:color';

#request_quote_form {
	max-width: 68rem;
	margin: 0 auto;
	padding: 10vw 1.5rem 8vw 1.5rem;
	text-align: center;

	header {
		color: variables.$color_comet;
		line-height: 1.238;
		@include mixins.scale(font-size, 2rem, 20em, 2.625rem, 40em);
	}

	.error {
		clear: both;
		color: variables.$color_monza;
		font-weight: 600;
		font-style: italic;
	}

	.selected_values {
		list-style: none;
		margin: 1rem auto;
		padding: 0;

		li {
			font-style: italic;

			&:before {
				content: '\2022';
				padding: 0 1rem 0 0;
			}

			@media (min-width: 36em) {
				display: inline-block;

				&:before {
					padding: 0 1rem;
				}

				&:first-of-type:before {
					content: '';
					padding: 0;
				}
			}

			span {
				color: variables.$color_carrot_orange;
			}
		}
	}

	label {
		width: auto;
		margin: 2rem 0;
		@include mixins.flexbox();
		@include mixins.flex-direction(column);
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		@media (min-width: 32em) {
			@include mixins.flex-direction(row);
		}

		span {
			width: auto;
			max-width: 100%;
			margin: 0;
			padding: 0 .75rem;
			color: variables.$color_dove_gray;
			@include mixins.scale(font-size, 1rem, 20em, 1.375rem, 40em);
		}

		select,
		input {
			max-width: 12.5rem;
			color: variables.$color_carrot_orange;
			border: 0;
			border-bottom: 1px dashed variables.$color_alto;
			border-radius: 0;
			@include mixins.scale(font-size, 1rem, 20em, 1.375rem, 40em);
		}
	}

	.slider_wrapper {
		margin: 3rem 0;
		@include mixins.flex-direction(column);

		input[type=range] {
		    /*removes default webkit styles*/
		    -webkit-appearance: none;

		    /*fix for FF unable to apply focus style bug */
		    border: 1px solid variables.$color_white;

		    /*required for proper track sizing in FF*/
		    width: 100%;
			max-width: 36rem;

			margin: 2rem 0;
		}

		input[type=range]::-webkit-slider-runnable-track {
			width: 100%;
			max-width: 36rem;
		    height: .3125rem;
		    background-color: variables.$color_mercury;
		    border: none;
		    border-radius: .1875rem;
		}

		input[type=range]::-webkit-slider-thumb {
		    -webkit-appearance: none;
		    border: none;
		    height: 1.25rem;
		    width: 1.25rem;
		    border-radius: 50%;
		    background-color: variables.$color_carrot_orange;
		    margin-top: -.5rem;
		}

		input[type=range]:focus {
		    outline: none;
		}

		input[type=range]:focus::-webkit-slider-runnable-track {
		    background-color: variables.$color_mercury;
		}

		input[type=range]::-moz-range-track {
			width: 100%;
			max-width: 36rem;
		    height: .3125rem;
		    background-color: variables.$color_mercury;
		    border: none;
		    border-radius: 3px;
		}

		input[type=range]::-moz-range-thumb {
		    border: none;
		    height: 1.25rem;
		    width: 1.25rem;
		    border-radius: 50%;
		    background-color: variables.$color_carrot_orange;
		}

		/*hide the outline behind the border*/
		input[type=range]:-moz-focusring{
		    outline: 1px solid variables.$color_white;
		    outline-offset: -1px;
		}

		input[type=range]::-ms-track {
			width: 100%;
			max-width: 36rem;
		    height: .3125rem;

		    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
		    background: transparent;

		    /*leave room for the larger thumb to overflow with a transparent border */
		    border-color: transparent;
		    border-width: .75rem 0;

		    /*remove default tick marks*/
		    color: transparent;
		}

		input[type=range]::-ms-fill-lower {
		    background-color: variables.$color_mercury;
		    border-radius: .625rem;
		}

		input[type=range]::-ms-fill-upper {
		    background-color: variables.$color_mercury;
		    border-radius: .625rem;
		}

		input[type=range]::-ms-thumb {
		    border: none;
		    height: 1.25rem;
		    width: 1.25rem;
		    border-radius: 50%;
		    background: variables.$color_carrot_orange;
			margin-top: -.25rem;
		}

		input[type=range]:focus::-ms-fill-lower {
		    background-color: variables.$color_mercury;
		}

		input[type=range]:focus::-ms-fill-upper {
		    background-color: variables.$color_mercury;
		}

		.range_label {
			width: 100%;
			max-width: 36rem;
			margin: -2rem auto 2rem auto;
			color: variables.$color_dove_gray;
			font-size: .75rem;
			@include mixins.flexbox();
			@include mixins.justify-content(space-between);

			div {
				text-align: center;
				@include mixins.flex(0 0 4rem);

				&:first-of-type {
					position: relative;
					left: -.375rem;
				}

				&:last-of-type {
					position: relative;
					right: -.375rem;
				}

				@media (min-width: 30em) {
					@include mixins.flex(0 0 8rem);

					&:first-of-type {
						left: -2.375rem;
					}

					&:last-of-type {
						right: -2.375rem;
					}
				}
			}
		}

		.slider_note {
			width: 100%;
			max-width: 36rem;
			margin: 0 auto;
			@include mixins.scale(font-size, .75rem, 20em, .875rem, 40em);
		}
	}

	.individual_note {
		max-width: 26.25rem;
		margin-right: auto;
		margin-left: auto;
		color: variables.$color_comet;
		font-style: italic;
	}

	button {
		display: inline-block;
		margin-top: 1rem;
		margin-bottom: 3rem;
		background-color: variables.$color_mantis;
		border-color: variables.$color_mantis;

		&.disabled {
			background-color: color.adjust(variables.$color_mantis, $lightness: 20%);
			cursor: not-allowed;
		}

		&:not(.disabled):hover {
			background-color: color.adjust(variables.$color_mantis, $lightness: -12%);
			border-color: color.adjust(variables.$color_mantis, $lightness: -12%);
		}
	}

	.button {
		margin-bottom: 3rem;
	}

	& + .ctas {
		border-top: 1px solid variables.$color_alto;
	}
}

#request_quote_contact_form {
	padding: 10vw 1.5rem 8vw 1.5rem;

	br {
		clear: both;
	}

	.error {
		clear: both;
		color: variables.$color_monza;
		font-weight: 600;
	}

	.clear_renewal_date {
		position: absolute;
		right: 0;
		bottom: 0;
		margin: .4375rem;
		padding: 0 .5rem;
		line-height: 2rem;
		text-align: center;
		border: 1px solid variables.$color_alto;
		border-radius: .25rem;
	}

	button {

		margin: 2rem auto;
		background-color: variables.$color_mantis;
		border-color: variables.$color_mantis;

		&.disabled {
			background-color: color.adjust(variables.$color_mantis, $lightness: 20%);
			cursor: not-allowed;
		}

		&:not(.disabled):hover {
			background-color: color.adjust(variables.$color_mantis, $lightness: -12%);
			border-color: color.adjust(variables.$color_mantis, $lightness: -12%);
		}
	}
}

body[data-contrast-mode=on] {
	#request_quote_form {
		header,
		label span,
		.slider_note,
		.individual_note,
		.range_label {
			color: variables.$color_white;
		}

		select {
			background-color: variables.$color_black;
			background-image: url('../images/chevron_down_white.svg');
		}

		input[type=range] {
			background-color: variables.$color_black;
			border-color: variables.$color_black;
		}

		input[type=range]::-webkit-slider-runnable-track,
		input[type=range]:focus::-webkit-slider-runnable-track,
		input[type=range]::-moz-range-track,
		input[type=range]::-ms-fill-lower,
		input[type=range]::-ms-fill-upper,
		input[type=range]:focus::-ms-fill-lower,
		input[type=range]:focus::-ms-fill-upper {
			background-color: variables.$color_white;
		}

		& + .ctas {
			border-color: variables.$color_white;
		}
	}
}

.quote_results {
	text-align: center;

	header {
		width: 100%;
		max-width: 56.25rem;
		margin: 0 auto;
		color: variables.$color_comet;
		line-height: 1.35714;
		@include mixins.scale(font-size, 2rem, 20em, 2.6125rem, 40em);
	}

	p {
		width: 100%;
		max-width: 56.25rem;
		margin: 2rem auto;
		font-weight: 500;
		font-size: 1.1rem;
		line-height: 1.58889;
	}

	$breakpoint_to_full_table : 40em;
	table {
		table-layout: auto;
		display: block;
		max-width: 80rem;
		border-collapse: collapse;
		margin: 5rem auto;
		border: 0;

		@media (min-width: $breakpoint_to_full_table) {
			display: table;
		}

		thead {
			display: block;
			width: 100%;

			@media (min-width: $breakpoint_to_full_table) {
				display: table-header-group;
			}

			tr {
				display: block;
				border-color: transparent;

				@media (min-width: $breakpoint_to_full_table) {
					display: table-row;
				}

				th {
					display: none;
					font-weight: 700;
					text-align: center;

					@media (min-width: $breakpoint_to_full_table) {
						display: table-cell;
					}

					&:nth-of-type(even) {
						color: variables.$color_white;
						background-color: variables.$color_kimberly;
					}

					&:nth-of-type(odd) {
						color: variables.$color_white;
						background-color: variables.$color_dolphin;
					}

					&:first-of-type {
						display: block;
						text-align: center;
						background-color: transparent;
						border: 0;

						@media (min-width: $breakpoint_to_full_table) {
							display: table-cell;
							text-align: left;
						}

						div {
							color: variables.$color_comet;
							font-weight: 400;
							font-size: 2rem;
						}

						a {
							font-size: 1rem;
						}
					}

					&:nth-of-type(2) {
						border-top-left-radius: .25rem;
					}

					&:last-of-type {
						border-top-right-radius: .25rem;
					}
				}
			}
		}

		tbody {
			display: block;

			@media (min-width: $breakpoint_to_full_table) {
				display: table-row-group;
			}

			tr {
				display: block;
				margin-top: 1.875rem;
				padding-top: 1rem;
				padding-bottom: 1rem;
				border: 1px solid variables.$color_alto;

				@media (min-width: $breakpoint_to_full_table) {
					display: table-row;
					border: 0;
				}

				&:nth-of-type(even) {
					background-color: variables.$color_alabaster;
				}

				&:last-of-type {
					background-color: variables.$color_white;
				}

				td {
					display: block;
					padding: .25rem .75rem;
					text-align: center;
					background-color: transparent;

					&:before {
						content: attr(data-label) ": ";
						padding-right: .5rem;
						font-weight: 600;
					}

					@media (min-width: $breakpoint_to_full_table) {
						display: table-cell;
						padding: 1rem .75rem;

						&:before {
							display: none;
						}
					}

					&:first-of-type {
						display: block;
						margin-bottom: .625rem;
						font-weight: 600;
						font-size: 1.125rem;
						text-align: center;

						&:before {
							display: none;
						}

						&:after {
							content: '';
							display: block;
							width: 80%;
							margin: 0 auto;
							padding: .25rem 0 .5rem 0;
							border-bottom: 1px solid variables.$color_alto;
						}

						@media (min-width: $breakpoint_to_full_table) {
							display: table-cell;
							text-align: left;

							&:after {
								display: none;
							}
						}
					}

					@media (min-width: $breakpoint_to_full_table) {
						&:not(.no_border) {
							border: 1px solid variables.$color_alto;
						}
					}
				}

				&:last-of-type {
					td:after {
						display: none;
					}
				}
			}
		}
	}
}

.quote_results.self_funded {
	padding: 10vw 1.5rem 8vw 1.5rem;
}

.ctas.self_funded {
	border-top: 1px solid variables.$color_alto;
}

body[data-contrast-mode=on] {
	.quote_results {
		header,
		table thead tr th:first-of-type div {
			color: variables.$color_white;
		}

		table {
			color: variables.$color_white;
			background-color: variables.$color_black;

			thead tr th:nth-of-type(even),
			thead tr th:nth-of-type(odd) {
				background-color: variables.$color_black;
				border: 1px solid variables.$color_white;
			}

			thead tr th:first-of-type {
				border: 0;
			}

			tbody tr:nth-of-type(even) {
				background-color: variables.$color_black;
			}
		}
	}

	.ctas.self_funded {
		border-color: variables.$color_white;
	}
}

.download_results {
	margin: 0 1.5rem;
	padding: 10vw 1.5rem 8vw 1.5rem;
	text-align: center;

	@media (min-width: 71rem) {
		max-width: 68rem;
		margin: 0 auto;
	}

	header {
		width: 100%;
		max-width: 56.25rem;
		margin: 0 auto;
		color: variables.$color_comet;
		line-height: 1.35714;
		@include mixins.scale(font-size, 2rem, 20em, 2.6125rem, 40em);
	}

	p {
		width: 100%;
		max-width: 56.25rem;
		margin: 2rem auto;
		font-weight: 500;
		font-size: 1.1rem;
		line-height: 1.58889;
	}

	label {
		text-align: left;

		@media (min-width: 40em) {
			float: left;
			width: 48%;

			&:nth-of-type(odd) {
				clear: both;
				margin-right: 4%;
			}
		}
	}

	button {
		margin-top: 2rem;
	}
}

.thank_you {
	margin: 0 1.5rem;
	padding: 10vw 1.5rem 8vw 1.5rem;
	text-align: center;

	@media (min-width: 71rem) {
		max-width: 68rem;
		margin: 0 auto;
	}

	header {
		width: 100%;
		max-width: 56.25rem;
		margin: 0 auto;
		color: variables.$color_comet;
		line-height: 1.35714;
		@include mixins.scale(font-size, 2rem, 20em, 2.6125rem, 40em);
	}

	p {
		width: 100%;
		max-width: 56.25rem;
		margin: 2rem auto;
		font-weight: 500;
		font-size: 1.1rem;
		line-height: 1.58889;
	}

	button,
	.button {
		display: inline-block;

		&:nth-of-type(2) {
			color: variables.$color_mantis;
			background-color: variables.$color_white;
			border-color: variables.$color_mantis;

			&:hover {
				color: variables.$color_white;
				background-color: variables.$color_mantis;
			}
		}
	}

	span {
		display: block;
		font-weight: 600;
		line-height: 3rem;
		text-transform: uppercase;

		@media (min-width: 40em) {
			display: inline-block;
			width: 5rem;
		}
	}
}

.quote_progress_nav {
	border-top: 1px solid variables.$color_alto;
	border-bottom: 1px solid variables.$color_alto;
	@include mixins.flexbox();
	@include mixins.flex-wrap(wrap);
	@include mixins.justify-content(space-between);

	@media (min-width: 40em) {
		@include mixins.flex-wrap(nowrap);
	}

	& > a {
		padding: 0 1rem;
		color: inherit;
		line-height: 4.5rem;
		@include mixins.flexbox();
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		@media (min-width: 40em) {
			width: 10rem;
		}

		&:hover {
			color: variables.$color_carrot_orange;
		}

		&:first-child {
			max-width: 50%;
			white-space: nowrap;
			border-right: 1px solid variables.$color_alto;
			@include mixins.order(2);
			@include mixins.flex(1 0 50%);

			@media (min-width: 40em) {
				@include mixins.order(1);
				@include mixins.flex(0 1 auto);
			}
		}

		&:last-child:not(:first-child) {
			max-width: 50%;
			white-space: nowrap;
			@include mixins.order(3);
			@include mixins.flex(1 0 50%);

			@media (min-width: 40em) {
				border-left: 1px solid variables.$color_alto;
				@include mixins.flex(0 1 auto);
			}
		}
	}

	.progress {
		height: 4.5rem;
		border-bottom: 1px solid variables.$color_alto;
		@include mixins.order(1);
		@include mixins.flex(1 0 100%);
		@include mixins.flexbox();
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		@media (min-width: 40em) {
			border-bottom: 0;
			@include mixins.order(2);
			@include mixins.flex(1 1 auto);
		}

		div {
			width: 2.375rem;
			height: 2.375rem;
			margin: .625rem;
			border: 2px solid variables.$color_silver_chalice;
			border-radius: 50%;
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			svg {
				display: none;
			}

			span {
				font-weight: 700;
				font-size: .9375rem;
			}

			&.active {
				position: relative;
				border-color: variables.$color_mantis;

				&:before {
					content: '';
					position: absolute;
					top: -1.25rem;
					left: 50%;
					width: .6875rem;
					height: .375rem;
					background: transparent url('../images/toggle-select.svg') center no-repeat scroll;
					@include mixins.transform(translateX(-50%));
				}

				span {
					color: variables.$color_mantis;
				}
			}

			&.complete {
				background-color: variables.$color_mantis;
				border-color: variables.$color_mantis;

				svg {
					display: block;
				}

				span {
					display: none;
				}
			}
		}
	}

	&.left .progress {
		@media (min-width: 40em) {
			padding-left: 1rem;
			@include mixins.justify-content(flex-start);
		}
	}
}

body[data-contrast-mode=on] {
	.quote_progress_nav {
		.progress div {
			border-color: variables.$color_white;

			&.active,
			&.complete {
				border-color: variables.$color_mantis;

				svg {
					fill: variables.$color_black;
				}
			}
		}
	}
}
