@use '../base/mixins';
@use '../base/variables';
@use 'sass:color';

$breakpoint_to_full_logo			: 40em;
$breakpoint_to_flex_row_home		: 50em;
$breakpoint_to_flex_row_interior	: 60em;
$breakpoint_to_menu_change			: 70em;
$breakpoint_to_row_of_four			: 80em;
.site_nav {
	position: relative;
	@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_black, .2));

	&.home {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 5000;
	}

	&.is_menu_shown {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 5000;
	}

	&.plan_finder {
		border-bottom: 1px solid variables.$color_alto;
		@include mixins.box-shadow(none);
	}

	&.provider_search {
		border-bottom: 1px solid variables.$color_alto;
		@include mixins.box-shadow(none);
	}

	.secondary_menu {
		background: variables.$color_home_gray;
		text-align: right;
		padding-right: 5%;
		font-size: .95rem;

		@media screen and (max-width: $breakpoint_to_flex_row_interior) {
			display: none;
		}

		a {
			color: variables.$color_jacarta;
			word-spacing: 1em;
			font-size: .8em;

			span {
				&:last-of-type {
					padding-left: .3em;
					vertical-align: text-bottom;
				}
			}

			svg {
				width: 1em;
				height: 1em;
			}

			&:hover {
				color: variables.$color_carrot_orange;

				svg path {
					fill: variables.$color_carrot_orange;
				}
			}
		}

		@media (max-width: 40em) {
			display: none;
		}
	}

	.common {
		background: variables.$color_white;
		padding-right: 5%;
		@include mixins.flexbox();
		@include mixins.justify-content(flex-end);

		.nav_container {
			align-items: center;
			justify-content: space-between;
			@include mixins.flex(1 0 auto);

			.toggle {
				display: none;

				@media (max-width:$breakpoint_to_flex_row_interior) {
					padding-top: 1.5rem;
					float: right;
					display: block;
				}

				.search {
					float: left;
				}
			}

			.nav_list {
				list-style: none;
				display: flex;
				margin-bottom: 0;
				float: right;
				padding-left: 1rem;


				@media (max-width:$breakpoint_to_flex_row_interior) {
					display: none;
				}

				.dropdown {
					margin-right: 3rem;
					padding-bottom: 1rem;
					margin-top: .25rem;

					@media (max-width:$breakpoint_to_flex_row_interior) {
						margin-right: 2rem;
					}


					.title {
						display: flex;

						@media (min-width: $breakpoint_to_flex_row_interior) and (max-width: $breakpoint_to_menu_change) {
							svg {
								height: 25px;
								width: 35px;
							}
							a {
								line-height: 1rem;
								max-width: 4rem;
							}
						}

						a {
							color: variables.$color_jacarta;
							font-size: .825rem;
							padding-right: .2rem;
						}

						svg {
							align-self: center;

						}
					}

					&:hover {
						text-decoration: underline;
						text-decoration-thickness: 2px;

						.dropdown_menu {
							display: flex;
							margin-top: 1rem;
							@include mixins.box-shadow(0 0 .25rem rgba(variables.$color_black, .2));
						}
					}
				}

				.dropdown_menu {
					display: none;
					position: absolute;
					background-color: variables.$color_white;
					width: 100%;
					height: 27rem;
					right: 0;
					z-index: 1000;

					@media(max-width:75em) {
						height: 32rem;
					}
				}

				.dropdown_menu_left {
					width: 33%;
					padding: 1rem 2rem 0 3rem;
					margin: 2rem 0;
					border-right: 1px solid variables.$color_dove_gray;

					@media(min-width:90rem) {
						padding-left: 10rem;
					}

					h2 {
						font-size: 1.25rem;
						color: variables.$color_jacarta;
						font-weight: 600;
					}

					p {
						font-size: .825rem;
						margin-bottom: 2rem;
					}

					a {
						color: variables.$color_white;
						max-width: 25%;
					}
				}

				.dropdown_menu_right {
					margin: 2rem 0 2rem 3rem;

					.menu_right_main {
						display: table-cell;

						ul {
							list-style: none;
						}

						.menu_item_content {
							padding-bottom: .5rem;
							padding-right: 25%;
							text-align: left;


							a {
								font-size: 1rem;
								color: variables.$color_jacarta;
								font-weight: 600;
								justify-content: left;

								&:hover {
									color: variables.$color_carrot_orange;
								}
							}

							svg {
								padding-left: .2rem;
								padding-top: .2rem;
							}

							p {
								font-size: .75rem;
								margin: 0;
							}
						}
					}

					.featured_links {
						border-top: 1px solid variables.$color_dove_gray;
						margin-left: 1rem;
						margin-top: 3rem;
						display: flex;
						width: 60rem;

						@media (max-width: $breakpoint_to_row_of_four) {
							width: 40rem;
						}


						> div {
							display: inline-flex;
							margin: 2rem 2rem 0 1rem;

							svg {
								height: 50px;
								width: 35px;
							}

							a {
								font-size: .825rem;
								color: variables.$color_jacarta;

								&:hover {
									color: variables.$color_carrot_orange;
								}
							}
						}
					}

					.featured_item {
						border-top: 1px solid variables.$color_dove_gray;
						margin-left: 1rem;
						margin-top: 3rem;
						display: flex;
						width: 60rem;

						@media (max-width: $breakpoint_to_row_of_four) {
							width: 40rem;
						}

						> div {
							display: inline-flex;
							margin-top: 1.5rem;
							margin-left: 1rem;

							svg {
								height: 50px;
								width: 50px;
							}

							a {
								font-size: .825rem;
								color: variables.$color_white;
							}

							h2 {
								font-size: .825rem;
							}
						}

						.featured_text {
							display: block;
							margin-top: 0;
							padding: 0 1.5rem;

							h2 {
								color: variables.$color_jacarta;
								font-weight: 600;
								margin-bottom: 0;
							}

							span {
								font-size: .75rem;
							}
						}
					}
				}
			}
		}

		.header_button, .header_outline_button {
			@media (max-width: $breakpoint_to_flex_row_interior) {
				display: none;
			}
		}

		a {
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			&:hover {
				/*padding-bottom: 10px;*/ /*height dependent on the gap you want to fill*/
			}

			&.logo {
				width: 100%;
				margin-right: auto;
				padding: .75rem;
				@include mixins.flex(1 1 35%);
				@include mixins.justify-content(flex-start);

				// IE11
				@media (-ms-high-contrast: none) {
					margin-right: 0;
				}

				svg {
					display: block;
					width: 100%;
					max-width: 3.375rem;
					max-height: 100%;

					&:nth-of-type(2) {
						display: none;
					}

					@media (min-width: $breakpoint_to_full_logo) {
						&:nth-of-type(1) {
							display: none;
						}

						&:nth-of-type(2) {
							display: block;
							max-width: 10.125rem;
						}
					}
				}
			}


			&.search,
			&.toggle {
				min-width: 4.25rem;
				padding: 0;
				cursor: pointer;
				@include mixins.flex(0 0 auto);
				@include mixins.scale(padding-right, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);
				@include mixins.scale(padding-left, .75rem, 20em, 1.5rem, $breakpoint_to_full_logo);

				&.menu {
					svg:nth-of-type(2) {
						display: none;
					}

					&.on {
						svg:nth-of-type(1) {
							display: none;
						}

						svg:nth-of-type(2) {
							display: block;
						}
					}
				}

				svg {
					path {
						@include mixins.transition(.25s all);
					}
				}

				&:hover {
					svg {
						path {
							fill: variables.$color_carrot_orange;
						}
					}
				}
			}
		}
	}

	& > .menu {
		position: absolute;
		top: 4.5rem;
		right: 0;
		bottom: 0;
		display: none;
		background-color: variables.$color_white;
		z-index: 1;
		@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_black, .2));


		.mobile_menu {
			> div {
				border-bottom: 1px solid variables.$color_home_gray;
				padding: 1rem 1rem 0 1.5rem;
			}

			header {
				a {
					margin-bottom: .3125rem;
					color: variables.$color_jacarta;
					font-size: 1.125rem;
					letter-spacing: .040625em;
					cursor: pointer;
					@include mixins.flexbox();
					@include mixins.justify-content(space-between);
					@include mixins.align-items(center);

					&.toggle {

						svg {
							margin-left: 2rem;
							@include mixins.transition(.25s all);


							path {
								fill: variables.$color_home_gray;
							}
						}

						&.on {
							svg {
								@include mixins.transform(rotate(180deg));
							}
						}
					}
				}
			}

			ul {
				// Fix IE/Edge
				list-style: none url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
				display: none;
				margin: 0;
				padding: 0;

				li {
					padding: .5rem 0;

					a {
						color: variables.$color_dove_gray;
					}
				}
			}


			.mobile_menu_logins {
				padding-top: 0;

				a {
					color: variables.$color_dusty_gray;
					font-size: 14px;
				}
			}

			.mobile_menu_buttons {
				display: grid;
				border-bottom: none;

				a {
					margin-right: 0;
/*					font-size: 1rem;*/
					font-weight: 700;
				}
			}
		}
	}

	& > .search {
		position: absolute;
		top: 4.5rem;
		right: 0;
		bottom: 0;
		display: none;
		background-color: variables.$color_white;
		z-index: 1;
		@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_black, .2));

		.mobile_search {
			> div {
				border-bottom: 1px solid variables.$color_home_gray;
				padding: 1rem 1rem 0 1.5rem;
			}

			header {
				a {
					margin-bottom: .3125rem;
					color: variables.$color_jacarta;
					font-size: 1.125rem;
					letter-spacing: .040625em;
					cursor: pointer;
					@include mixins.flexbox();
					@include mixins.justify-content(space-between);
					@include mixins.align-items(center);
				}
			}
		}
	}
}

	body[data-contrast-mode=on] {
		.site_nav {
			border-bottom: 1px solid variables.$color_white;

			.common {
				background-color: variables.$color_black;

				a.logo svg {
					path, polyline {
						fill: variables.$color_white;
					}
				}

				a.login, a.search, a.toggle {
					color: variables.$color_white;
					border-color: variables.$color_white;

					svg {
						path {
							fill: variables.$color_white;
						}
					}
				}
			}

			.menu {
				background-color: variables.$color_black;
				background-image: none;
				border-top: 1px solid variables.$color_white;


				.container {
					.custom > div {
						background-color: variables.$color_black;
						background-image: none;

						&.i_have {
							border-bottom: 1px solid variables.$color_white;

							@media (min-width: 36em) {
								border-right: 1px solid variables.$color_white;
								border-bottom: 0;
							}

							@media (min-width: $breakpoint_to_flex_row_home) {
								border-bottom: 1px solid variables.$color_white;
							}

							@media (min-width: $breakpoint_to_flex_row_interior) {
								border-bottom: 1px solid variables.$color_white;
							}
						}

						&.i_am {
							@media (min-width: $breakpoint_to_flex_row_home) {
								border-bottom: 1px solid variables.$color_white;
							}

							@media (min-width: $breakpoint_to_flex_row_interior) {
								border-right: 1px solid variables.$color_white;
								border-bottom: 0;
							}
						}

						.img_background {
							display: none;
						}
					}

					.standard {
						border-color: variables.$color_white !important;

						a:hover {
							color: variables.$color_white;
						}
					}
				}

				.request_quote {
					background-image: none;

					@media (min-width: $breakpoint_to_flex_row_home) {
						@include mixins.justify-content(center);

						.button {
							margin-right: 0;
						}
					}
				}
			}
		}
	}

	.site_header {
		@include mixins.flexbox();
		@include mixins.flex-direction(column);

		.fix_ie_wrapper {
			padding: 1.5rem;
			color: variables.$color_white;
			background-color: variables.$color_kimberly;
			background-position: center;
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-size: cover;
			z-index: 1;
			position: relative;
			width: 100%;
			@include mixins.flexbox();
			@include mixins.flex-direction(column);
			@include mixins.justify-content(center);
			@include mixins.align-items(center);
			@include mixins.scale(min-height, 15.5rem, 20em, 21.75rem, 50em);


			&.has_background_img:after {
				background-color: rgba(color.adjust(variables.$color_jacarta, $hue: -.31746deg, $saturation: - 10.21035%, $lightness: 7.2549%), .75);
				z-index: 2;
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

			}
		}

		.max_content_width {
			position: relative;
			max-width: 60rem;
			z-index: 3;
		}

		h1 {
			margin-top: 0;
			margin-bottom: 0;
			color: inherit;

			&.center {
				text-align: center;
			}

			& + p {
				margin-top: .375rem;
			}
		}

		p {
			margin: 0;

			&.center {
				text-align: center;
			}

			&.date {
				margin-top: 1.5rem;
				font-weight: 600;
				letter-spacing: .02833em;
				@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 40em);

			}
		}

		hr {
			margin: 2.25rem 0;
			border: 0;
			border-bottom: 1px solid rgba(variables.$color_white, .2);
		}
	}

	body[data-contrast-mode=on] {
		.site_header .fix_ie_wrapper {
			background-color: variables.$color_black;
			background-image: none !important;
			border-bottom: 1px solid variables.$color_white;
		}
	}

	.sticky_nav {
		font-weight: 700;
		font-size: .875rem;
		line-height: 3.75rem;
		letter-spacing: .03929em;
		text-transform: uppercase;
		background-color: variables.$color_alabaster;
		border-bottom: 1px solid variables.$color_alto;

		&.is_stickied {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			z-index: 5000;
			@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_black, .2));

		}

		.max_content_width {
			position: relative;
			max-width: 70rem;

			@media (min-width: 30em) {
				@include mixins.flexbox();
				@include mixins.justify-content(space-between);
			}

			.browse {
				display: block;
				text-align: center;
				border-right: 0 !important;

				@media (min-width: 60em) {
					display: none;
				}

				&:hover {
					color: variables.$color_carrot_orange;
				}
			}

			.page_links {
				display: none;
				width: 100%;
				background-color: variables.$color_alabaster;
				border-top: 1px solid variables.$color_alto;
				z-index: 150;

				@media (min-width: 30em) {
					position: absolute;
					top: calc(100% + 1px);
					left: 0;
					border-top: 0;
					border-bottom: 1px solid variables.$color_alto;
				}

				@media (min-width: 60em) {
					// Not using @mixin because needs to use !important
					display: -webkit-box !important; // Safari 3.1+, iOS Safari 3.2+, Chrome 4+
					display: -moz-box !important; // Firefox 2+
					display: -ms-flexbox !important; // IE 10
					display: -webkit-flex !important; // Safari 6.1+, iOS Safari 7.1+
					display: flex !important;
					position: relative;
					border-bottom: 0;
				}

				a {
					display: block;
					width: 100%;
					height: 3.75rem;
					line-height: 3.75rem;
					white-space: nowrap;
					border: 0 !important;

					@media (min-width: 60em) {
						display: inline-block;
					}

					&:not(:last-of-type) {
						border-bottom: 1px solid variables.$color_alto !important;

						@media (min-width: 60em) {
							border-right: 0;
							border-bottom: 0 !important;
						}
					}

					&:hover {
						color: variables.$color_carrot_orange;
					}
				}
			}

			select,
			a:not(.support) {
				width: 100%;
				padding-top: 0;
				padding-bottom: 0;
				font-weight: inherit;
				font-size: inherit;
				line-height: inherit;
				letter-spacing: inherit;
				text-transform: inherit;
				background-color: transparent;
				border: 0;

				@media (min-width: 30em) {
					width: auto;
					border-right: 1px solid variables.$color_alto;
					border-left: 1px solid variables.$color_alto;
				}
			}

			select {
				background-image: url('../images/toggle-select.svg');

				@media (min-width: 30em) {
					max-width: 16rem;
				}
			}

			a:not(.support) {
				padding: 0 1rem;
				color: inherit;
			}

			.support {
				display: block;
				width: 100%;
				padding: 0 1rem;
				color: variables.$color_white;
				text-align: center;
				white-space: nowrap;
				background-color: variables.$color_button_background;

				&:hover {
					background-color: variables.$color_button_background_hover;
				}

				@media (min-width: 30em) {
					width: auto;
				}
			}
		}

		&.blog-category-listing {
			background-color: variables.$color_white;

			.max_content_width {
				@include mixins.justify-content(center);
			}
		}
	}

	body[data-contrast-mode=on] {
		.sticky_nav {
			background-color: variables.$color_black;
			border-color: variables.$color_white;
			@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_white, .2));

			.page_links {
				background-color: variables.$color_black;
			}

			select,
			a:not(.support) {
				color: variables.$color_white;
			}

			a.support {
				color: variables.$color_black;
				background-color: variables.$color_white;

				&:hover {
					background-color: variables.$color_white;
				}
			}
		}
	}

