@use '../base/variables';
@use '../base/mixins';

.php_fit {
	.col3 .col header {
		padding-top: 1rem;
		font-weight: 400;
		color: variables.$color_nevada;
		text-align: center;
		@include mixins.scale(font-size, 1rem, 20em, 1.375rem, 40em);
	}

	& + .Wave5 + .bloq.health_tip + .ctas {
		border-top: 1px solid variables.$color_alto;
	}
}

body[data-contrast-mode=on] {
	.php_fit .col3 .col header {
		color: variables.$color_white;
	}
}