@use '../base/mixins';
@use '../base/variables';
@use '../base/placeholders' as *;
@use 'sass:color';

$breakpoint_to_side_by_side: 36em;
$breakpoint_to_full_logo: 40em;
$breakpoint_to_tablet: 50em;
$breakpoint_to_small_screen: 70em;

.i_links {
    @media (min-width: $breakpoint_to_side_by_side) {
        @include mixins.flexbox();
    }


    .main_callout {
        background-color: transparent;
        margin-left: 3%;

        header {
            min-width:20rem;
            max-width:35rem;
        }

        a {
            min-width: 11.75rem;
        }
    }

    & > div {
        position: relative;
        width: 100%;
        min-height: 17rem;
        height: 100vw;
        padding: 1.5rem;
        color: variables.$color_white;
        z-index: 1;
        @include mixins.flexbox();
        @include mixins.flex-direction(column);
        @include mixins.justify-content(center);

        @media (min-width: 15em) {
            height: 180vw;
        }

        @media (min-width: 30em) {
            height: 140vw;
        }

        @media (min-width: 40em) {
            height: 95vw;
        }

        @media (min-width: 50em) {
            height: 65vw;
        }

        @media (min-width: 75em) {
            height: 35rem;
        }

        .img_background {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: variables.$color_comet;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: scroll;
            z-index: 2;

             @media (max-width: 45em) {
            padding-right: 70em;
            }
        }

        header {
            position: relative;
            line-height: 1.25;
            z-index: 3;

            span {
                display: block;
                font-size: 1em;
            }

            h2 {
                color: variables.$color_white;
                margin: 0;
                font-size: 2.75rem;
            }

            p {
                max-width: 26rem;
            }
        }
    }

    .button {
        position: relative;
        text-transform: none;
        z-index: 4;
        padding: 0.25rem 2rem;
        margin-top: 2rem;
        cursor: pointer;

        &:hover {
            color: variables.$color_carrot_orange;
            background-color: variables.$color_white;
            border-color: variables.$color_white;
        }
    }

    p {
        /*        width: 100%;
        font-weight: 500;
        font-size: .875rem;
        line-height: 1.25;*/
        z-index: 4;
    }

    & + [class^=Wave].blend_Top .bottom {
        fill: variables.$color_home_gray;
    }
}


body[data-contrast-mode=on] {
    .i_links {
        border-bottom: 1px solid variables.$color_white;

        & > div {
            background-color: variables.$color_black;
            background-image: none;

            &:first-of-type {
                border-bottom: 1px solid variables.$color_white;

                @media (min-width: 36em) {
                    border-right: 1px solid variables.$color_white;
                    border-bottom: 0;
                }
            }

            .img_background {
                background-image: none !important;
            }
        }
    }
}

.home_banner_callouts {
    background-color: variables.$color_home_gray !important;
    padding: 1.5rem .5rem;

    & > div {
       @extend %clearfix;
        display: block;


        @media (min-width: 64em) {
            @include mixins.flex(1 1 auto);
            height: 6rem;
        }

        @media (min-width: $breakpoint_to_full_logo) {
            display: flex;
            justify-content: center;
        }

        & > div {
            margin-bottom: 1rem;
            display: block;

            @media (min-width: $breakpoint_to_full_logo) {
                width: 40%;

                &:first-of-type {
                    margin-right: 2%;
                    padding-right: 1rem;
                    border-right: 1px solid #cccccc;
                }
            }

            @media (min-width: 100em) {
                width: 32%;
            }


            & > svg {
                height: 3.5em;
                width: 5em;
                float: left;
                margin-bottom: 3rem;

                @media (max-width: $breakpoint_to_full_logo) {
                    margin: 0;
                }
            }

            p {
                font-size: .875rem;
                line-height: 1.42857;
                letter-spacing: .0275em;
                margin-bottom: 0;
            }

            a svg {
                fill: variables.$color_carrot_orange;
                width: .5rem;
                height: .5rem;
            }
        }
    }
}


body[data-contrast-mode=on] {
    .home_php_easy > div > header span {
        color: variables.$color_white;
    }

    .home_php_easy > div > div > svg path {
        fill: variables.$color_white;
    }

    .home_php_easy + [class^=Wave].blend_Top .bottom {
        fill: variables.$color_black;
    }
}

#were_here_top_wave, #were_here_bottom_wave {
    @media(max-width: $breakpoint_to_full_logo) {
        display: none;
    }
}

#were_here_bottom_wave {
    margin-bottom: 0 !important;
}

.were_here_wrapper {
    background-color: color.adjust(variables.$color_jacarta, $hue: -.29376deg, $saturation: -5.03937 * 1%, $lightness: 7.64706 * 1%);
    @include mixins.linear-gradient(to bottom, variables.$color_comet 0%, variables.$color_jacarta 100%);

    @media (max-width: $breakpoint_to_tablet) {
        display: block;
        @include mixins.justify-content(space-between);
    }

    > div {
        display: flex;
        position: relative;

        @media (max-width: $breakpoint_to_small_screen) {
            display: block;
        }

        .photo {
            background-position: 0 15%;
            background-size: cover;
            height: 30rem;
            flex: 1;
            position: relative;


            @media (min-width: $breakpoint_to_small_screen) {
                background: none !important;
                height: initial;

                img {
                    height: 80%;
                    position: absolute;
                    bottom: -6rem;
                    margin-left: 5%;
                    display: block !important;
                }
            }

            @media(min-width: 70rem) and (max-width: 80rem) {
                margin-right: 25rem;
            }

            img {
                display: none;
            }
        }

        .content {
            margin-top: 12%;
            flex: 1;

            @media (max-width: 70em) {
                padding: 2rem;
                margin: 0;
            }

            header {
                margin: 4rem 0;
            }


            h3 {
                color: variables.$color_white;
                line-height: 1.21875;
                letter-spacing: .0315625em;
                margin-top: 0;
            }



            p {
                color: variables.$color_white;
                max-width: 30em;
            }

            .grid_child {
                margin-bottom: 1rem;
                width: 30%;

                svg {
                    width: 80px;
                    height: 50px;
                    margin-bottom: 2rem;

                    @media (max-width: 40em) {
                        margin: 0;
                        display: block;
                    }
                }

                p {
                    font-size: .75rem;
                    line-height: 3;
                    letter-spacing: .0275em;
                    display: inline;
                    vertical-align: top;
                    padding-left: .3em;
                }

                a {
                    color: variables.$color_white;
                    margin-right: 3em;
                    font-size: 1rem;
                    text-transform: uppercase;
                    line-height: 1rem;

                    &:hover {
                        color: variables.$color_carrot_orange;
                    }
                }

                .icon {
                    margin: 0 auto;
                    width: 500px;
                    line-height: 1.25;

                    @media(max-width:$breakpoint_to_side_by_side) {
                        display: grid;
                        width: 40rem;
                    }

                    .left_column {
                        float: left;
                        width: 13rem;
                        padding-bottom: .5rem;
                    }

                    .right_column {
                        float: right;
                        width: 14rem;
                        padding-bottom: .5rem;
                    }

                    .icon_wrapper svg {
                        float: left;
                        clear: both;
                    }
                }
            }
        }

        & + [class^=Wave] .bottom {
            fill: variables.$color_white;
        }

        & + [class^=Wave].blend_Bottom .top {
            fill: transparent;
        }
    }

    & + [class^=Wave] + .home_find_plan {
        background-color: variables.$color_white;
    }
    /*    & + [class^=Wave] .bottom {
        fill: $color_white;
    }

    & + [class^=Wave].blend_Bottom .top {
        fill: transparent;
    }*/
}

body[data-contrast-mode=on] {
    .were_here_wrapper + [class^=Wave] .bottom {
        fill: variables.$color_black;
    }
}

.toolkit_wrapper.home {


    & + [class^=Wave].Purple .top {
        fill: variables.$color_white;
    }

    & + [class^=Wave].Green .top {
        fill: variables.$color_white;
    }
}

body[data-contrast-mode=on] {
    .toolkit_wrapper.home {
        background-color: variables.$color_black;

        & + [class^=Wave].blend_Bottom .top {
            fill: variables.$color_black;
        }
    }
}

/*.home_alert + [class^=Wave] + .bloq.toolkit.home {
	max-width: none;
	margin-bottom: 0;
	background-color: $color_white;

	& + [class^=Wave].blend_Bottom .top {
		fill: $color_white;
	}
}*/

.video_wrapper {
    background-color: variables.$color_white;
    padding-bottom: 4rem;

    header {
        text-align: center;

        h3 {
            margin-bottom: 0;
            font-size: 1.75rem;
            font-weight: 600;
        }

        p {
            margin-top: 0;
            font-size: .875rem;
        }
    }

    div {
        margin-left: 10%;
        margin-right: 10%;

        .col {
            figure {
                border: 1px solid variables.$color_mercury;
                padding-bottom: 50%;
                background-size: cover;
                background-repeat: no-repeat;
            }

            &.video figure svg {
                position: absolute;
                top: 50%;
                left: 50%;
                max-width: 8rem;
                margin: 0;
                @include mixins.filter(drop-shadow(0 0 1rem rgba(0, 0, 0, .6)));

                path {
                    fill: variables.$color_white;
                    @include mixins.transition(.25s all);
                }
            }

            &.video:hover figure svg path {
                fill: variables.$color_mercury;
            }
        }
    }
}

.stay_connected {
    background-color: variables.$color_white;
    margin-bottom: 1.5rem;

    header {
        text-align: center;

        h3 {
            margin-bottom: 0;
            font-size: 1.75rem;
            font-weight: 600;
        }

        p {
            margin-top: 0;
            font-size: .875rem;
        }
    }

    .blog_listing {
        border: none;
        padding-top: 0;

        .col3_blog {
            margin: 0;
            padding: 0;
            border-bottom: none;
        }

        .blog_button {
            float: right;
        }

        h3 {
            font-size: 1rem;
            color: variables.$color_carrot_orange;
        }
    }

    & + [class^=Wave] .bottom {
        fill: transparent;
    }

    & + [class^=Wave].blend_Bottom .top {
        fill: variables.$color_white;
    }
}

body[data-contrast-mode=on] {
    .home_experience_community {
        background-color: variables.$color_black;

        .img_background {
            background-image: none !important;
        }

        .info_blocks .info {
            color: variables.$color_white;
            background-color: variables.$color_black;

            header {
                span, p {
                    color: variables.$color_white;
                }
            }
        }
    }
}