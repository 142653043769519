@use '../base/variables';

.tab-links {
	$breakpoint_to_row : 50em;
	list-style: none;
	margin: 0 0 2.5rem;
	padding: 0;
	border-radius: 4px;
	box-shadow: 0 10px 15px variables.$color_alto;

	@media (min-width: $breakpoint_to_row) {
		display: flex;
		border-bottom: 1px solid variables.$color_white;
		border-radius: 0;
		box-shadow: none;
	}

	@media (max-width: $breakpoint_to_row) {
		li {
			&.show {
				display: block;
			}

			&.hide {
				display: none !important;
			}

			&.mobile {
				display: block !important;
			}
		}
	}

	li {
		margin-bottom: 0 !important;

		&.mobile {
			color: white;
			text-align: center;
			background-color: variables.$color_carrot_orange;
			border-radius: 4px;

			a {
				padding: 1rem;
				color: white;
				border-bottom: 0;
				border-radius: 4px;

				&::after {
					content: url('../images/chevron_down_white.svg');
					position: relative;
					top: -1px;
					display: inline-block;
					width: 7px;
					height: 10px;
					margin-left: .75rem;
					transition: all .25s;
					transform-origin: 75% 155%;
				}

				&.is-open {
					border-radius: 4px 4px 0 0;

					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		&:not(.mobile) {
			display: none;
		}

		@media (min-width: $breakpoint_to_row) {
			&:not(.mobile) {
				display: block !important;
			}

			&.mobile {
				display: none;
			}

			&:not(:last-of-type) {
				margin-right: 2.5rem;
			}
		}

		a {
			position: relative;
			display: block;
			padding: 1rem;
			color: variables.$color_body;
			font-weight: 400;
			text-align: center;
			border-bottom: 1px solid variables.$color_white;

			&:hover,
			&:focus {
				color: variables.$color_white;
				background-color: variables.$color_carrot_orange;
				border-color: variables.$color_carrot_orange;
			}

			@media (min-width: $breakpoint_to_row) {
				padding: 0 0 1.25rem;
				border: 0;

				&::after {
					content: '';
					position: absolute;
					right: 0;
					bottom: -1px;
					left: 0;
					height: 6px;
					background-color: variables.$color_carrot_orange;
					transition: all .25s;
					transform-origin: 50% 50%;
					transform: scale(0, 1);
				}

				&.active,
				&:hover,
				&:focus {
					color: #ed822b;
					background-color: transparent;

					&::after {
						transform: scale(1, 1);
					}
				}
			}
		}

		&:last-of-type a {
			border-radius: 0 0 4px 4px;
		}
	}
}

.tab-sections {
	> div:not(.active) {
		display: none;
	}
}
