@use '../base/variables';
@use '../base/mixins';
@use 'sass:color';

html {
	overflow-x:hidden;
}

body {
	& {
		position: relative;
		color: variables.$color_body;
		font-family: variables.$font_family_body;
		background-color: variables.$color_white;
		font-style: normal;
		font-weight: 400;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		@include mixins.scale(font-size, .875rem, 20em, 1rem, 30em);

	}

	&.home {
		padding-top: 4.5rem;
		overflow-x: hidden;
	}

	&.nav_is_stickied {
		padding-top: 3.75rem;
	}

	&.is_locked {
		overflow: hidden;
	}

	#loading_overlay {
		display: none;
	}

	&.is_loading {
		overflow: hidden;
		cursor: wait;

		#loading_overlay {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background: rgba(variables.$color_white, .75) url('../images/loading-icon.gif') center / 10rem no-repeat scroll;
			cursor: wait;
			z-index: 999999;
		}
	}

	#access_link {
		display: none;
	}
}

a {
	color: variables.$color_hyperlink;
	font-weight: 700;
	text-decoration: none;
	@include mixins.transition(.25s all);

	&:link {}

	&:visited {}

	&:hover {
		color: variables.$color_hyperlink_hover;
	}

	&:active {}
}

h1, h2, h3, h4, h5, h6 {
	color: variables.$color_comet;
	font-weight: 400;
	font-family: variables.$font_family_header;

	&.center {
		text-align: center;
	}
}

h1 {
	color: variables.$color_timber_green;
	font-weight: 600;
	line-height: 1.2;
	@include mixins.scale(font-size, 1.5rem, 20em, variables.$font_size_h1, 40em);

}

h2 {
	margin-top: 1.75rem;
	line-height: 1.4375;
	@include mixins.scale(font-size, 1.375rem, 20em, variables.$font_size_h2, 30em);
}

h3 {
	margin-top: 1.875rem;
	line-height: 1.286;
	@include mixins.scale(font-size, 1.25rem, 20em, variables.$font_size_h3, 30em);
}

h4 {
	margin-top: 2.125rem;
	line-height: 1.417;
	@include mixins.scale(font-size, 1.125rem, 20em, variables.$font_size_h4, 30em);
}

h5 {
	margin-top: 2.6875rem;
	font-size: variables.$font_size_h5;
}

h6 {
	margin-top: 3.25rem;
	font-size: variables.$font_size_h6;
}

blockquote {
	quotes: "\201C" "\201D" "\201C" "\201D";
	position: relative;
	margin: 0 5vw 4rem 5vw;
	color: variables.$color_blockquote;
	font: italic 600 1.4375rem / 1.608 variables.$font_family_blockquote;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 5px;
		@include mixins.linear-gradient(to bottom, variables.$color_mantis 0%, color.adjust(variables.$color_mantis, $lightness: 37.8%) 100%);
	}

	p {
		margin-left: 2rem;
	}
}

ul, ol {
	li {
		margin-bottom: .25rem;
	}
}