@use '../base/mixins';
@use '../base/variables';

.product_listing_faqs {
	background-color: variables.$color_white;
}

body[data-contrast-mode=on] {
	.product_listing_faqs {
		background-color: variables.$color_black;
	}
}

.product_listing {
	padding: 3rem 1.5rem;
	background-color: variables.$color_alabaster;

	h2 {
		@include mixins.flex(1 0 100%);
	}

	.col {
		margin-bottom: 3rem;
		@include mixins.flexbox();

		.icon {
			margin-right: 2rem;
			@include mixins.flex(0 0 auto);

			svg {
				width: 4rem;
				height: 4rem;

				path {
					fill: variables.$color_dove_gray;
				}
			}
		}

		.content {
			@include mixins.flex(1 1 100%);

			header {
				color: variables.$color_comet;
				font-weight: 400;
				font-size: 1.25rem;

				a {
					color: inherit;
					font-weight: inherit;
				}
			}

			p {
				margin-top: 0;
			}

			a {
				letter-spacing: .031875em;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.product_listing {
		background-color: variables.$color_black;

		.col {
			.icon svg path {
				fill: variables.$color_white;
			}

			.content header {
				color: variables.$color_white;
			}
		}
	}
}