@use '../base/variables';
@use '../base/mixins';
@use '../base/placeholders' as *;

.gallery_listing {
	padding: 3rem 1.5rem;
	background-color: variables.$color_alabaster;

	.videoHomePage {
		header {
			color: variables.$color_comet;
			text-align: left !important;
		}
		p {
			font-size: .8rem;
			color: variables.$color_dove_gray;
			font-weight: 100;
		}
	}

	.col {
		figure {
			border: 1px solid variables.$color_mercury;
		}

		header {
			margin-top: 1.25rem;
			font-weight: 400;
			letter-spacing: .0315em;
			text-align: center;
			@include mixins.scale(font-size, .875rem, 20em, 1.25rem, 40em);
		}

		&.video figure svg {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 5rem;
			height: 100%;
			max-height: 5rem;
			margin: 0;
			@include mixins.filter(drop-shadow(0 0 1rem rgba(0, 0, 0, .6)));

			path {
				fill: variables.$color_white;
				@include mixins.transition(.25s all);
			}
		}

		&.video:hover figure svg path {
			fill: variables.$color_mercury;
		}
	}
}

body[data-contrast-mode=on] {
	.gallery_listing {
		background-color: variables.$color_black;

		.col figure.aspect {
			border: 1px solid variables.$color_white;
		}
	}
}

$gallery_margins : 1.375rem;
.gallery_detail {
	@extend %clearfix;
	padding: 3rem 1.5rem;
	background-color: variables.$color_alabaster;

	.gallery_img {
		display: block;
		margin-bottom: $gallery_margins;

		figure {
			padding-bottom: 75%;
			border: 1px solid variables.$color_mercury;
		}

		@media (min-width: 20em) {
			float: left;

			&:nth-of-type(5n + 1) {
				clear: both;
				width: 100%;
			}

			&:nth-of-type(5n + 2),
			&:nth-of-type(5n + 3),
			&:nth-of-type(5n + 4),
			&:nth-of-type(5n + 5) {
				float: left;
				width: calc((100% - #{$gallery_margins}) * .5);
			}

			&:nth-of-type(5n + 2),
			&:nth-of-type(5n + 4) {
				margin-right: $gallery_margins;
			}
		}

		@media (min-width: 36em) {
			&:nth-of-type(5n + 1) {
				width: calc((100% - #{$gallery_margins}) * .72);
				margin-right: $gallery_margins;

				figure {
					padding-bottom: calc(58.25% + #{$gallery_margins});
				}
			}

			&:nth-of-type(5n + 2),
			&:nth-of-type(5n + 3) {
				width: calc((100% - #{$gallery_margins}) * .28);
				margin-right: 0;
			}

			&:nth-of-type(5n + 4),
			&:nth-of-type(5n + 5) {
				width: calc((100% - #{$gallery_margins}) * .5);
			}

			&:nth-of-type(5n + 4) {
				clear: both;
				margin-right: $gallery_margins;
			}

			&:nth-of-type(10n + 6) {
				float: right;
				margin-right: 0;
				margin-left: $gallery_margins;
			}

			&:nth-of-type(10n + 7),
			&:nth-of-type(10n + 8) {
				float: right;
			}
		}

		@media (min-width: 50em) {
			&:nth-of-type(5n + 1) {
				width: calc((100% - (2 * #{$gallery_margins})) * .55);

				figure {
					padding-bottom: calc(61.25% + #{$gallery_margins});
				}
			}

			&:nth-of-type(5n + 2),
			&:nth-of-type(5n + 3),
			&:nth-of-type(5n + 4),
			&:nth-of-type(5n + 5) {
				clear: none;
				width: calc((100% - (2 * #{$gallery_margins})) * .225);
				margin-right: 0;

				/* IE 10+ */
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					width: calc((100% - (2 * #{$gallery_margins})) * .224);
				}

				/* MS Edge 12+ */
				@supports (-ms-ime-align: auto) {
					width: calc((100% - (2 * #{$gallery_margins})) * .224);
				}
			}

			&:nth-of-type(5n + 2),
			&:nth-of-type(5n + 4) {
				margin-right: $gallery_margins;
			}

			&:nth-of-type(10n + 7),
			&:nth-of-type(10n + 9) {
				margin-right: 0;
				margin-left: $gallery_margins;
			}

			&:nth-of-type(10n + 9),
			&:nth-of-type(10n + 10) {
				float: right;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.gallery_detail {
		background-color: variables.$color_black;

		figure.aspect {
			border: 1px solid variables.$color_white;
		}
	}
}