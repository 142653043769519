
@use '../base/variables';
/**
 *		Override image paths
 */
.bx-wrapper .bx-loading {
	background-image: url('../images/bxslider/bx_loader.gif') !important;
}
.bx-wrapper .bx-prev,
.bx-wrapper .bx-next,
.bx-wrapper .bx-controls-auto .bx-start,
.bx-wrapper .bx-controls-auto .bx-stop {
	background-image: url('../images/bxslider/controls.png') !important;
}

.bx-wrapper {
	position: relative;
}

.bx-pager {
	position: absolute;
	bottom: .25rem;
	left: 50%;
	transform: translateX(-50%); // Center the pager horizontally
	z-index: 1000;
	white-space: nowrap;
	text-align: center; // Center the dots horizontally
}

.bx-pager .bx-pager-item {
	display: inline-block;
	vertical-align: middle;
	margin: 0 2px;
}

.bx-pager .bx-pager-item a {
	width: 10px;
	height: 10px;
	background-color: variables.$color_silver_chalice;
	border-radius: 50%;
	text-indent: -9999px; // This hides the text (numbers)
	display: inline-block;
	margin: 0 5px;
}

.bx-pager .bx-pager-item a.active {
	background-color: variables.$color_carrot_orange;
}

/**
 *		Remove some built-in styles
 */
.bx-wrapper {
	margin: 0 !important;
	background: transparent !important;
	border: 0 !important;
	box-shadow: none !important;
}