@use '../base/variables';
@use '../base/mixins';

@include mixins.keyframes(rotate) {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

#animation_wrapper {
	width: 100%;
	height: auto;
	border-top: 1px solid variables.$color_wild_sand;

	&:empty {
		display: none;
	}
}

/* Animations */

.animation-graphic {
	overflow: hidden;
}

.animation-graphic, .animation-graphic .inner-wrap {
	height: 340px;
	background-position: center bottom;
	position: relative;
}

.animation-graphic .inner-wrap {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

/* ==========================================================================
Member Animations
========================================================================== */

.svg-member {
	background-image: url('../images/animation-member-background.svg');
}

.svg-member .inner-wrap {
	max-width: 700px;
}

.svg-member .man {
	z-index: 5;
	position: absolute;
		left: 0;
    	bottom: -10px;
}

.svg-member .house {
	z-index: 1;
	position: absolute;
		bottom: 24px;
		left: -290px;
}

.svg-member .text {
	z-index: 4;
	position: absolute;
    	right: 0;
		top: 80px;
}

.svg-member .sky {
	width: 102%;
	position: absolute;
		top: 45px;
		left: 0;
}

@media all and (min-width: 500px) and (max-width: 800px) {

	.svg-member, .svg-member .inner-wrap {
		height: 220px;
	}

	.svg-member .man {
		z-index: 5;
		position: absolute;
	    	bottom: -50px;
	}

	.svg-member .house {
		z-index: 1;
		position: absolute;
			bottom: -19px;
			left: -240px;
	}

	.svg-member .inner-wrap {
		max-width: 450px;
	}

	.svg-member .text {
		z-index: 4;
		position: absolute;
	    	right: 0;
			top: 10px;
	}

	.svg-member .text svg {
		width: 400px;
	}

	.svg-member {
		background-position: center bottom;
	}

	.svg-member .man svg {
		width: 50px;
	}

	.svg-member .house svg {
		width: 300px;
	}

	.svg-member .sky {
		width: 200%;
		position: absolute;
			top: 4%;
			left: 0;
	}

}

@media all and (max-width: 499px) {

	.svg-member #Bubbles {
		display: none !important;
	}

	.svg-member #text-hide {
		fill: transparent !important;
	}

	.svg-member, .svg-member .inner-wrap {
		height: 200px;
	}

	.svg-member .text {
		top: -10px;
		right: 30px;
	}

	.svg-member .text svg {
		width: 400px;
	}

	.svg-member .man {
		left: 43%;
		top: 7px;
	}

	.svg-member .man svg {
		width: 50px;
	}

	.svg-member .house {
		left: -150px;
		bottom: -19px;
	}

	.svg-member .house svg {
		width: 298px;
	}

}

/* ==========================================================================
Seeking Animations
========================================================================== */

.svg-seeking {
	background-image: url('../images/animation-seeking-background.svg');
}

.svg-seeking .inner-wrap {
	max-width: 860px;
}

.svg-seeking .animation, .svg-seeking .text {
	position: absolute;
}

.svg-seeking .animation {
	left: 0;
	bottom: -8px;
}

.svg-seeking .text {
	top: 50px;
	right: 15px;
}

@media all and (max-width: 870px) {

	.svg-seeking, .svg-seeking .inner-wrap {
		height: 220px;
	}

}

@media all and (min-width: 600px) and (max-width: 870px) {

	.svg-seeking .inner-wrap {
		max-width: 570px;
	}

	.svg-seeking .text {
		right: 0;
		top: 0;
	}

	.svg-seeking .text svg {
		width: 450px;
	}

	.svg-seeking .animation {
		left: -50px;
		bottom: -41px;
	}

	.svg-seeking .animation svg {
		width: 70%;
	}

}


@media all and (min-width: 500px) and (max-width: 599px) {

	.svg-seeking .inner-wrap {
		max-width: 450px;
	}

	.svg-seeking .animation {
		left: -50px;
		bottom: -50px;
	}

	.svg-seeking .animation svg {
		width: 70%;
	}

	.svg-seeking .text {
		right: 0;
		top: 0;
	}

	.svg-seeking .text svg {
		width: 370px;
	}

}

@media all and (max-width: 499px) {

	.svg-seeking #seeking-text, .svg-seeking #Bubbles {
		display: none !important;
	}

	.svg-seeking, .svg-seeking .inner-wrap {
		height: 200px;
	}

	.svg-seeking .animation {
		left: 0;
		bottom: -56px;
	}

	.svg-seeking .animation svg {
		width: 320px;
	}

	.svg-seeking .text {
		right: 30px;
		top: -40px;
	}

	.svg-seeking .text svg {
		width: 400px;
	}

	.svg-seeking #text-hide {
		fill: transparent !important;
	}

}

/* ==========================================================================
Broker Animations
========================================================================== */

.svg-broker {
	background-image: url('../images/animation-broker-background.svg');
}

.svg-broker .car, .svg-broker .text {
	position: absolute;
}

.svg-broker .inner-wrap {
	max-width: 600px;
}

.svg-broker .car {
	left: 0;
	bottom: 0;
}

.svg-broker .text {
	top: 100px;
	right: 15px;
}

@media all and (min-width: 551px) and (max-width: 800px) {

	.svg-broker, .svg-broker .inner-wrap {
		height: 220px;
	}

	.svg-broker .inner-wrap {
		max-width: 500px;
	}

	.svg-broker .car {
		left: 0;
		bottom: 5px;
	}

	.svg-broker .car svg {
		width: 100%;
	}

	.svg-broker .text {
		right: 0;
		top: 10px;
	}

	.svg-broker .text svg {
		width: 320px;
	}

}

@media all and (max-width: 550px) {

	.svg-broker, .svg-broker .inner-wrap {
		height: 190px;
	}

	.svg-broker .car {
		left: 5%;
		bottom: 4px;
	}

	.svg-broker .car svg {
		width: 180px;
	}

	.svg-broker .text {
		position: relative !important;
			top: 0 !important;
			right: 0 !important;
	}

	.svg-broker .text svg {
		width: 60%;
		position: absolute;
			right: 4%;
	}

}


/* ==========================================================================
Provider Animations
========================================================================== */

.svg-provider {
	background-image: url('../images/animation-provider-background.svg');
}

.svg-provider .people, .svg-provider .text {
	position: absolute;
}

.svg-provider .inner-wrap {
	max-width: 700px;
}

.svg-provider .people {
	left: 0;
	bottom: -10px;
}

.svg-provider .text {
	top: 50px;
	right: 0;
}

.svg-provider .text svg {
	width: 390px;
}

@media all and (min-width: 551px) and (max-width: 800px) {

	.svg-provider, .svg-provider .inner-wrap {
		height: 220px;
	}

	.svg-provider .inner-wrap {
		max-width: 500px;
	}

	.svg-provider .people {
		left: 0;
		bottom: -35px;
	}

	.svg-provider .people svg {
		width: 220px;
	}

	.svg-provider .text {
		right: 0;
		top: 10px;
	}

	.svg-provider .text svg {
		width: 270px;
	}

}

@media all and (max-width: 550px) {

	.svg-provider, .svg-provider .inner-wrap {
		height: 190px;
	}

	.svg-provider .people {
		width: 60%;
		z-index: 2;
			left: 5%;
			bottom: -35%;
	}

	.svg-provider .people svg {
		width: 60%;
	}

	.svg-provider .text {
		position: relative !important;
			top: 0 !important;
			right: 0 !important;
	}

	.svg-provider .text svg {
		width: 52%;
		position: absolute;
			right: 4%;
	}

}

@media all and (max-width: 400px) {

	.svg-provider .people {
		width: 100%;
		text-align: center;
			left: 0 !important;
	}

	.svg-provider .people svg {
		width: 210px !important;
	}

	.svg-provider .text {
		display: none;
	}

}

/* ==========================================================================
Homepage Animations
========================================================================== */

.svg-homepage {
	background-image: url('../images/animation-home-background.svg');
}

.svg-homepage .family {
	text-align: center;
	position: absolute;
		left: 0;
		bottom: 0;
}

.svg-homepage .text {
	text-align: center;
	position: absolute;
		right: 0;
		bottom: 130px;
}

.svg-homepage .inner-wrap {
	max-width: 800px;
}

.svg-homepage #Names #Boy_Name, .svg-homepage #Names #Girl_Name, .svg-homepage #Names #Mom_Name, .svg-homepage #Names #Dad_Name {
	display: none;
}

.svg-homepage #Names #Boy_Name.show, .svg-homepage #Names #Girl_Name.show, .svg-homepage #Names #Mom_Name.show, .svg-homepage #Names #Dad_Name.show {
	display: block !important;
}

.svg-homepage #Dad:hover #Dad_Name {
	display: block !important;
}


@media all and (min-width: 551px) and (max-width: 850px) {

	.svg-homepage, .svg-homepage .inner-wrap {
		height: 200px;
	}

	.svg-homepage .inner-wrap {
		max-width: 500px;
	}

	.svg-homepage .family {
		text-align: center;
			left: 0;
			bottom: -48px;
	}

	.svg-homepage .family svg {
		width: 180px;
	}

	.svg-homepage .text {
		bottom: 55px;
	}

	.svg-homepage .text svg {
		width: 320px;
	}

}

@media all and (max-width: 550px) {

	.svg-homepage #bubble, .svg-homepage #speech-text {
		display: none !important;
	}

	.svg-homepage, .svg-homepage .inner-wrap {
	    height: 190px;
	}

	.svg-homepage .family {
		text-align: center;
			left: 10px !important;
			bottom: -48px;
	}

	.svg-homepage .family svg {
		width: 180px !important;
	}

	.svg-homepage .text {
		right: 30px;
		bottom: 55px;
	}

	.svg-homepage .text svg {
		width: 320px;
	}
}

body[data-contrast-mode=on] {
	#Boy_Name,
	#Girl_Name,
	#Mom_Name,
	#Dad_Name {
		path {
			fill: variables.$color_black;
		}
	}
}

body[data-contrast-mode=on] {
	g#Bubbles {
		fill: variables.$color_black;
	}

	path#speech-text {
		fill: variables.$color_white;
	}

	#Play {
		fill: variables.$color_white;

		#Fill-1 {
			stroke: variables.$color_white;
		}

		#Fill-4 {
			fill: variables.$color_white;
			stroke: variables.$color_white;
		}

		#WATCH-VIDEO {
			fill: variables.$color_white;
		}
	}
}