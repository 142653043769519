@use '../base/variables';
@use '../base/mixins';
@use 'sass:color';
@use '../base/placeholders' as *;

*:focus {
    outline: none;
}

form {
	@extend %clearfix;
	padding: 2rem 0;
}

.errors {
	margin-bottom: 2rem;
	padding: .25rem 1rem;
	color: variables.$color_errors;
	background: rgba(variables.$color_errors, .1);
	border: 1px solid variables.$color_errors;

	&:empty {
		display: none;
	}
}

fieldset {
	margin: 0 0 2rem 0;
	padding: 0;
	border: 0;

	legend {
		width: 100%;
		margin-bottom: 1rem;
		font-size: 1.625rem;
		font-family: variables.$font_family_legends;
		text-align: center;
	}
}

label {
	position: relative;
	display: block;
	width: 100%;
	margin: 1rem 0;
	color: variables.$color_labels;
	font-size: 1.125rem;

	&.checkbox {
		cursor: pointer;
		@include mixins.flexbox();
		&.centered {
			@include mixins.justify-content(center);
		}
	}

	&.error {
		input,
		select,
		textarea {
			padding-right: 3rem;
			background-image: url('../images/input-error.svg');
			background-position: calc(100% - .75rem) 50%;
			background-size: initial;
			background-repeat: no-repeat;
			background-attachment: scroll;
			border-color: variables.$color_errors;
		}
	}

	&.complete {
		input,
		select,
		textarea {
			border-color: variables.$color_input_border_complete;
		}
	}

	span {
		display: block;
		width: 100%;
		margin-bottom: .25rem;
		font-family: variables.$font_family_labels;
		letter-spacing: .056875em;

		&.required:before {
			content: '*';
			padding: 0 .25rem;
			color: variables.$color_errors;
			font-weight: 700;
		}

		small {
			color: variables.$color_silver_chalice;
			font-weight: 700;
			font-size: .625rem;
			font-family: variables.$font_family_body;
			letter-spacing: .056em;

			&:before {
				content: "(";
			}

			&:after {
				content: ")";
			}
		}

		&.error {
			padding: .375rem;
			color: variables.$color_errors;
			font-size: .875rem;
		}
	}
}

input,
select,
textarea,
button {
	width: 100%;
	max-width: 100%;
	padding: .6875rem .875rem;
	color: variables.$color_input_text;
	font-family: inherit;
	line-height: 1.5;
	border: 1px solid variables.$color_input_border;
	border-radius: .25rem;
	@include mixins.transition(.2s all);
	@include mixins.scale(font-size, .875rem, 20em, 1rem, 28em);
	@include mixins.placeholder {
		color: variables.$color_input_placeholder;
		font: inherit;
		@include mixins.scale(font-size, .875rem, 20em, 1rem, 28em);
	}

	&:focus {
		border-color: variables.$color_input_border_focus;
	}
}

input[type=number] {
	width: 4rem;
	text-align: center;
	background: variables.$color_number_background;
	border: 0;
}

.freeform-row .freeform-column button {
	display: inline-block;
	min-width: 9.75rem;
	padding: .5625rem 2.5rem;
	color: variables.$color_button;
	font-weight: 600;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background: variables.$color_button_background;
	border: 2px solid variables.$color_button_border;
	border-radius: .25rem;

	&:hover {
		background-color: variables.$color_comet;
		border: 2px solid variables.$color_comet;
	}
}

.freeform-row .freeform-column label  {
	color: variables.$color_comet;
    font-size: 1.125rem;
	width: 100%;
    margin-bottom: .25rem;
    font-family: "Open Sans", sans-serif;
    letter-spacing: .056875em;
	font-weight: 400 !important;
}
.freeform-row .freeform-column label:not(.freeform-label)  {
	color: variables.$color_comet;
	font-size: 14.4px;
	margin-bottom: .25rem;
	font-family: "Open Sans", sans-serif;
	letter-spacing: .056875em;
	font-weight: 400 !important;
	display: flex;
	align-items: center;
}

.freeform-row .freeform-column label .freeform-input[type=checkbox]:checked {
	background-color: variables.$color_checkbox_background;
	display: inline-block !important;
	border-color: variables.$color_checkbox_background;
	color: variables.$color_checkbox_background;
	width: 1.5625rem !important;
	height: 1.5625rem !important;
	background-repeat: no-repeat;
	background-image: url('../images/checkmark.svg');
	background-position: center;
	transition: background-color 0.2s;
}

.freeform-row .freeform-column label .freeform-input[type=checkbox]:checked:after {
	background-image: url('../images/checkmark.svg');
	color:white;
}
.freeform-row .freeform-column label .freeform-input[type=checkbox],
input[type=checkbox] {
	display: inline-block;
	width: 1.5625rem;
	height: 1.5625rem;
	margin-right: 1.5rem;
	background: transparent center center no-repeat scroll;
	border: 1px solid variables.$color_input_border;
	cursor: pointer;
	@include mixins.appearance(none);

	&:checked {
		background-color: variables.$color_checkbox_background;
		background-image: url('../images/checkmark.svg');
		border-color: variables.$color_checkbox_background;
	}

	& + span {
		position: relative;
		width: auto;
		display: inline-block;
		margin-bottom: 0;
		font-family: variables.$font_family_body;
		line-height: 1.5625;
		letter-spacing: normal;
	}
}

select {
	padding-right: 2.625rem;
	background: variables.$color_white url('../images/chevron_down.svg') calc(100% - .875rem) 50% no-repeat scroll;
	border-radius: 0;
	@include mixins.appearance(none);

	&:disabled {
		opacity: .4;
		cursor: not-allowed;
	}
}

input[type=submit],
button {
	@extend a;
	@extend .button;
	margin-top: 1rem;
	padding: .625rem 1.5rem;
	color: variables.$color_button_hover;
	background-color: variables.$color_carrot_orange;
	font-weight: 600;
	text-transform: uppercase;
	border-color: variables.$color_button_border;
	cursor: pointer;

	&:hover {
		background-color: variables.$color_comet;
		border-color: variables.$color_comet;
	}

	@media (min-width: 25em) {
		clear: both;
		display: block;
		width: auto;
	}

	&.disabled, &[disabled] {
		background-color: color.adjust(variables.$color_gray, $lightness: 20%);
		border-color: variables.$color_gray;
		cursor: not-allowed;
	}
}
#login_form,
#register_form {
	input[type=submit],
	button {
		@media (min-width: 25em) {
			float: none;
		}
	}
}

.form_success {
	margin-top: 1rem;

	@media(min-width: 50rem) {
		@include mixins.flexbox();
		@include mixins.flex-wrap(wrap);
	}

	.heading {
		background: #85c169;
		border-left: 10px solid #608c4b;
		color: variables.$color_white;
		padding: .5rem 1rem .5rem 2rem;
		@include mixins.flexbox();

		@media(min-width: 50rem) {
			@include mixins.flex-basis(100%);
		}

		.message {
			align-self: center;
		}

		.check {
			align-self: center;
			margin-left: auto;
			padding-top: .25rem;

			svg {
				height: 25px;
				width: 25px;
			}
		}
	}

	.main_body {
		margin: 2rem 0;

		@media(min-width: 50rem) {
			//padding-left: .75rem;
			padding-right: 3rem;
			max-width: calc(100% - 18rem);
			width: calc(100% - 18rem);
			@include mixins.align-self(flex-start);
		}

		.submitted_fields {
			padding: 2rem 0;

			.field {
				border-bottom: 1px solid variables.$color_gallery;
				padding: 1rem 0;

				label {
					font-weight: 700;
				}
			}
		}
	}

	.what_next {
		text-align: center;

		@media(min-width: 50rem) {
			max-width: 18rem;
			width: 18rem;
			text-align: left;
			@include mixins.align-self(flex-start);
		}

		h5 {
			margin: 1.75rem 0 0;
		}

		a {
			display: block;
			color: inherit;
			font-weight: 400;
			line-height: 2;
			letter-spacing: .003125em;

			&:before {
				content: attr(data-before);
			}

			&:hover {
				color: variables.$color_carrot_orange;
			}
		}
	}

	.button {
		margin-top: 2rem;
	}
}

.page_contact.become {
	background-color: variables.$color_white;
}

body[data-contrast-mode=on] {
	.page_contact.become {
		background-color: variables.$color_black;
	}
}
