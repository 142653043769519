@use '../base/mixins';
@use '../base/variables';
@use '../base/placeholders' as *;
@use 'sass:color';

.provider_search_form {
	padding: 2rem 1.5rem;
	text-align: center;

	&.has_searched {
		display: none;
	}

	h1 {
		margin-bottom: 1rem;
		color: variables.$color_comet;
	}

	form {
		.flex {
			@media (min-width: 58em) {
				@include mixins.flexbox();
				@include mixins.justify-content(center);
			}
		}

		label {
			margin: 1rem 0 2rem 0;

			&.location {
				@media (min-width: 58em) {
					width: 23.75rem;
					margin-bottom: 2rem;
				}
			}

			&.distance {
				@media (min-width: 58em) {
					width: 23.75rem;
					margin-bottom: 2rem;
				}
			}


			&.keywords, &.category {
				@extend %clearfix;
				margin-bottom: 3.5rem;

				&:before {
					content: 'OR';
					position: relative;
					top: -.875rem;
					display: block;
					color: variables.$color_dove_gray;
					font-weight: 700;
					font-size: .875rem;
					letter-spacing: .03143em;

					@media (min-width: 58em) {
						position: absolute;
						top: auto;
						bottom: .75rem;
						right: 100%;
						display: inline-block;
						width: 5.375rem;
						text-align: center;
					}
				}

				.inputs {
					max-width: 23.75rem;
					margin: 0 auto;
					@include mixins.flexbox();

					.action {
						position: relative;
						padding: 0 1rem;
						background-color: variables.$color_athens_gray;
						border: 1px solid variables.$color_silver;
						border-radius: 0 4px 4px 0;
						@include mixins.flex(0 0 auto);
						@include mixins.flexbox();
						@include mixins.justify-content(center);
						@include mixins.align-items(center);
						@include mixins.transition(all .25s);

						input {
							min-width: 0;
							width: auto;
							max-width: none;
							height: 2.875rem;
							margin-right: 0;
							@include mixins.appearance(checkbox);

							&:checked {
								background-color: transparent;
								background-image: none;
								border-color: transparent;
							}
						}

						.label {
							position: absolute;
							top: calc(100% + .5rem);
							right: 0;
							width: 23.75rem;
							font-weight: 600;
							font-size: .75rem;
							text-transform: none;
							text-align: right;
							white-space: nowrap;
						}
					}

					> input:focus + .action {
						border-color: variables.$color_carrot_orange;
					}
				}

				&.checkbox_shown .inputs > input {
					border-right: 0;
					border-radius: 4px 0 0 4px;
					@include mixins.flex(1 1 auto);
				}

				@media (min-width: 58em) {
					width: 23.75rem;
					margin-bottom: 2rem;
					margin-left: 5.375rem;
				}
			}

			&.category {
				&:before {
					content: 'AND';
					position: relative;
					top: -.875rem;
					display: block;
					color: variables.$color_dove_gray;
					font-weight: 700;
					font-size: .875rem;
					letter-spacing: .03143em;

					@media (min-width: 58em) {
						position: absolute;
						top: auto;
						bottom: .75rem;
						right: 100%;
						display: inline-block;
						width: 5.375rem;
						text-align: center;
					}
				}
			}

			&.network {
				margin-bottom: 4rem;

				@media (min-width: 58em) {
					clear: both;
				}

				span.note {
					max-width: 23.75rem;
					margin: 0 auto;
					padding-top: .5rem;
					color: variables.$color_matrix;
					font-style: italic;
					font-size: 1rem;
					text-transform: none;
				}
			}

			span {
				font-weight: 700;
				font-size: 1.125rem;
				letter-spacing: .05556em;
				text-transform: uppercase;

				small {
					position: relative;
					top: -.125rem;
					text-transform: none;
				}
			}

			select {
				background-image: url('../images/generator.svg');
				border-radius: .25rem;
			}

			input,
			select {
				max-width: 23.75rem;
				height: 3rem;

				@media (min-width: 58em) {
					width: 23.75rem;
				}
			}

			div.input {
				@include mixins.flexbox();
				@include mixins.justify-content(center);
				@include mixins.align-items(center);

				input {
					max-width: 20.5rem;
					border-right: 0;
					border-radius: .25rem 0 0 .25rem;

					@media (min-width: 58em) {
						width: 20.5rem;
					}

					&:focus + a {
						border-color: variables.$color_carrot_orange;
					}
				}

				a {
					width: 3.25rem;
					height: 3rem;
					padding: 0 1rem;
					border: 1px solid variables.$color_silver;
					border-radius: 0 .25rem .25rem 0;
					@include mixins.flex(0 0 auto);

					svg {
						margin: .625rem 0 0 0;
					}

					&:hover {
						svg path:nth-of-type(3) {
							fill: variables.$color_white;
						}
					}
				}
			}
		}

		button {
			margin: 0 auto;
			font-family: variables.$font_family_open_sans;
		}
	}
}

body[data-contrast-mode=on] {
	.provider_search_form form {
		label span {
			color: variables.$color_white;

			small {
				color: variables.$color_white;
			}
		}

		label.category:after {
			color: variables.$color_white;
		}

		label.network span.note {
			color: variables.$color_white;
		}
	}
}

.sticky_nav.provider_search .max_content_width {
	@extend %clearfix;
	max-width: none;

	@media (min-width: 30em) {
		display: block;
	}

	.results_header {
		@extend %clearfix;
		text-align: center;
		text-transform: none;
		background: variables.$color_white;
		border-bottom: 1px solid variables.$color_mercury;

		@media (min-width: 60em) {
			@include mixins.flexbox();
			@include mixins.justify-content(space-between);
		}

		& > div {
			padding: 1.25rem 1.5rem 0 1.5rem;
			font-weight: 400;
			line-height: 1.4;
			@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 40em);
			@include mixins.scale(font-size, 1.125rem, 40em, 1.625rem, 60em);


			@media (min-width: 60em) {
				padding-bottom: 1.25rem;
				text-align: left;
				@include mixins.flex(1 1 auto);
			}
		}

		span {
			color: variables.$color_comet;
		}

		strong {
			font-weight: 600;
		}

		a {
			padding: 1rem 1.5rem;
			color: variables.$color_dove_gray;
			font-weight: 600;
			line-height: 2.45;
			letter-spacing: .05556em;
			@include mixins.flexbox(inline);
			@include mixins.justify-content(center);
			@include mixins.align-items(center);
			@include mixins.scale(font-size, .8125rem, 20em, 1.125rem, 40em);

			@media (min-width: 30em) {
				border: 0;
			}

			@media (min-width: 60em) {
				white-space: nowrap;
				border-left: 1px solid variables.$color_alto;
				@include mixins.flex(0 1 auto);
			}

			svg {
				@include mixins.scale(margin-right, .5rem, 20em, 1rem, 40em);
			}
		}
	}

	input,
	select {
		border: 0;
		border-radius: 0;
		text-transform: none;
		white-space: nowrap;
	}

	.input_contain {
		float: left;
		display: none;
		width: 100%;
		height: 3.75rem;
		border-bottom: 1px solid variables.$color_mercury;

		@media (min-width: 34em) {
			display: block;
			max-width: 20rem;
			border-right: 1px solid variables.$color_mercury;
			border-bottom: 0;
		}

		.input {
			height: 3.75rem;
			overflow: hidden;
			@include mixins.flexbox();

			a {
				width: auto;
				padding: 0 1rem;
				background: variables.$color_white;
				border-right: 1px solid variables.$color_alto;
				@include mixins.flexbox();
				@include mixins.align-items(center);

				svg {
					width: 1.125rem;
					height: 1.625rem;
				}
			}

			input {
				line-height: 2.375;
			}
		}
	}

	select {
		height: 3.75rem;
		border-right: 1px solid variables.$color_mercury;

		&:focus {
			color: variables.$color_carrot_orange;
		}

		@media (min-width: 30em) {
			max-width: 10rem;
		}

		&#range_select {
			display: none;

			@media (min-width: 42em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}

		// Facility will only show up for 'Other Facility' searches
		&#facility_select {
			display: none;

			@media (min-width: 50em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}

		&#specialty_select {
			display: none;

			@media (min-width: 52em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}

		&#languages_select {
			display: none;

			@media (min-width: 62em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}

		&#patients_select {
			display: none;

			@media (min-width: 72em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}

		&#gender_select {
			display: none;

			@media (min-width: 78em) {
				display: inline-block;

				&:last-of-type {
					+ .revise_search {
						float: right;
						display: block;

						+ .toggle_search_criteria {
							display: none;
						}
					}
				}
			}
		}
	}

	.revise_search {
		display: none;
		color: variables.$color_dove_gray;
		padding: 0 1rem;
		text-transform: none;
		white-space: nowrap;
		border-left: 1px solid variables.$color_mercury;

		@media (min-width: 78em) {
			float: right;
			display: block;
		}

		svg {
			position: relative;
			top: .1875rem;
			margin-right: .5rem;

			path {
				@include mixins.transition(.25s all);
			}
		}

		&:hover {
			color: variables.$color_comet;

			svg path {
				fill: variables.$color_comet;
			}
		}
	}

	.toggle_search_criteria {
		float: right;
		display: inline-block;
		padding: 0 1.5rem;
		text-align: center;

		@media (min-width: 30em) {
			width: 100%;
			border: 0;
		}

		@media (min-width: 34em) {
			width: auto;
			border-right: 1px solid variables.$color_mercury;
			border-left: 1px solid variables.$color_mercury;
		}

		@media (min-width: 78em) {
			display: none;
		}

		svg {
			position: relative;
			top: .25rem;
			width: 1.1875rem;
			height: 1.1875rem;
			margin-left: .5rem;

			path {
				fill: variables.$color_dusty_gray;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.sticky_nav.provider_search .max_content_width {
		.results_header {
			color: variables.$color_white;
			background-color: variables.$color_black;

			span {
				color: variables.$color_white;
			}

			a {
				color: variables.$color_white;

				svg {
					path,
					polygon {
						fill: variables.$color_white;
						stroke: variables.$color_white;
					}
				}
			}
		}

		.input {
			a {
				background-color: variables.$color_black;

				svg path {
					fill: variables.$color_white;
				}
			}

			input {
				color: variables.$color_white;
				background-color: variables.$color_black;
			}
		}

		.revise_search {
			color: variables.$color_white;

			svg path {
				fill: variables.$color_white;
			}
		}
	}
}

.provider_search_results {
	@extend %clearfix;
	display: none;

	&.has_searched {
		display: block;
	}

	#all_filter_options {
		position: fixed;
		top: 1rem;
		right: 1rem;
		bottom: 1rem;
		left: 1rem;
		display: none;
		background-color: variables.$color_alabaster;
		overflow-y: auto;
		z-index: 6000;
		@include mixins.box-shadow(0 0 1.25rem rgba(variables.$color_black, .2));

		.close {
			@extend %clearfix;
			display: block;
			padding: 1rem;

			svg {
				float: right;
				display: block;
			}
		}

		input,
		select {
			border: 0;
			border-radius: 0;
			text-transform: none;
			white-space: nowrap;
		}

		.input_contain {
			width: 100%;
			max-width: 30rem;
			//height: 3.75rem;
			margin: 0 auto;
			border-top: 1px solid variables.$color_mercury;
			border-right: 1px solid variables.$color_mercury;
			border-left: 1px solid variables.$color_mercury;

			.input {
				height: 3.75rem;
				overflow: hidden;
				@include mixins.flexbox();

				a {
					width: auto;
					padding: 0 1rem;
					background: variables.$color_white;
					border-right: 1px solid variables.$color_alto;
					@include mixins.flexbox();
					@include mixins.align-items(center);

					svg {
						width: 1.125rem;
						height: 1.625rem;
						margin: 0;
					}
				}

				input {
					line-height: 2.375;
				}
			}

			+ select {
				border-top: 1px solid variables.$color_mercury;
			}
		}

		select {
			display: block;
			max-width: 30rem;
			height: 3.75rem;
			margin: 0 auto;
			background-color: transparent;
			border-right: 1px solid variables.$color_mercury;
			border-bottom: 1px solid variables.$color_mercury;
			border-left: 1px solid variables.$color_mercury;

			&:focus {
				color: variables.$color_carrot_orange;
			}
		}

		.revise_search {
			display: block;
			color: variables.$color_dove_gray;
			padding: 1.125rem 1rem;
			text-align: center;
			text-transform: none;
			white-space: nowrap;

			svg {
				position: relative;
				top: .1875rem;
				margin-right: .5rem;

				path {
					@include mixins.transition(.25s all);
				}
			}

			&:hover {
				color: variables.$color_comet;

				svg path {
					fill: variables.$color_comet;
				}
			}
		}
	}

	.results_map {
		.embed-container {
			height: 31.25rem;
			padding-bottom: 0;

			.login_reminder {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 2rem 1.5rem 7rem 1.5rem;
				color: variables.$color_white;
				text-align: center;
				background-color: rgba(color.adjust(variables.$color_comet, $hue: -.29376deg, $saturation: -5.03937%, $lightness: 7.64706%), .96%); // color: color-diff($color_comet, #726C92);
				z-index: 2000;

				@media (min-width: 31.25em) {
					padding-bottom: 5rem;
					@include mixins.flexbox();
					@include mixins.flex-direction(column);
					@include mixins.justify-content(center);
					@include mixins.align-items(center);
				}

				header {
					line-height: 1.4;
					letter-spacing: .03167em;
					@include mixins.scale(font-size, 1.25rem, 20em, 2.25rem, 45em);
				}

				p {
					max-width: 75%;
					margin: 1rem auto;
				}

				.button {
					margin-top: 2rem;
					color: variables.$color_carrot_orange;
					background-color: variables.$color_white;
					border-color: variables.$color_white;

					&:hover {
						color: variables.$color_white;
						background-color: variables.$color_carrot_orange;
						border-color: variables.$color_carrot_orange;
					}
				}

				.dismiss {
					position: absolute;
					right: 1rem;
					bottom: 1.5rem;
					left: 1.5rem;
					color: variables.$color_white;

					&:hover {
						color: variables.$color_carrot_orange;
					}
				}
			}
		}
	}

	.results_list {
		max-width: 73.75rem;
		margin: 2rem auto 0 auto;
		padding: 1.5rem;

		table {
			table-layout: auto;
			text-align: center;
			border: 0;

			@media (min-width: 35em) {
				text-align: left;
			}

			thead {
				display: none;

				@media (min-width: 50em) {
					display: table-header-group;
				}

				tr {
					//border-bottom: 1px dashed $color_mercury;

					th {
						font-weight: 700;
						font-size: .875rem;
						letter-spacing: .04857em;
						text-transform: uppercase;
						background-color: transparent;
					}

					a.sort {
						position: relative;

						&:after {
							content: '';
							position: absolute;
							top: .25rem;
							right: -1.5rem;
							display: block;
							width: 1rem;
							height: .875rem;
							background-position: center;
							background-repeat: no-repeat;
						}

						&.asc:after {
							background-image: url('../images/toggle-select.svg');
						}

						&.desc:after {
							background-image: url('../images/toggle-select.svg');
							@include mixins.transform(rotate(180deg));
						}
					}
				}
			}

			tbody {
				tr {
					display: block;
					//padding-left: 3rem;
					border-bottom: 1px solid variables.$color_mercury;

					@media (min-width: 35em) {
						@include mixins.flexbox();
						@include mixins.flex-wrap(wrap);
						@include mixins.align-items(end);
					}

					@media (min-width: 50em) {
						display: table-row;
						padding-left: 0;
					}

					&.highlight {
						background-color: #fbfbfb ;
						border-left: 4px solid variables.$color_jacarta ;
					}

					td {
						display: block;
						padding: .5rem .75rem;
						background-color: transparent;

						@media (min-width: 35em) {
							width: 50%;
							max-width: 50%;
							@include mixins.flex(1 1 auto);
						}

						@media (min-width: 50em) {
							display: table-cell;
							padding: 1rem .75rem;
						}

						&.checkbox_select {
							float: left;
							position: absolute;
							left: 1.5rem;
							width: 3.5rem;

							@media (min-width: 50em) {
								float: none;
								position: relative;
								left: auto;
								width: 2.5rem;
							}
						}

						&.title {
							font-size: 1.125rem;

							@media (min-width: 35em) {
								@include mixins.order(1);
							}

							@media (min-width: 50em) {
								float: none;
								display: table-cell;
								width: auto;
								padding: 1rem .75rem;
							}

							span {
								display: block;
								margin-top: 1rem;
								color: variables.$color_comet;

								@media (min-width: 50em) {
									margin-top: 0;
								}
							}
						}

						&.address {
							@media (min-width: 35em) {
								padding-bottom: 1.5rem;
								@include mixins.order(3);
							}

							@media (min-width: 50em) {
								float: none;
								display: table-cell;
								width: auto;
								padding: 1rem .75rem;
							}
						}

						&.network {
							font-size: .875rem;

							&:before {
								content: 'Network: ';

								@media (min-width: 50em) {
									display: none;
								}
							}

							@media (min-width: 35em) {
								float: left;
								width: 50%;
							}

							@media (min-width: 50em) {
								float: none;
								display: table-cell;
								width: auto;
								padding: 1rem .75rem;
							}
						}

						&.distance {
							&:before {
								content: 'Distance: ';

								@media (min-width: 50em) {
									display: none;
								}
							}

							@media (min-width: 35em) {
								@include mixins.order(2);
								text-align: right;
							}

							@media (min-width: 50em) {
								float: none;
								display: table-cell;
								width: auto;
								padding: 1rem 1rem;
								text-align: left;
							}
						}

						&.details_link {
							clear: both;
							width: auto;
							padding: 1rem .75rem 2rem .75rem;

							@media (min-width: 35em) {
								@include mixins.order(4);
								text-align: right;
							}

							@media (min-width: 50em) {
								width: 1%;
								padding: 1rem .75rem;
							}
						}

						label.checkbox input {
							width: .9375rem;
							height: .9375rem;
							margin-right: 0;
							background-size: 78%;

							&:checked {
								background-color: variables.$color_mantis;
								border-color: variables.$color_mantis;
							}
						}
					}
				}
			}

			.button {
				min-width: 0;
				padding-right: 1.625rem;
				padding-left: 1.625rem;
				font-size: .875rem;
				letter-spacing: .075em;

				@media (min-width: 50em) {
					white-space: nowrap;
				}
			}
		}
	}

	.results_paginate {
		margin-top: 2rem;
		text-align: center;

		@media (min-width: 48em) {
			@include mixins.flexbox();
			@include mixins.justify-content(space-between);
			@include mixins.align-items(center);
		}

		span {
			display: block;
			padding: 1.75rem 0;
			font-style: italic;
		}

		.button {
			white-space: nowrap;
			background-color: variables.$color_mantis;
			border-color: variables.$color_mantis;

			@media (min-width: 48em) {
				padding-top: .75rem;
				padding-bottom: .75rem;
			}

			&:hover {
				background-color: color.adjust(variables.$color_mantis, $lightness: -12%);
				border-color: color.adjust(variables.$color_mantis, $lightness: -12%);
			}
		}
	}

	.disclaimer {
		padding-top: 1rem;
		font-size: .875rem;
		letter-spacing: .03429em;
	}
}

body[data-contrast-mode=on] {
	.provider_search_results {
		.results_list table tbody tr {
			td.title span {
				color: variables.$color_white;
			}

			td label.checkbox input:checked {
				background-color: variables.$color_black;
				border-color: variables.$color_white;
			}
		}
	}
}

.provider_search_no_results {
	padding: 14vh 12vw;
	font-weight: 600;
	text-align: center;
}

.provider_search_detail {
	display: none;

	&.viewing_details {
		display: block;
	}

	.site_header .fix_ie_wrapper {
		min-height: 15rem !important;
	}

	.max_content_width {
		padding: 2rem 1.5rem;

		.address,
		.phone {
			margin-bottom: 1.5rem;
			letter-spacing: .03143em;
			@include mixins.scale(font-size, 1rem, 20em, 1.75rem, 40em);
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			svg {
				height: auto;
				margin-right: 1rem;
				@include mixins.flex(0 0 auto);
				@include mixins.scale(width, .9375rem, 20em, 1.1875rem, 40em);
			}

			a {
				color: inherit;
				font-weight: 400;
			}
		}

		.address div span {
			display: block;

			@media (min-width: 50em) {
				display: inline;

				&:nth-of-type(1):after {
					content: ', ';
				}
			}
		}

		.details {
			width: auto;
			max-width: 56.25rem;
			margin: 2rem auto;
			text-align: center;
			border: 0;

			tbody {
				tr {
					td {
						display: block;
						line-height: 1.5;
						vertical-align: top;

						&:nth-of-type(1) {
							padding-bottom: 0;
						}

						&:nth-of-type(2) {
							padding-top: 0;
						}

						@media (min-width: 48em) {
							display: table-cell;

							&:nth-of-type(1) {
								width: 50%;
								padding-bottom: 1rem;
								padding-right: 2.5rem;
								text-align: left;
								border-right: 1px dashed;
							}

							&:nth-of-type(2) {
								width: 50%;
								padding-top: 1rem;
								padding-left: 2.5rem;
								text-align: left;
							}
						}

						> div {
							margin-bottom: 1.5rem;
						}

						.label {
							display: block;
							color: variables.$color_jacarta;
							font-weight: 600;
						}

						ul {
							list-style: none;
							display: inline;
						    padding: 0;

							li {
								display: block;
							    margin: 0;

								&:nth-of-type(1) {
									display: inline;
								}
							}
						}
					}
				}
			}
		}
	}

	#directions_form {
		padding: 0;
		@include mixins.flexbox();
		@include mixins.flex-wrap(wrap);

		@media (min-width: 36em) {
			@include mixins.flex-wrap(nowrap);
		}

		label {
			margin: 0;
			@include mixins.flex(1 1 100%);

			input {
				height: 4.6875rem;
				line-height: 3rem;
				border-right: 0;
				border-bottom: 0;
				border-left: 0;
				border-radius: 0;
			}
		}

		button {
			height: 4.6875rem;
			padding: 0 2.5rem;
			line-height: 4.6875rem;
			border-radius: 0;
			@include mixins.flex(1 1 100%);

			@media (min-width: 36em) {
				@include mixins.flex(0 0 auto);
			}
		}
	}

	.embed-container {
		height: 22rem;
		padding-bottom: 0;

		ng-map {
			height: 100% !important;
		}
	}
}

body[data-contrast-mode=on] {
	.provider_search_detail .max_content_width {
		.address,
		.phone {
			svg path {
				fill: variables.$color_white;
			}
		}

		.details tbody tr td span {
			color: variables.$color_white;
		}

		& + #directions_form label input {
			color: variables.$color_white;
			background-color: variables.$color_black;
			border-top: 1px solid variables.$color_white;
		}
	}
}