.pagination_list {
	display: inline-block;
	margin: 0;
	padding: 0;
	border-radius: 3px;
}

.pagination_list li {
	display: inline;
}

.pagination_list li:first-child a {
	border-left-width: 1px;
	border-radius: 3px 0 0 3px;
}

.pagination_list li:last-child a {
	border-radius: 0 3px 3px 0;
}

.pagination_list li.active a {
	color: #999;
	cursor: default;
}

.pagination_list a {
	float: left;
	padding: 0 1em;
	line-height: 2em;
	text-decoration: none;
	border: 1px solid #ddd;
	border-left-width: 0;
}

.pagination_list .active a,
.pagination_list a:hover {
	background-color: #f5f5f5;
}

.pagination_list a svg {
	fill: currentColor;
	position: relative;
	top: .1875rem;
	width: 1.0625rem;
}

.pagination_list li.first svg {
	margin-right: .5rem;
}

.pagination_list li.last svg {
	margin-left: .5rem;
}