@use '../base/variables';

section[class^=Wave] {
	position: relative;
	width: 100%;
	z-index: 1000;

	&.blend_None {
		margin: 3rem 0;
	}

	svg {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;

		.top,
		.bottom {
			fill: transparent;
		}
	}

	.wave {
		display: none;
	}

	&.Green.toRight .wave.greenGradient.toRight{
		display: block;
	}

	&.Green.toLeft .wave.greenGradient.toLeft {
		display: block;
	}

	&.Purple.toRight .wave.purpleGradient.toRight {
		display: block;
	}

	&.Purple.toLeft .wave.purpleGradient.toLeft {
		display: block;
	}

	&.Wave1 {
		height: 9.523076923077vw;

		&.blend_Top {
			margin-top: -9.523076923077vw;
		}

		&.blend_Bottom {
			margin-bottom: -9.323076923077vw;
		}
	}

	&.Wave2 {
		height: 9.255409255409vw;

		&.blend_Top {
			margin-top: -9.255409255409vw;
		}

		&.blend_Bottom {
			margin-bottom: -9.255409255409vw;
		}
	}

	&.Wave3 {
		height: 14.435114503817vw;

		&.blend_Top {
			margin-top: -14.435114503817vw;
		}

		&.blend_Bottom {
			margin-bottom: -14.435114503817vw;
		}
	}

	&.Wave4 {
		height: 13.030534351145vw;

		&.blend_Top {
			margin-top: -13.030534351145vw;
		}

		&.blend_Bottom {
			margin-bottom: -13.030534351145vw;
		}
	}

	&.Wave5 {
		height: 5.801526717557vw;

		&.blend_Top {
			margin-top: -5.601526717557vw;
		}

		&.blend_Bottom {
			margin-bottom: -5.801526717557vw;
		}
	}

	&.blend_Top {
		.bottom {
			fill: variables.$color_alabaster;
		}

		& + section,
		& + a + section {
			//margin-bottom: 5rem;
			background-color: variables.$color_alabaster;
			//border-bottom: 1px solid $color_alto;

			& + .bloq.callout {
				//margin-top: calc(-5rem - 1px);
			}
		}
	}
}

body[data-contrast-mode=on] {
	section[class^=Wave].blend_Top .bottom {
		fill: variables.$color_black;
	}

	section[class^=Wave].blend_Top + section,
	section[class^=Wave].blend_Top + a + section {
		background-color: variables.$color_black;
	}
}