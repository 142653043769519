@use '../base/variables';

.newsroom-card {
  text-align: center;
  border: 2px solid variables.$color_alto;
  border-radius: .3rem;
  padding: .5rem;

  img {
    width: 30%;
  }
}