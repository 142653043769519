.embed-container {
	position: relative;
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}