@use '../base/variables';
@use '../base/mixins';
a.button {
	display: inline-block;
	min-width: 9.75rem;
	padding: .5625rem 2.5rem;
	color: variables.$color_button;
	font-weight: 600;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background: variables.$color_button_background;
	border: 2px solid variables.$color_button_border;
	border-radius: .25rem;

	&:hover {
		color: variables.$color_button_hover;
		background: variables.$color_button_background_hover;
		border-color: variables.$color_button_border_hover;
	}

	&.share {
		padding-right: 1rem;
		padding-left: 1rem;
		@include mixins.flexbox(inline);
		@include mixins.align-items(center);

		svg {
			margin-right: 1rem;
		}
	}
}

body[data-contrast-mode=on] {
	a.button {
		color: variables.$color_black;
		background-color: variables.$color_white;
		border-color: variables.$color_white;
	}
}

a.white_button {
	display: inline-block;
	min-width: 12rem;
	padding: .5625rem 1.5rem;
	color: variables.$color_carrot_orange;
	font-weight: 600;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	background: variables.$color_white;
	border: 2px solid variables.$color_white;
	border-radius: .25rem;
	margin-right: 5rem;
	margin-bottom:1rem;

	&:hover {
		color: variables.$color_white;
		background: variables.$color_carrot_orange;
		border-color: variables.$color_carrot_orange;
	}
}

a.outline_button {
	display: inline-block;
	min-width: 9.75rem;
	padding: .5625rem 1.5rem;
	width:12rem;
	color: variables.$color_white;
	font-weight: 600;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	background: transparent;
	border: 2px solid variables.$color_white;
	border-radius: .25rem;

	&:hover {
		color: variables.$color_white;
		background: variables.$color_carrot_orange;
		border-color: variables.$color_carrot_orange;
	}

	svg {
		margin-right: .5em;

		&:hover {
			fill: #A8A8A8;
		}
	}
}

a.header_button {
	height: 2rem;
	margin-top: 1rem;
	margin-right: 1em;
	display: inline-block;
	min-width: 8.5rem;
	color: variables.$color_button;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	background: variables.$color_button_background;
	border: 2px solid variables.$color_button_border;
	border-radius: .25rem;

	&:hover {
		color: variables.$color_button_hover;
		background: variables.$color_button_background_hover;
		border-color: variables.$color_button_border_hover;
	}
}

a.header_outline_button {
	height: 2rem;
	margin-top: 1rem;
	display: inline-block;
	min-width: 8.5rem;
	color: variables.$color_jacarta;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	background: variables.$color_white;
	border: 2px solid variables.$color_jacarta;
	border-radius: .25rem;

	&:hover {
		color: variables.$color_button_hover;
		background: variables.$color_button_background_hover;
		border-color: variables.$color_button_border_hover;
	}

	svg {
		margin-right: .5em;

		@media(max-width: 60em) {
			margin-top: .25rem;
		}
	}
}
