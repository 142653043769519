@use '../base/variables';
@use '../base/mixins';
@use '../base/placeholders';
@use 'sass:color';

.bloq_heading {
	padding-top: 2rem;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	text-align: center;

	div {
		max-width: 100%;
		color: variables.$color_comet;
		@include mixins.scale(font-size, 1.5rem, 20em, 2rem, 40em);
	}

	p {
		max-width: 100%;
		margin-top: 0;
		margin-bottom: 2rem;
	}
}

body[data-contrast-mode=on] {
	.bloq_heading div {
		color: variables.$color_white;
	}
}

.bloq {
	//margin-bottom: $bloq_media_spacing;

	&.media {
		&.two > *:first-child {
			margin-bottom: variables.$bloq_media_spacing;
		}

		&.content {
			margin-bottom: 0;
		}

		&.two,
		&.three,
		&.four {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}

		@media (min-width: 40rem) {
			&.content,
			&.two {
				align-items: start;
				@include mixins.flexbox();
			}

			&.right,
			&.Right {
				@include mixins.flex-direction(row-reverse);

				.content {
					padding-left: 1.5rem;
				}
			}

			&.left,
			&.Left {
				.content {
					padding-right: 1.5rem;
				}
			}

			&.two > * {
				width: 50%;
				@include mixins.flex(1 1 auto);
			}

			&.content > .media {
				min-width: 36%;
				width: 36%;
				@include mixins.flex(1 1 36%);
			}

			&.content > .content {
				width: 100%;
				max-width: 100%;
				align-items: center;
				@include mixins.flex(1 1 auto);
				@include mixins.flexbox();

				& > * {
					width: 100%;
					max-width: 100%;
				}
			}

			&.two > *:first-child {
				margin-right: variables.$bloq_media_spacing;
				margin-bottom: 0;
			}
		}

		&.three > *:nth-child(1),
		&.three > *:nth-child(2) {
			margin-bottom: variables.$bloq_media_spacing;
		}

		@media (min-width: 30rem) {
			&.three {
				@include mixins.flexbox();
				@include mixins.flex-wrap(wrap);
			}

			&.three > *:nth-child(1) {
				@include mixins.flex(1 1 100%);
			}

			&.three > *:nth-child(2),
			&.three > *:nth-child(3) {
				@include mixins.flex-grow(1);
				@include mixins.flex-shrink(1);
				@include mixins.flex-basis(calc((100% - #{variables.$bloq_media_spacing}) / 2.0001));
				max-width: calc((100% - #{variables.$bloq_media_spacing}) / 2.0001);
				margin-bottom: 0;
			}

			&.three > *:nth-child(2) {
				margin-right: variables.$bloq_media_spacing;
			}

			@media (min-width: 45rem) {
				&.three {
					@include mixins.flex-wrap(nowrap);
				}

				&.three > *:nth-child(1),
				&.three > *:nth-child(2),
				&.three > *:nth-child(3) {
					@include mixins.flex-grow(1);
					@include mixins.flex-shrink(1);
					@include mixins.flex-basis(calc((100% - #{variables.$bloq_media_spacing * 2}) / 3.0001));
					max-width: calc((100% - #{variables.$bloq_media_spacing * 2}) / 3.0001);
					margin-bottom: 0;
				}

				&.three > *:nth-child(1) {
					margin-right: variables.$bloq_media_spacing;
				}
			}
		}

		&.four > *:nth-child(1),
		&.four > *:nth-child(2),
		&.four > *:nth-child(3) {
			margin-bottom: variables.$bloq_media_spacing;
		}

		@media (min-width: 30rem) {
			&.four {
				@include mixins.flexbox();
				@include mixins.flex-wrap(wrap);
			}

			&.four > * {
				@include mixins.flex-grow(1);
				@include mixins.flex-shrink(1);
				@include mixins.flex-basis(calc((100% - #{variables.$bloq_media_spacing}) / 2.0001));
				max-width: calc((100% - #{variables.$bloq_media_spacing}) / 2.0001);
			}

			&.four > *:nth-child(1),
			&.four > *:nth-child(3) {
				margin-right: variables.$bloq_media_spacing;
			}

			&.four > *:nth-child(3) {
				margin-bottom: 0;
			}

			@media (min-width: 45rem) {
				&.four {
					@include mixins.flex-wrap(nowrap);
				}

				&.four > * {
					@include mixins.flex-grow(1);
					@include mixins.flex-shrink(1);
					@include mixins.flex-basis(calc((100% - #{variables.$bloq_media_spacing * 3}) / 4.0001));
					max-width: calc((100% - #{variables.$bloq_media_spacing * 3}) / 4.0001);
				}

				&.four > *:nth-child(2) {
					margin-right: variables.$bloq_media_spacing;
				}

				&.four > *:nth-child(4) {
					margin-right: 0;
				}

				&.four > *:nth-child(1),
				&.four > *:nth-child(2),
				&.four > *:nth-child(3),
				&.four > *:nth-child(4) {
					margin-bottom: 0;
				}
			}
		}

		div header {
			text-align: center;

			div {
				padding-top: 1rem;
				padding-bottom: .3125rem;
				color: variables.$color_comet;
				@include mixins.scale(font-size, 1rem, 20em, 1.375rem, 40em);
			}

			p {
				margin-top: 0;
			}
		}
	}

	&.content:not(.media) {
		ul, ol {
			&.list_column {
				@media (min-width: 50em) {
					@include mixins.column-count(2);

					li {
						@include mixins.break-inside(avoid);
						list-style-position: inside;
					}
				}
			}
		}

		p {
			img {
				display: block;
				max-width: 100%;
				height: auto !important;
			}
		}
	}

	a.media {
		position: relative;
		display: block;
		color: variables.$bloq_overlay_text_color;
		z-index: 1;

		&.video {
			position: relative;
		}

		&:hover {
			color: variables.$bloq_overlay_text_color_hover;
		}

		figure {
			figcaption {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				margin: 0;
				padding: 1.5rem;
				text-align: center;
				text-shadow: 0 0 .5rem rgba(variables.$color_black, .6);
				z-index: 3;
			}

			svg {
				fill: variables.$bloq_icon_color;
				width: 10%;
				min-width: 2.5rem;
				min-height: 2.5rem;
				max-width: 6rem;
				max-height: 6rem;
				@include mixins.filter(drop-shadow(0 0 1rem rgba(variables.$bloq_icon_shadow_color, .6)));
				z-index: 3;
			}
		}

		&.video {
			figure svg {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				max-width: 5rem;
				height: 100%;
				max-height: 5rem;
				margin: 0;
				@include mixins.filter(drop-shadow(0 0 1rem rgba(0, 0, 0, .6)));

				path {
					fill: variables.$color_white;
					@include mixins.transition(.25s all);
				}
			}

			&:hover figure svg path {
				fill: variables.$color_carrot_orange;
			}
		}
	}

	.content {
		padding: variables.$bloq_paragraph_spacing;
		text-align: center;
		align-items: center;
		@include mixins.flexbox();
		@include mixins.flex-direction(column);

		@media (min-width: 40rem) {
			padding: variables.$bloq_paragraph_spacing variables.$bloq_media_spacing;
			text-align: left;
		}

		ul, ol {
			li {
				text-align: left;
			}
		}

		h2 {
			max-width: 100%;
			margin-top: 0;
			margin-bottom: 0;
		}

		p {
			max-width: 100%;
		}
	}
}

.has_blended_wave {
	.bloq.media.content {
		padding-bottom: 14.435114503817vw;
	}

	@media (min-width: 40rem) {
		.bloq.media.content {
			padding-bottom: 0;
			@include mixins.align-items(stretch);

			& > .media {
				figure.aspect {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					height: 100%;
					padding-bottom: 0;
				}
			}

			& > .content {
				padding-bottom: 14.435114503817vw;
			}
		}
	}
}

.has_member_discounts {
	.bloq.media.content > .content ul {
		list-style: none;
		margin: 0 0 1rem 0;
		padding: 0;

		li {
			display: block;
			padding: .625rem 0;
			font-weight: 600;
			border-bottom: 1px solid variables.$color_mercury;
		}

		& + a {

		}
	}
}

body[data-contrast-mode=on] {
	.bloq.media div header div {
		color: variables.$color_white;
	}
}

$breakpoint_tookit_to_two_columns		: 36em;
$breakpoint_tookit_to_three_columns		: 54em;
.bloq.toolkit {
	width: 100%;
	max-width: 68rem;
	margin-right: auto;
	margin-left: auto;


	& > div {
		margin-top: 3rem;

		@media (min-width: $breakpoint_tookit_to_two_columns) {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		& > .icon {
			align-items: center;
			margin: 0 0 1.5rem;
			padding: 2.5rem 1.5rem;
			color: variables.$color_nevada;
			border-radius: .5rem;
			background: variables.$color_white;
			@include mixins.flexbox();
			@include mixins.transition(.25s all);
			@include mixins.flex-direction(column);
			@include mixins.justify-content(flex-start);

			@media (min-width: $breakpoint_tookit_to_two_columns) {
				width: calc((100% - 1.75rem) / 2.0001);
				@include mixins.flex(0 1 auto);

				&:nth-of-type(1) {
					margin-right: 1.75rem;
				}
			}

			@media (min-width: $breakpoint_tookit_to_three_columns) {
				width: calc((100% - 3.5rem) / 3.0001);

				&:nth-of-type(2n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: 1.75rem;
				}
			}

			svg {
				display: block;
				width: 5.625rem;
				height: 5.625rem;
				margin-right: auto;
				margin-bottom: 1.125rem;
				margin-left: auto;

				path,
				circle {
					fill: rgba(variables.$color_comet, .8);
					@include mixins.transition(.25s all);
				}
			}

			span {
				max-width: 100%;
				color: variables.$color_nevada;
				font-weight: 600;
				font-size: 1.125rem;
				line-height: 1.2;
				letter-spacing: .035625em;
				text-align: center;
			}

			p {
				max-width: 100%;
				margin-bottom: 0;
				font-weight: 500;
				text-align: center;
			}
		}

		a.icon:hover {
			svg {
				path,
				circle {
					fill: variables.$color_carrot_orange;
				}
			}
	}
	}

	&:not(.no_border) .icon {
		border: 1px solid variables.$color_alto;
	}
}

body[data-contrast-mode=on] {
	.bloq.toolkit header div,
	.bloq.toolkit header p {
		color: variables.$color_white;
	}

	.bloq.toolkit > div > .icon {
		background-color: variables.$color_black;
		border-color: variables.$color_white;

		p,
		span {
			color: variables.$color_white;
		}

		svg {
			path,
			circle {
				fill: variables.$color_white;
			}
		}
	}
}

.toolkit_wrapper {
	.max_content_width {
		padding: 1.5rem !important;
	}

	& + .toolkit_wrapper {
		margin-top: -1.5rem !important;
		padding-top: 0 !important;

		.max_content_width {
			padding-top: 0 !important;

			.bloq.toolkit div {
				margin-top: 0 !important;
			}
		}

		// @media (min-width: $breakpoint_tookit_to_two_columns) {
		// 	margin-top: -14rem;
		// }
	}
}

$breakpoint_tookit_to_two_columns: 36em;
$breakpoint_tookit_to_three_columns: 54em;

.bloq.plan_finder {

	header {
		text-align: center;

		h3 {
			margin-bottom: 0;
			font-size: 1.75rem;
			font-weight: 600;
		}

		p {
			margin-top: 0;
			font-size: .875rem;
		}
	}

	& > div {
		margin-top: 3rem;
		text-align: center;

		@media (min-width: $breakpoint_tookit_to_two_columns) {
			@include mixins.flexbox();
			@include mixins.flex-wrap(wrap);
			@include mixins.justify-content(flex-start);
		}

		& > .icon {
			margin: 0 0 1.5rem;
			padding: 1.5rem;
			border-radius: .5rem;
			background: variables.$color_white;
			@include mixins.flexbox();
			@include mixins.flex-direction(column);
			@include mixins.transition(.25s all);

			@media (min-width:20em) {
				h2 {
					margin: auto;
				}
			}

			span {
				line-height: 1.25;
				text-align: center;
				padding: .5rem 0;
				font-size: .825rem;
			}

			svg {
				width: 3.625rem;
				height: 3.625rem;
				margin: auto;
			}

			.arrows {
				svg {
					margin-left: .3rem;
					padding-top: .3rem;
					width: 1rem;
					height: 1rem;
				}
			}

			&:last-of-type {
				background-image: url('../images/menu-background.svg'), linear-gradient(to bottom, variables.$color_carrot_orange 0%, color.adjust(variables.$color_carrot_orange, $hue: -0.0067653deg, $saturation: -4.93151%, $lightness: 14.90196%) 100%);
				background-color: #ff6a00;
				background-size: 16em;
				background-repeat: no-repeat;
				background-position: center;
				@media (max-width: $breakpoint_tookit_to_three_columns) {
					background-size: 30em;
				}

				h2 {
					color: white;
					font-size: 1.5rem;
				}

				a {
					background: variables.$color_button;
					border-radius: 0.25rem;
					padding: .5rem 0;
					margin-bottom: 2rem;

					&:hover {
						color: variables.$color_button;
						background-color: variables.$color_comet;
					}

					svg {
						display: none;
					}
				}

				svg {
					visibility: hidden;
				}
			}

			@media (min-width: $breakpoint_tookit_to_two_columns) {
				width: calc((100% - 1.75rem) / 2.0001);
				@include mixins.flex(0 1 auto);

				&:nth-of-type(odd) {
					margin-right: 1.75rem;
				}

				h2 {
					margin-bottom: 0;
					font-size: 1.5rem;
				}
			}

			@media (min-width: $breakpoint_tookit_to_three_columns) {
				width: calc((100% - 3.5rem) / 4.0001);
				display: grid;

				&:nth-of-type(3n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(n + 8)) {
					margin-right: .75rem;
				}

				h2 {
					font-weight: 600;
					font-size: 1.125rem;
					line-height: 1;
					letter-spacing: .035625em;
					text-align: center;
					margin-top: .5rem;
				}

				a {
					font-size: .825rem;
				}

				.arrows {
					svg {
						display: inline;
					}
				}
			}
		}
	}

	&:not(.no_border) .icon {
		border: 1px solid variables.$color_alto;
	}
}

.bloq.php_easy {

	header {
		text-align: center;

		h3 {
			margin-bottom: 0;
			font-size: 1.75rem;
			font-weight: 600;
		}

		p {
			margin-top: 0;
			font-size: .875rem;
		}
	}

	& > div {
		margin-top: 3rem;

		@media (min-width: $breakpoint_tookit_to_two_columns) {
			@include mixins.flexbox();
			@include mixins.flex-wrap(wrap);
			@include mixins.justify-content(flex-start);
		}

		& > .icon {
			margin: 0 0 1.5rem;
			padding: 1rem;
			color: variables.$color_nevada;
			border-radius: .5rem;
			background: variables.$color_white;
			@include mixins.flexbox();
			@include mixins.flex-direction(column);
			@include mixins.justify-content(flex-start);
			@include mixins.align-items(center);
			@include mixins.transition(.25s all);

			@media (min-width: $breakpoint_tookit_to_two_columns) {
				width: calc((100% - 1.75rem) / 2.0001);
				@include mixins.flex(0 1 auto);

				&:nth-of-type(odd) {
					margin-right: 1.75rem;
				}
			}

			@media (min-width: $breakpoint_tookit_to_three_columns) {
				width: calc((100% - 3.5rem) / 3.0001);

				&:nth-of-type(2n + 1) {
					margin-right: 0;
				}

				&:not(:nth-of-type(3n + 3)) {
					margin-right: 1.75rem;
				}
			}

			.borders {
				background: variables.$color_white;
				position: absolute;
				width: 24rem;
				height: 16rem;
				z-index: 1;

				@media(max-width: $breakpoint_tookit_to_two_columns) {
					width: 34rem;
					height: 12rem;
				}
			}

			svg {
				display: block;
				width: 3rem;
				height: 3rem;
				margin-right: auto;
				margin-left: auto;
				z-index: 5;

				path,
				circle {
					/*fill: rgba($color_nevada, .8);*/
					@include mixins.transition(.25s all);
				}
			}

			h2 {
				text-transform: uppercase;
				text-align: center;
				font-weight: 700;
				font-size: 1.5rem;
				z-index: 5;
			}

			span {
				line-height: 1.25;
				text-align: center;
				padding: .5rem 0;
				font-size: .825rem;
				z-index: 5;
			}

			.arrows {
				z-index: 5;
				svg {
					margin-left: .3rem;
					padding-top: .3rem;
					width: 1rem;
					height: 1rem;
					display: inline;
					z-index: 5;
				}
			}

			p {
				max-width: 100%;
				margin-bottom: 0;
				font-weight: 500;
				text-align: center;
				z-index: 5;
			}
		}
	}

	&:not(.no_border) .icon {
		border-top: .85rem solid variables.$color_alto;
		border-bottom: .85rem solid variables.$color_alto;
		border-left: .25rem solid variables.$color_alto;
		border-right: .25rem solid variables.$color_alto;
		border-radius: 0;
	}
}



	.bloq.health_tip {
		margin-bottom: 0;
		padding: 2rem 0;
		background-image: url('../images/health-tip-background.svg');
		background-position: 50% 100%;
		background-repeat: no-repeat;
		background-attachment: scroll;

		.label {
			color: variables.$color_nevada;
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;
		}

		p {
			max-width: 46.875rem;
			margin: 0 auto;
			padding: 4rem 1.5rem;
			color: variables.$color_comet;
			font-weight: 400;
			line-height: 1.35417em;
			text-align: center;
			@include mixins.scale(font-size, 1.5rem, 20em, 3rem, 40em);
		}
	}

	body[data-contrast-mode=on] {
		.bloq.health_tip {
			background-color: variables.$color_black;

			.label,
			p {
				color: variables.$color_white;
			}
		}
	}

	.bloq.faq {

		&.move-up .max_content_width {
			padding-top: 0 !important;
		}

		padding-bottom: 3rem;
	}

	.bloq.callout {
		padding: 2.5rem 1.5rem;
		color: variables.$color_white;
		text-align: center;
		background-color: variables.$color_carrot_orange;

		.max_content_width {
			@media (min-width: 50em) {
				@include mixins.flexbox();
				@include mixins.align-items(center);
			}

			div {
				margin-bottom: 3rem;
				font-size: 1.125rem;
				letter-spacing: .03167em;

				@media (min-width: 50em) {
					margin-right: 3rem;
					margin-bottom: 0;
					text-align: left;
					@include mixins.flex(1 1 100%);
				}

				header {
					line-height: 1.2;
					letter-spacing: .03156em;
					@include mixins.scale(font-size, 1.5rem, 20em, 2rem, 40em);
				}

				p {
					margin-top: .5rem;

					@media (min-width: 50em) {
						margin-bottom: 0;
					}
				}
			}

			.button {
				color: variables.$color_carrot_orange;
				background-color: variables.$color_white;
				border-color: variables.$color_white;

				&:hover {
					color: variables.$color_white;
					background-color: variables.$color_comet;
					border-color: variables.$color_comet;
				}

				@media (min-width: 50em) {
					white-space: nowrap;
					@include mixins.flex(0 0 auto);
				}
			}
		}
	}

	body[data-contrast-mode=on] {
		.bloq.callout {
			background-color: variables.$color_black;
			border-top: 1px solid variables.$color_white;
			border-bottom: 1px solid variables.$color_white;

			.button {
				color: variables.$color_black;

				&:hover {
					color: variables.$color_black;
					background-color: variables.$color_white;
					border-color: variables.$color_white;
				}
			}
		}
	}

	.bloq.blog_listing .col,
	.bloq .blog_listing .col {
		margin-bottom: 2.1875rem;
	}

	.bloq.provider_map {
		padding: 0 1.5rem;

		.max_content_width {
			@media (min-width: 50em) {
				@include mixins.flexbox();
			}
		}

		.blog_listing {
			padding-right: 0;
			padding-left: 0;
			background-color: transparent;

			@media (min-width: 50em) {
				margin-right: 4rem;
				@include mixins.flex(1 1 50%);
			}

			.col {
				width: 100% !important;
				max-width: 100% !important;
				min-height: 18rem;
			}
		}

		.svg_map {
			@include mixins.flexbox();
			@include mixins.flex-direction(column);
			@include mixins.justify-content(center);

			@media (min-width: 50em) {
				@include mixins.flex(1 1 50%);
			}

			svg,
			img {
				display: block;
				width: 100%;
				max-width: 100%;
				height: auto !important;

				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					max-height: 30rem;
				}
			}

			.map_label {
				padding-bottom: 2rem;
				color: variables.$color_comet;
				font-weight: 600;
				font-size: 1.25rem;
				text-align: center;
				@include mixins.scale(margin-top, 1rem, 20em, 4rem, 50em);
			}
		}
	}

	section[class^=Wave].blend_Top + .bloq.provider_map,
	section[class^=Wave].blend_Top + a + .bloq.provider_map {
		background-color: variables.$color_alabaster;
	}

	body[data-contrast-mode='on'] {
		section[class^=Wave].blend_Top + .bloq.provider_map,
		section[class^=Wave].blend_Top + a + .bloq.provider_map {
			background-color: variables.$color_black;
		}
	}

	.bloq.staff_listing {
		padding: 0;

		h2 {
			margin-top: 0;
		}
	}

	.bloq.lead_generation {
		padding: 0 1.5rem;

		@media (min-width: 50em) {
			label {
				float: left;
				width: 48%;

				&:nth-of-type(odd) {
					clear: both;
					margin-right: 4%;
				}
			}

			.g-recaptcha {
				float: left;
				margin-top: 3.1875rem;
			}
		}
	}