@use '../base/mixins';
@use '../base/variables';

.col3 {
	@media (min-width: variables.$breakpoint_col3_to2) {
		@include mixins.flexbox();
		@include mixins.flex-wrap(wrap);
	}

	.col {
		display: block;
		width: 100%;
		margin-bottom: variables.$margin_rows;

		@media (min-width: variables.$breakpoint_col3_to2) {
			width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			max-width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			@include mixins.flex(1 1 auto);

			&:nth-of-type(2n + 1) {
				margin-right: variables.$margin_columns;
			}
		}

		@media (min-width: variables.$breakpoint_col3_to3) {
			width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);
			max-width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);

			&:nth-of-type(2n + 1) {
				margin-right: 0;
			}

			&:not(:nth-of-type(3n + 3)) {
				margin-right: variables.$margin_columns;
			}
		}
	}
}
