@use '../base/mixins';
@use '../base/variables';

/* default */
.map {
	top: 0;
	left: 0;
	width: 100%;
	height: 350px;
}

.map-wrapper {
	position: relative;
	overflow: hidden;
}

/* top is set to 30px to allow for google maps controls. set to 0 if not using google maps */
.mapescape-scroll {
	background: transparent;
	z-index: 1;
	width: 40px;
	right: 0;
	top: 30px;
	position: absolute;
	@include mixins.transition(.25s all);
}

.mapescape-scroll.scroll-inactive {
	right: -40px;
}

.mapescape-scroll-tab {
	width: 35px;
	height: 70px;
	right: 0;
	border-radius: 55px 0 0 55px;
	position: absolute;
	background: rgba(variables.$color_comet, .7) url('../images/mapescape/scroll-icon.png') 70% 50% no-repeat;
	color: #fff;
	background-size: 12px 25px;
	@include mixins.transition(.25s all);
}

/* active state */
.scroll-active .mapescape-scroll-tab:before {
	content: ' ';
	z-index: -1;
	display: block;
	position: absolute;
	left: -10px;
	top: -10px;
	width: 45px;
	height: 90px;
	right: 0;
	border-radius: 55px 0 0 55px;
	background: rgba(variables.$color_white, .9);
	@include mixins.transition(.25s all);
}