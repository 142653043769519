@use '../base/mixins';
@use '../base/variables';

.max_content_width {
	width: 100%;
	max-width: 68rem;
	margin-right: auto;
	margin-left: auto;
}

.site_wrapper {
	width: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;

	&.home {
		background-color: variables.$color_white;
	}

	& > article {
		width: 100%;

		&.interior {
			padding: 0 1.5rem;

			& .max_content_width {
				padding: 3rem 0;

				&:empty {
					display: none;
				}
			}
		}
	}

	a:not(.button) {
		svg {
			margin-left: .5rem;

			path {
				@include mixins.transition(.25s all);
			}
		}

		&:hover svg path {
			fill: variables.$color_comet;
		}
	}
}

body[data-contrast-mode=on] {
	.site_wrapper.home {
		background-color: variables.$color_black;
	}

	.site_wrapper a:not(.button) svg path {
		fill: variables.$color_white;
	}
}

.page_intro {
	max-width: 71rem;
	margin: 0 auto;
	padding: 3rem 1.5rem calc(6.412144504228vw + 1rem) 1.5rem;

	@media (min-width: 48em) {
		padding-bottom: 0;
		@include mixins.flexbox();
		@include mixins.flex-direction(row-reverse);
		@include mixins.justify-content(space-between);
	}

	figure {
		padding-bottom: 73%;

		@media (min-width: 48em) {
			width: 47%;
			max-width: 47%;
			padding-bottom: calc((100vw - 3rem) * .57);
			@include mixins.flex(1 1 auto);
		}

		@media (min-width: 53em) {
			padding-bottom: calc((100vw - 3rem) * .52);
		}

		@media (min-width: 58em) {
			padding-bottom: calc((100vw - 3rem) * .47);
		}

		@media (min-width: 63em) {
			padding-bottom: calc((100vw - 3rem) * .43);
		}

		@media (min-width: 68em) {
			padding-bottom: calc((100vw - 3rem) * .38);
		}

		@media (min-width: 73em) {
			padding-bottom: calc(22rem + 8vw);
		}

		img {

		}
	}

	.content {
		@media (min-width: 48em) {
			padding-bottom: calc(6.412144504228vw + 1rem);
			@include mixins.flex(1 1 auto);
		}

		h2 {
			line-height: 1.21875;
			letter-spacing: .0315625em;
		}

		.date {
			letter-spacing: .031875em;
		}

		a {
			font-weight: 600;
		}
	}

	&.php_fit {
		max-width: 71rem;
		padding-bottom: 0;

		.content {
			padding-bottom: 0;
		}
	}

	@media (min-width: 48em) {
		figure + .content {
			max-width: 47%;
			padding-bottom: 0;
		}
	}

	& + [class^=Wave] {
		.bottom {
			fill: variables.$color_alabaster;
		}

		& + article {
			background-color: variables.$color_alabaster;
			border-bottom: 1px solid variables.$color_alto;

			& + article {
				background-color: variables.$color_alabaster;
				border-bottom: 1px solid variables.$color_alto;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.page_intro + [class^=Wave] .bottom {
		fill: variables.$color_black;
	}

	.page_intro + [class^=Wave] + article,
	.page_intro + [class^=Wave] + article + article {
		background-color: variables.$color_black;
		border-bottom: 1px solid variables.$color_white;
	}
}