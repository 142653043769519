@use '../base/mixins';
@use '../base/variables';

#search_form {
	padding-top: 0;

	.flex {
		margin-bottom: 3rem;
		padding: 1rem 0;
		border-bottom: 1px solid variables.$color_alto;
		@include mixins.flexbox();

		#keywords {
			@include mixins.flex(1 1 100%);
			margin-right: 1.5rem;
			font-size: 1.375rem;
			border: 0;
			border-radius: 0;
		}

		button {
			@include mixins.flex(0 0 auto);
			position: relative;
			float: right;
			min-width: 0;
			width: 3.4375rem;
			height: 3.4375rem;
			padding: 0;
			background-color: transparent;
			border: 1px solid variables.$color_alto;
			border-radius: 50%;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 1rem;
				height: 1rem;
				margin: 0 auto;

				path {
					@include mixins.transition(.25s all);
				}
			}

			&:hover {
				background-color: variables.$color_comet;
				border-color: variables.$color_comet;

				svg path {
					fill: variables.$color_white;
				}
			}
		}
	}

	.search_content {
		@include mixins.flexbox();
		@include mixins.flex-direction(column-reverse);

		@media (min-width: 50em) {
			@include mixins.flex-direction(row-reverse);
		}

		aside {
			padding-top: 4rem;
			text-align: center;
			border-top: 1px solid variables.$color_mercury;

			@media (min-width: 25em) {
				@include mixins.flexbox();
				@include mixins.flex-wrap(wrap);
				@include mixins.justify-content(space-around);
			}

			@media (min-width: 50em) {
				display: block;
				max-width: 30rem;
				margin-left: 3rem;
				padding-top: 0;
				padding-left: 2rem;
				text-align: left;
				border-top: 0;
				border-left: 1px solid variables.$color_mercury;
			}

			.result_counts {
				margin-top: 2rem;

				@media (min-width: 25em) {
					@include mixins.flex(1 0 100%);
					@include mixins.flexbox();
					@include mixins.justify-content(space-around);
				}

				@media (min-width: 50em) {
					display: block;
				}

				.result_count {
					@include mixins.flexbox();
					@include mixins.justify-content(center);
					@include mixins.align-items(center);

					@media (min-width: 50em) {
						display: block;
						white-space: nowrap;
					}

					svg {
						position: relative;
						top: -.25rem;
						margin-right: .5rem;

						@media (min-width: 50em) {
							top: 0;
						}
					}

					strong {
						padding-right: .25rem;
					}
				}
			}

			section {
				margin: 2rem 0;

				@media (min-width: 25em) {
					@include mixins.flex(1 0 50%);
					text-align: center;
				}

				@media (min-width: 50em) {
					text-align: left;
				}

				header {
					margin-bottom: .375rem;
					font-style: italic;
					font-size: 1.125rem;
				}

				&.normal {
					header {
						margin-bottom: 0;
						font-weight: 600;
						font-style: normal;
					}

					p {
						margin-top: 0;
						font-size: .875rem;
					}
				}

				ul {
					list-style: none;
					margin: 0;
					padding: 0;

					li a {
						font-weight: 400;

						&.active {
							color: variables.$color_comet;
							font-weight: 600;
						}
					}
				}
			}
		}

		.results {
			#site_results,
			#portal_results {
				margin-bottom: 4rem;

				&:empty {
					display: none;
				}
			}

			.search_result {
				margin-bottom: 4rem;

				h3 {
					margin: 0;

					a {
						color: variables.$color_comet;
						font-weight: 400;
					}

					svg {
						position: relative;
						top: -.5rem;
						margin-right: .5rem;

						path {
							fill: variables.$color_comet;
						}
					}
				}

				a {
					display: inline-block;
					  overflow-wrap: break-word;
			  			  word-wrap: break-word;
			  			 word-break: break-word;
			  			-ms-hyphens: auto;
			  	   	   -moz-hyphens: auto;
			  		-webkit-hyphens: auto;
			  				hyphens: auto;
				}
			}

			.load_more a {
				@include mixins.justify-content(flex-start);
			}

			#portal_results:not(:empty) + #site_results:not(:empty) {
				padding-top: 3rem;
				border-top: 1px dashed variables.$color_comet;
			}
		}
	}
}

body[data-contrast-mode='on'] {
	#search_form {
		.flex button svg path {
			fill: variables.$color_white;
		}

		.search_content {
			.results #portal_results:not(:empty)+#site_results:not(:empty) {
				border-color: variables.$color_white;
			}

			aside {
				.result_counts .result_count svg g {
					fill: variables.$color_white;
				}

				section ul li a.active {
					color: variables.$color_white;
				}
			}
		}
	}
}

.trending_searches {
	header {
		@include mixins.flexbox();
		@include mixins.align-items(center);
		@include mixins.scale(font-size, 1.125rem, 20em, 1.625rem, 40em);

		svg {
			margin-right: 1.5rem;

			path {
				fill: variables.$color_carrot_orange;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;
		@include mixins.flexbox();
		@include mixins.flex-wrap(wrap);

		li a {
			display: block;
			padding: 1rem 1.5rem;
		}
	}
}