@use '../base/variables';
@use '../base/mixins';
.col2 {
	@media (min-width: variables.$breakpoint_col2) {
		@include mixins.flexbox();
		@include mixins.flex-wrap(wrap);
	}

	.col {
		display: block;
		width: 100%;
		margin-bottom: variables.$margin_rows;

		@media (min-width: variables.$breakpoint_col2) {
			width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			max-width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			@include mixins.flex(1 1 auto);

			&:nth-of-type(odd) {
				margin-right: variables.$margin_columns;
			}
		}
	}
}
