@use '../base/mixins';
@use '../base/variables';
@use 'sass:color';

.journey_header_lead_gen {
	@media (min-width: 60em) {
		@include mixins.flexbox();
	}

	.img {
		position: relative;
		min-height: 24rem;
		height: auto;

		@media (min-width: 60em) {
			flex: auto;
			min-height: 34rem;
		}

		.img_background {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: transparent center / cover no-repeat scroll;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.64) 100%);
			}
		}

		.content {
			position: absolute;
			right: 2rem;
			bottom: 2rem;
			left: 2rem;
			color: variables.$color_white;

			.heading {
				margin-bottom: 1rem;
				font-weight: 700;
				font-size: 2.25rem;
				line-height: 1.2;
			}

			.subheading {}
		}
	}

	.form {
		padding: .25rem 2.5rem;
		background-image: linear-gradient(-180deg, #726C92 2%, #8681A2 98%);

		@media (min-width: 60em) {
			width: 29.25rem;
		}

		div:not(.errors) {
			margin-top: 1rem;
			color: variables.$color_white;
			font-weight: 500;
			font-size: 1.75rem;
			line-height: 1.3;
		}

		.errors {
			color: variables.$color_white;
		}

		.thank-you {
			padding: 5rem 0;
			color: variables.$color_white;

			@media (min-width: 60em) {
				padding: 15rem 0;
			}
		}

		form {
			padding-top: .5rem;

			label {
				span {
					color: variables.$color_white;
					font-size: 1rem;

					&.required {
						&::before {
							display: none;
						}

						&::after {
							content: '*';
							padding-left: .25rem;
						}
					}
				}

				@media (min-width: 30em) {
					&.first_name {
						float: left;
						width: 49%;
						margin-right: 2%;
					}

					&.last_name {
						float: left;
						width: 49%;
					}

					&.email {
						clear: both;
						float: left;
						width: 49%;
						margin-right: 2%;
					}

					&.phone {
						float: left;
						width: 49%;
					}

					&.company {
						clear: both;
					}
				}
			}
		}

		button {
			width: 100%;
			margin-top: .75rem;
			text-align: center;
		}
	}
}

.i_links.journey {
	@media (min-width: 36em) {
		display: block;
	}

	@media (min-width: 50em) {
		@include mixins.flexbox();
	}

	& > div {
		height: auto;
		padding: 1.75rem 1.5rem 1.5rem 1.5rem;
	}

	.i_have {
		height: auto;

		@media (min-width: 36em) {
			min-height: 17rem;
			height: auto;
		}

		@media (min-width: 50em) {
			min-height: 34rem;
		}

		.img_background {
			opacity: 1;
		}
	}

	.i_am {
		background-color: color.adjust(variables.$color_jacarta, $hue: -.31746deg, $saturation: -10.21035%, $lightness: 7.2549 * 1%);
		@include mixins.linear-gradient(to bottom,
						color.adjust(variables.$color_jacarta, $hue: -.31746deg, $saturation: -10.21035%, $lightness: 7.2549%) 0%,
						color.adjust(variables.$color_jacarta, $hue: .20202deg, $saturation: -21.91781%, $lightness: 28.43137%) 100%
		);
		@media (min-width: 36em) {
			min-height: 17rem;
		}

		@media (min-width: 50em) {
			min-height: 34rem;
			@include mixins.align-items(start);
			@include mixins.scale(padding-left, 1.5rem, 36em, 4rem, 60em);
		}

		header {
			max-width: 100%;

			@media (min-width: 50em) {
				text-align: left;
			}

			span {
				&:nth-of-type(1) {
					font-weight: 600;
					letter-spacing: .03947em;
					@include mixins.scale(font-size, 1.875rem, 20em, 2.375rem, 40em);
				}

				&:nth-of-type(2) {
					padding-top: .5rem;
					font-weight: 400;
					line-height: 1.5;
					letter-spacing: .03167em;
					text-transform: none;
					@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 40em);
				}
			}
		}

		.button {
			@media (min-width: 50em) {
				margin-right: 0;
				margin-bottom: 0;
				margin-left: 0;
			}
		}
	}
}

body[data-contrast-mode='on'] {
	.i_links.journey .i_am {
		background-color: variables.$color_black;
		background-image: none;
	}
}

.journey section:first-of-type .max_content_width .bloq.media {
	padding-top: 3rem;
}

.journey .bloq.content,
.journey .bloq.faq {
	padding: 3rem 1.5rem;
}

.journey > section:last-of-type:not([class^=Wave]):not(.bloq.callout) {
	margin-bottom: 0 !important;
	background-color: variables.$color_alabaster;
	border-bottom: 1px solid variables.$color_alto;
}

body[data-contrast-mode='on'] {
	.journey > section:last-of-type:not([class^=Wave]):not(.bloq.callout) {
		background-color: variables.$color_black;
	}
}