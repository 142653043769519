/**
 *		element/.class/#id {
 *			@extend %clearfix;
 *		}
 */
%clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

/**
 *		element/.class/#id {
 *			@extend %fullcoverage;
 *		}
 */
%fullcoverage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

/**
 *		element/.class/#id {
 *			@extend %center;
 *		}
 */
%center {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		 -o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
}