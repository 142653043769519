@use '../base/variables';

.table_overflow {
	width: 100%;
	margin-bottom: 2rem;
	overflow-x: auto;

	table {
		min-width: 40rem;
	}
}

table {
	table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	border: 1px solid variables.$color_table_borders;

	thead {
		tr {
			border-bottom: 1px solid variables.$color_table_borders;

			th {
				padding: .75rem;
				font-weight: 400;
				font-size: .875rem;
				font-family: variables.$font_family_table_headers;
				text-align: left;
				background: variables.$color_table_header_background;
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 1rem .75rem;

				&.no_results_message {
					padding: 4rem 0;
					text-align: center;
				}
			}

			&:nth-of-type(even) td {
				background: variables.$color_table_alternate_rows;
			}
		}
	}
}