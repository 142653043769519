@use '../base/mixins';
@use '../base/variables';
@use '../base/placeholders' as *;

.glossary_intro {
	max-width: 73rem;
	margin: 0 auto;
	padding-top: 4.5rem;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	@include mixins.scale(padding-bottom, 5rem, 20em, 7rem, 48em);

	@media (min-width: 48em) {
		padding-bottom: 0;
		@include mixins.flexbox();
		@include mixins.justify-content(space-between);
	}

	.bloq.media {
		margin-bottom: 0;
	}
}

.glossary_listing {
	margin-top: -8rem;
	padding: 3rem 1.5rem;
	background-color: variables.$color_alabaster;

	h2 {
		margin-bottom: .75rem;
	}

	.glossary_listing_term {
		@extend %clearfix;
		margin-bottom: 2rem;

		figure {
			position: relative;

			@media (min-width: 32em) {
				float: left;
				width: 50%;
				max-width: 16rem;
				margin-right: 1.5rem;
				margin-bottom: .25rem;
			}

			img {
				display: block;
			}
		}

		a.video {
			figure svg {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				max-width: 5rem;
				height: 100%;
				max-height: 5rem;
				margin: 0;
				@include mixins.filter(drop-shadow(0 0 1rem rgba(0, 0, 0, .6)));

				path {
					fill: variables.$color_white;
					@include mixins.transition(.25s all);
				}
			}

			&:hover figure svg path {
				fill: variables.$color_carrot_orange;
			}
		}

		p {
			margin: 0;
		}


	}
}

body[data-contrast-mode=on] {
	.glossary_listing {
		background-color: variables.$color_black;
	}
}