@use '../base/mixins';

/**
 *		Override image paths
 */
#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url('../images/fancybox/fancybox_sprite.png') !important;
}

#fancybox-loading div {
	background-image: url('../images/fancybox/fancybox_loading.gif') !important;
}

.fancybox-nav {
	background-image: url('../images/fancybox/blank.gif') !important; /* helps IE */
}

.fancybox-overlay {
	background-color: rgba(16, 12, 12, .69);
	background-image: none;
	overflow-x: hidden !important;
}

/*Retina graphics!*/
@media
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url('../images/fancybox/fancybox_sprite@2x.png') !important;
	}

	#fancybox-loading div {
		background-image: url('../images/fancybox/fancybox_loading@2x.gif') !important;
	}
}

/**
 *		Fix nav button placement for video (iframe) lightboxes
 */
.fancybox-type-iframe .fancybox-nav,
[data-fancybox-type=iframe] .fancybox-nav {
	top: calc(50% - 1.5rem);
	width: 48px;
	height: 38px;
}

.fancybox-type-iframe .fancybox-nav span,
[data-fancybox-type=iframe] .fancybox-nav span {
	left: 50%;
	margin-top: 0;
	visibility: visible;
	@include mixins.transform(translate(-50%, -50%));
}

.fancybox-type-iframe .fancybox-next,
[data-fancybox-type=iframe] .fancybox-next {
	right: -35px;
}

.fancybox-type-iframe .fancybox-prev,
[data-fancybox-type=iframe] .fancybox-prev {
	left: -35px;
}