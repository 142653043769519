@use '../base/functions';

@mixin flex-wrap($val) {
  & {
    flex-wrap: $val;
  }
}

@mixin align-items($val) {
  & {
    align-items: $val;
  }
}

@mixin transition($val...) {
  & {
    transition: $val;
  }
}

@mixin transform($val) {
  & {
    transform: $val;
  }
}

@mixin transform-origin($val) {
  transform-origin: $val;
}

@mixin transform-style($val) {
  transform-style: $val;
}

@mixin flex($val) {
  flex: $val;
}

@mixin flex-grow($val) {
  flex-grow: $val;
}

@mixin flex-shrink($val) {
  flex-shrink: $val;
}

@mixin flex-basis($val) {
  flex-basis: $val;
}

@mixin order($val) {
  order: $val;
}

@mixin align-self($val) {
  align-self: $val;
}

@mixin align-content($val: stretch) {
  align-content: $val;
}

@mixin animation($val) {
  & {
    animation: $val;
  }

}

@mixin appearance($val: none) {
  appearance: $val;
  &::-ms-expand {
    display: none;
  }
}

@mixin backface-visibility($val) {
  backface-visibility: $val;
}

@mixin border-radius($val) {
  border-radius: $val;
}

@mixin box-shadow($val...) {
  & {
    box-shadow: $val;
  }

}

@mixin break-inside($val: auto) {
  break-inside: $val;
}

@mixin column-count($count: 1) {
  column-count: $count;
}

@mixin column-span($val: all) {
  column-span: $val;
}

@mixin column-gap($val: 1em) {
  column-gap: $val;
}

@mixin perspective($val) {
  perspective: $val;
}

@mixin perspective-origin($val) {
  perspective-origin: $val;
}

@mixin linear-gradient($direction, $gradients...) {
  & {
    background-image: linear-gradient($direction, $gradients);
  }

}

@mixin radial-gradient($center, $shape, $gradients...) {
  background-image: radial-gradient($center, $shape, $gradients);
}

@mixin filter($val) {
  filter: $val;
}

@mixin scale($prop, $start-size, $start-width, $end-size, $end-width) {
	& {
		#{$prop}: #{$start-size};
	}

	@media (min-width: #{$start-width}) {
		#{$prop}: calc(#{$start-size} + #{functions.strip-unit($end-size - $start-size)} * (100vw - #{$start-width}) / #{functions.strip-unit($end-width - $start-width)});
	}

	@media (min-width: #{$end-width}) {
		& {
			#{$prop}: #{$end-size};
		}
	}
}

@mixin flexbox($layout: 'block') {
	& {
		display: flex;
	}

	@if $layout == 'inline' {
		& {
			display: inline-flex;
		}
	}
}

@mixin flex-direction($val) {
  &{
    flex-direction: $val;
  }

}

@mixin justify-content($val) {
  & {
    justify-content: $val;
  }

}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin object-fit($val, $position: null) {
  object-fit: $val;
  @if $position {
    object-position: $position;
  }
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
