@use '../base/mixins';

.areas_we_serve {
	figure.aspect img {
		@include mixins.object-fit(contain);
	}
}

.areas_we_serve_map {
	height: 15.625rem;
	padding-bottom: 0;

	@media (min-width: 28em) {
		height: 0;
		padding-bottom: 56.25%;
	}

	@media (min-width: 45em) {
		height: 25rem;
		padding-bottom: 0;
	}
}