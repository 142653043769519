@use '../base/variables';
@use '../base/mixins';

article.members {
	padding: 10vw 7.5vw;
	// @include scale(padding-right, 7.5vw, 20em, 15vw, 40em);
	// padding-bottom: 10vw;
	// @include scale(padding-left, 7.5vw, 20em, 15vw, 40em);

	.forms {
		width: 100%;
		max-width: 30rem;
		margin: 0 auto;
		@include mixins.flexbox();
		@include mixins.flex-direction(column);
		@include mixins.align-items(center);

		@media (min-width: 60em) {
			max-width: none;
			@include mixins.flex-direction(row);
			@include mixins.justify-content(center);
		}

		header {
			font-size: 1.75rem;
		}

		.login_form {
			width: 100%;
			background-color: variables.$color_white;
			border: 1px solid variables.$color_mercury;
			@include mixins.scale(padding, 1.5rem, 20em, 2.5rem, 40em);

			@media (min-width: 60em) {
				@include mixins.flex(1 1 50%);
				max-width: 30rem;
			}

			form {
				padding: 0;

				input,
				select {
					border-top: 0;
					border-right: 0;
					border-left: 0;
					border-radius: 0;
				}

				button {
					display: block;
					width: 100%;
					margin-top: 2rem;

				}

				a {
					display: block;
					margin-top: 1.5rem;
					font-weight: 400;
					text-align: center;
				}
			}
		}

		.register_link {
			margin-top: 2.5rem;
			color: variables.$color_white;
			text-align: center;
			background-color: rgba(variables.$color_comet, .9);
			background-image: url('../images/signup-background.svg');
			background-position: 100% 100%;
			background-size: 18rem;
			background-repeat: no-repeat;
			background-attachment: scroll;
			@include mixins.scale(padding, 1.5rem, 20em, 2.5rem, 40em);

			@media (min-width: 60em) {
				max-width: 30rem;
				height: 85%;
				margin-top: 0;
				text-align: left;
				@include mixins.flex(1 1 50%);
			}

			.button {
				margin-top: 2rem;
			}

			a,span {
				display: inline-block;
				margin-bottom: .5rem;
				color: variables.$color_white;
				font-weight: 700;
				border-bottom: 1px solid variables.$color_white;

				&:hover {
					color: variables.$color_carrot_orange;
					border-color: variables.$color_carrot_orange;
				}

				.glossarizer_replaced {
					text-decoration: none;
					border: 0;
				}
			}
		}
	}
}

body[data-contrast-mode=on] {
	.members {
		background-color: variables.$color_black;
		background-image: none;

		.login_form {
			background-color: variables.$color_black;
			border: 1px solid variables.$color_white;
		}

		.register_link {
			background-color: variables.$color_black;
			border: 1px solid variables.$color_white;
			border-top: 0;

			@media (min-width: 60em) {
				border-top: 1px solid variables.$color_white;
				border-left: 0;
			}
		}
	}
}

.registration_form {
	padding-bottom: 4rem;
	text-align: center;

	.step_title {
		padding: 3rem 0;
		color: variables.$color_comet;
		font-weight: 600;
		text-align: center;
		@include mixins.flexbox();
		@include mixins.scale(font-size, 1.25rem, 20em, 1.75rem, 40em);
		@include mixins.flex-direction(column);
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		@media (min-width: 36em) {
			@include mixins.flex-direction(row);
		}

		span {
			display: inline-block;
			width: 3.625rem;
			height: 3.625rem;
			margin-bottom: .5rem;
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 3.25rem;
			border: 3px solid variables.$color_mantis;
			border-radius: 50%;

			@media (min-width: 36em) {
				margin-right: 1.5rem;
				margin-bottom: 0;
			}
		}

		small {
			position: relative;
			top: .25rem;
			padding-left: .75rem;
			font-weight: 600;
			font-size: 1rem;
		}
	}

	label {
		width: 100%;
		max-width: 23.875rem;

		.label {
			font-weight: 700;
			font-size: 1.125rem;
			text-transform: uppercase;
		}

		.separator {
			display: inline-block;
		}

		.note {
			padding-top: .625rem;
			font-style: italic;
			font-weight: 600;
			font-size: .875rem;
		}

		.password_requirements {
			position: absolute;
			display: none;
			width: 100%;
			color: variables.$color_dove_gray;
			font-size: .875rem;
			text-align: left;
			background-color: variables.$color_white;
			border-bottom: .6875rem inset variables.$color_comet;
			box-shadow: 0 0 .9375rem rgba(variables.$color_black, .2);
			z-index: 1000;

			&:before {
				content: 'x';
				position: absolute;
				top: 0;
				right: 0;
				padding: .5rem 1.125rem;
				color: variables.$color_carrot_orange;
				font-size: 1.125rem;
				cursor: pointer;
			}

			ul {
				margin: 0;
				padding: 1rem 1rem 1rem 2rem;

				ul {
					padding-bottom: 0;
				}
			}

			& > ul > li:first-of-type {
				padding-right: 1.875rem;
			}
		}

		&.member_id {
			&:after {
				content: 'or';
				display: block;
				padding: 1.5rem 0 1rem 0;
				font-weight: 600;
				font-size: 1.4375rem;
			}
		}

		&.ssn {
			&:after {
				content: '&';
				display: block;
				padding-top: 1.5rem;
				font-weight: 600;
				font-size: 1.4375rem;

				@media (min-width: 40em) {
					display: none;
				}
			}

			@media (min-width: 40em) {
				.flex:after {
					content: '&';
					position: absolute;
					left: 100%;
					padding: 0 1.0625rem;
					font-weight: 600;
					font-size: 1.4375rem;
				}
			}

			input {
				max-width: 6.875rem;
				text-align: center;
			}
		}

		&.date_of_birth {
			input:nth-of-type(1),
			input:nth-of-type(2) {
				max-width: 4.6875rem;
				text-align: center;
			}

			input:nth-of-type(3) {
				max-width: 6.875rem;
				text-align: center;
			}
		}

		.flex {
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);
		}
	}

	& > label,
	& > .double {
		margin: 0 auto;
		text-align: center;
	}

	& > .double {
		& > label {
			display: inline-block;
		}

		@media (min-width: 40em) {
			@include mixins.flexbox();
			@include mixins.justify-content(center);

			label:first-of-type {
				margin-right: 3rem;
			}
		}
	}

	button {
		margin-top: 2rem;

		&[type=reset] {
			color: variables.$color_carrot_orange;
			background-color: variables.$color_white;

			@media (min-width: 25em) {
				margin-left: 1rem;
			}

			&:hover {
				color: variables.$color_comet;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.registration_form {
		.step_title,
		label {
			color: variables.$color_white;
		}
	}
}