/**
 *		Project (style guide)
 */
$color_jacarta								: #362e64;
$color_comet								: #5d5681;
$color_carrot_orange						: #ed822b;
$color_nevada								: #6a737b;
$color_mantis								: #7cbf4b;
$color_dove_gray							: #717171;
$color_home_gray                            : #f3f3f3;

$color_timber_green							: #1c2e36;
$color_silver								: #ccc;
$color_silver_chalice						: #a4a4a4;
$color_monza								: #d0021b;
$color_steel_gray							: #242230;
$color_gray									: #858585;
$color_swiss_coffee							: #dbd4d4;
$color_kimberly								: #746e96;
$color_outer_space							: #383e43;
$color_froly								: #ef6273;
$color_mulled_wine							: #4c4671;
$color_dusty_gray							: #959595;
$color_matrix								: #b45b66;
$color_scorpion								: #595959;
$color_dolphin								: #656082;
$color_athens_gray							: #ecebf0;
$color_rum									: #6c6688;
$color_charade								: #2b2a35;
$color_east_bay								: #433d6a;
$color_waterloo								: #8580a1;
$color_port_gore							: #2e2750;
$color_martinique							: #2d2a46;
$color_conifer								: #b4ec51;
$color_vida_loca							: #429321;

$font_stack_serif							: serif;
$font_stack_sans_serif						: sans-serif;

$font_family_open_sans						: 'Open Sans', $font_stack_sans_serif;

/**
 *		Standards (feel free to add, but don't delete existing!)
 */
$color_black								: #000;
$color_white								: #fff;

$color_alabaster							: #fafafa;
$color_alto									: #dbdbdb;
$color_gallery								: #efefef;
$color_mercury								: #e5e5e5;
$color_mine_shaft							: #323232;
$color_regent_gray							: #86949f;
$color_tamarillo							: #991613;
$color_wild_sand							: #f4f4f4;

/**
 *		Base elements
 */
$color_body									: $color_dove_gray;
$color_hyperlink							: $color_carrot_orange;
$color_hyperlink_hover						: $color_comet;

$font_family_body							: $font_family_open_sans;
$font_family_header							: $font_family_open_sans;

$font_size_h1								: 3rem;
$font_size_h2								: 2rem;
$font_size_h3								: 1.75rem;
$font_size_h4								: 1.5rem;
$font_size_h5								: 1.25rem;
$font_size_h6								: 1.125rem;

//	Sitewide alerts
$color_informational_background				: $color_mantis;
$color_urgent_background					: $color_monza;

//	Menu
$color_menu_borders							: $color_regent_gray;
$color_menu									: $color_black;
$color_menu_hover							: $color_white;
$color_menu_background						: $color_white;
$color_menu_background_hover				: $color_regent_gray;

//	Blockquotes
$color_blockquote							: $color_comet;
$font_family_blockquote						: $font_family_open_sans;

//	Button Links
$color_button								: $color_white;
$color_button_hover							: $color_white;

$color_button_background					: $color_carrot_orange;
$color_button_background_hover				: $color_comet;

$color_button_border						: $color_carrot_orange;
$color_button_border_hover					: $color_comet;

/**
 *		Tables
 */
$color_table_borders						: $color_alto;
$color_table_header_background				: $color_wild_sand;
$color_table_alternate_rows					: $color_wild_sand;

$font_family_table_headers					: $font_family_open_sans;

/**
 *		Forms
 */
$color_errors								: $color_monza;
$color_labels								: $color_comet;

$color_input_text							: $color_body;
$color_input_placeholder					: $color_silver_chalice;
$color_input_border							: $color_silver;
$color_input_border_focus					: $color_carrot_orange;
$color_input_border_error					: $color_monza;
$color_input_border_complete				: $color_mantis;

$color_number_background					: $color_mercury;
$color_checkbox_background					: $color_carrot_orange;

$font_family_labels							: $font_family_open_sans;
$font_family_legends						: $font_family_open_sans;

/**
 *		Store (cart, checkout, etc)
 */
 // Steps
$color_cart_steps_background				: $color_alto;
$color_cart_steps_background_active			: $color_mine_shaft;
$font_family_steps_labels					: $font_stack_sans_serif;
$breakpoint_cart_steps_to_horizontal		: 32rem;

// Cart
$color_cart_items_border					: $color_alto;
$color_cart_discount						: $color_tamarillo;

/**
 *		Column layouts
 */
$breakpoint_col2							: 30rem; // Break to 2 columns

$breakpoint_col3_to2						: 24rem; // Break to 2 columns
$breakpoint_col3_to3						: 45rem; // Break to 3 columns

$breakpoint_col4_to2						: 15rem; // Break to 2 columns
$breakpoint_col4_to3						: 30rem; // Break to 3 columns
$breakpoint_col4_to4						: 45rem; // Break to 4 columns

$breakpoint_col5_to2						: 12rem; // Break to 2 columns
$breakpoint_col5_to3						: 24rem; // Break to 3 columns
$breakpoint_col5_to4						: 36rem; // Break to 4 columns
$breakpoint_col5_to5						: 48rem; // Break to 5 columns

$margin_columns								: 2.1875rem; // Whitespace between columns
$margin_rows								: 2.1875rem; // Whitespace between rows

/**
 *		Bloqs
 */
$bloq_overlay_color							: $color_comet;
$bloq_overlay_text_color					: $color_white;
$bloq_overlay_text_color_hover				: $color_white;
$bloq_overlay_text_shadow_color				: $color_black;
$bloq_icon_color							: $color_white;
$bloq_icon_shadow_color						: $color_black;
$bloq_media_spacing							: 5rem;
$bloq_paragraph_spacing						: 1.5rem;
