@use '../base/mixins';

.load_more {
	@include mixins.flex(1 1 100%);
	margin-top: 1rem;

	a {
		font-weight: 600;
		letter-spacing: .031875em;
		text-transform: uppercase;
		@include mixins.flexbox();
		@include mixins.justify-content(flex-end);
		@include mixins.align-items(center);

		svg {
			display: none;
			margin-left: 0;
			margin-right: 1.5rem;
			@include mixins.transition(.1s all);
			@include mixins.animation(2s linear infinite rotate);

		}

		&.waiting {
			svg {
				display: block;
			}
		}
	}
}
