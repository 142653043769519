@use '../base/variables';
@use '../base/mixins';
$breakpoint_to_horizontal_pager : 37.5em;
$breakpoint_to_flex_row_interior: 75em;
.testimonials {
	position: relative;
	padding: 3rem 0;
	background-color: variables.$color_white;

	&.brokers {
		background-color: variables.$color_alabaster;
	}

	& > header {
		position: relative;
		color: variables.$color_comet;
		font-size: 2rem;
		letter-spacing: .03625em;
		text-align: center;
		z-index: 2;
	}

	.bx-wrapper {
		position: relative;
		z-index: 2;
	}

	.testimonial_slider {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 2;

		p {
			max-width: 62.5rem;
			margin: 0 auto;
			padding: 1.5rem;
			color: variables.$color_nevada;
			font-style: italic;
			font-weight: 600;
			line-height: 1.8;
			letter-spacing: .0365em;
			text-align: center;
			@include mixins.scale(font-size, 1rem, 20em, 1.25rem, 40em);

			&:before {
				content: open-quote;
			}

			&:after {
				content: close-quote;
			}
		}
	}

	& > div {
		position: relative;

		#testimonial_prev {
			position: absolute;
			top: 100%;
			left: 25%;
			z-index: 3;
			width: 3rem;
			height: 3rem;
			@include mixins.flexbox();
			@include mixins.transform(rotate(90deg) translateY(-50%));
			@include mixins.justify-content(center);
			@include mixins.align-items(center);



			@media (min-width: 40em) {
				display: none;
			}

			a {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		#testimonial_next {
			position: absolute;
			top: 100%;
			right: 25%;
			width: 3rem;
			height: 3rem;
			z-index: 3;
			@include mixins.transform(rotate(-90deg) translateY(-50%));
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			@media (min-width: 40em) {
				display: none;
			}

			a {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		
	}

	.testimonial_pager {
		position: relative;
		list-style: none;
		height: 19rem;
		margin: .25rem 0 2rem;
		padding: .5rem 3rem;
		z-index: 2;
		@include mixins.flexbox();
		@include mixins.justify-content(center);

		@media (min-width: 40em) {
			height: 18rem;
			margin-bottom: 0;
		}

		li {
			a {
				position: relative;
				display: none;
				margin: 1rem;

				@media (min-width: 40em) {
					display: block;
				}

				&.active {
					display: block;
				}

				figure {
					position: relative;
					width: 4.375rem;
					height: 4.375rem;
					margin: 0 auto;
					background-color: variables.$color_alto;
					border-radius: 50%;
					overflow: hidden;
					@include mixins.transition(.25s all);

					img {
						position: absolute;
						height: 100%;
						border-radius: 50%;
						@include mixins.object-fit(cover);
					}
				}

				&.active figure {
					width: 5.5rem;
					height: 5.5rem;
				}

				header {
					position: absolute;
					top: 5.375rem;
					bottom: 0;
					left: 50%;
					width: 40vw;
					min-width: 16rem;
					margin-top: .5rem;
					text-align: center;
					opacity: 0;
					@include mixins.transform(translateX(-50%));
					@include mixins.transition(.25s all);

					@media (min-width: $breakpoint_to_horizontal_pager) {
					}

					span {
						display: block;

						&:nth-of-type(1) {
							color: variables.$color_nevada;
							font-weight: 400;
							letter-spacing: .0361em;
							@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 40em);
						}

						&:nth-of-type(2) {
							color: variables.$color_outer_space;
							font-weight: 700;
							letter-spacing: .03625em;
							text-transform: uppercase;
						}
					}
				}

				&.active header {
					top: 6.5rem;
					opacity: 1;
				}
			}
		}
	}
}

.testimonials_home {
	position: relative;
	background-color: variables.$color_white;

	.img_background {
		position: relative;
		height: 0;
		padding-bottom: 75%;
		background-color: rgba(variables.$color_jacarta, .4);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: scroll;

		@media (min-width: 36em) {
			padding-bottom: 32.64%;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(variables.$color_comet, .7);
		}
	}


	.testimonial_box {
		-webkit-box-shadow: 0 0 5px #ccc;
		box-shadow: 0 0 5px #ccc;
		border: 5px solid variables.$color_white;
		background: variables.$color_white;
		max-width: 90%;
		margin-left: 5%;
		height: fit-content;
		padding-bottom: 3rem;
		top: -5rem;
	}

	& > header {
		position: relative;
		color: variables.$color_comet;
		font-size: 2rem;
		letter-spacing: .03625em;
		text-align: center;
		z-index: 2;
	}

	.bx-wrapper {
		max-width: 90%;
		margin-left: 4rem;
		position: relative;
		z-index: 1;
	}

	.testimonial_slider {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 2;

		span {
			display: block;
		}

		.author {
			text-align: center;
		}

		p {
			max-width: 62.5rem;
			margin: 0 auto;
			padding: 1.5rem;
			color: variables.$color_nevada;
			font-style: italic;
			font-weight: 600;
			line-height: 1.8;
			letter-spacing: .0365em;
			text-align: center;
			font-size: 1rem !important;
			@include mixins.scale(font-size, 1rem, 20em, 1.25rem, 40em);

			&:before {
				content: open-quote;
			}

			&:after {
				content: close-quote;
			}
		}
	}

	& > div {
		position: relative;

		#testimonial_prev {
			position: absolute;
			left: 0;
			width: 3rem;
			height: 3rem;
			z-index: 3;
			@include mixins.transform(rotate(90deg) translateY(-50%));
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			@media (min-width: $breakpoint_to_flex_row_interior) {
				top: 8rem;
			}

			a {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		#testimonial_next {
			position: absolute;
			right: 0;
			width: 3rem;
			height: 3rem;
			z-index: 3;
			@include mixins.transform(rotate(-90deg) translateY(-50%));
			@include mixins.flexbox();
			@include mixins.justify-content(center);
			@include mixins.align-items(center);

			@media (min-width: $breakpoint_to_flex_row_interior) {
				top: 8rem;
			}

			a {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}
	}

	.testimonial_pager {
		position: relative;
		list-style: none;
		margin-top: -3.25rem;
		z-index: 2;
		@include mixins.flexbox();
		@include mixins.justify-content(center);


		li {
			a {
				position: relative;
				display: none;
				margin: 1rem;

				@media (min-width: 40em) {
					display: block;
				}

				&.active {
					display: block;
				}

				figure {
					position: relative;
					width: 4.375rem;
					height: 4.375rem;
					margin: 0 auto;
					background-color: variables.$color_alto;
					border-radius: 50%;
					overflow: hidden;
					@include mixins.transition(.25s all);

					img {
						position: absolute;
						height: 100%;
						border-radius: 50%;
						@include mixins.object-fit(cover);
					}
				}

				&.active figure {
					width: 5.5rem;
					height: 5.5rem;
				}

				header {
					position: absolute;
					top: 15.375rem;
					bottom: 0;
					left: 50%;
					width: 40vw;
					min-width: 16rem;
					margin-top: .5rem;
					text-align: center;
					opacity: 0;
					@include mixins.transform(translateX(-50%));
					@include mixins.transition(.25s all);


					@media (min-width: $breakpoint_to_horizontal_pager) {
					}

					span {
						display: block;

						&:nth-of-type(1) {
							color: variables.$color_nevada;
							font-weight: 400;
							letter-spacing: .0361em;
							@include mixins.scale(font-size, .875rem, 20em, 1.125rem, 40em);
						}

						&:nth-of-type(2) {
							color: variables.$color_outer_space;
							font-weight: 700;
							letter-spacing: .03625em;
							text-transform: uppercase;
						}
					}
				}

				&.active header {
					opacity: 1;
				}
			}
		}
	}
}

section[class^=Wave].blend_Top + .testimonials,
section[class^=Wave].blend_Top + a + .testimonials {
	background-color: variables.$color_alabaster;
}

body[data-contrast-mode=on] {
	.testimonials {
		background-color: variables.$color_black;

		&.brokers {
			background-color: variables.$color_black;
		}

		header {
			color: variables.$color_white;
		}

		.testimonial_slider p {
			color: variables.$color_white;
		}

		.testimonial_pager li a header span {
			&:nth-of-type(1),
			&:nth-of-type(2) {
				color: variables.$color_white;
			}
		}
	}
}

body[data-contrast-mode=on] {
	.testimonials_home {
		background-color: variables.$color_black;

		&.brokers {
			background-color: variables.$color_black;
		}

		header {
			color: variables.$color_white;
		}

		.testimonial_slider p {
			color: variables.$color_white;
		}

		.testimonial_pager li a header span {
			&:nth-of-type(1),
			&:nth-of-type(2) {
				color: variables.$color_white;
			}
		}
	}
}