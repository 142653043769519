@use '../base/variables';
@use '../base/mixins';
@use 'sass:color';

#alert {
	position: relative;
	display: none;
	width: 100%;
	text-decoration: none;
	@include mixins.justify-content(space-between);

	&.is_shown {
		@include mixins.flexbox();
	}

	.message {
		@include mixins.flex(1 1 100%);
		color: variables.$color_white;
		padding: 2.5em .75rem .75rem .75rem;
		background: transparent url('../images/sitewide-alert-background.svg') 101% 100% no-repeat scroll;

		.title {
			margin-right: .5rem;
			font-weight: 600;
		}

		.description {
			position: relative;
			top: -.0625rem;
			font-weight: 600;
			font-size: .875rem;
		}
	}

	.close {
		width: 4rem;
		max-width: 4rem;
		cursor: pointer;
		padding-top: 1.5em;
		@include mixins.flex(1 1 auto);
		@include mixins.flexbox();
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		svg path {
			@include mixins.transition(.25s all);
		}
	}

	&.urgent {
		background: variables.$color_urgent_background;

		.close {
			background-color: color.adjust(variables.$color_urgent_background, $hue: .1387deg, $saturation: -.42082 * 1%, $lightness: -7.45098 * 1%);

			&:hover {
				background-color: color.adjust(color.adjust(variables.$color_urgent_background, $hue: .1387deg, $saturation: -.42082 * 1%, $lightness: -7.45098 * 1%), $lightness: -10%);
			}
		}

	}

	&.informational {
		background: variables.$color_informational_background;

		.close {
			background-color: color.adjust(variables.$color_informational_background, $hue: -.10972deg, $saturation: -3.54098 * 1%, $lightness: -8.03922 * 1%);

			&:hover {
				background-color: color.adjust(color.adjust(variables.$color_informational_background, $hue: -.10972deg, $saturation: -3.54098 * 1%, $lightness: -8.03922 * 1%), $lightness: 10%);
			}
		}

	}
}

body[data-contrast-mode=on] {
	#alert {
		background-color: variables.$color_black;
		border-bottom: 1px solid variables.$color_white;

		.close {
			background-color: variables.$color_black;
			border-left: 1px solid variables.$color_white;

			&:hover {
				background-color: variables.$color_white;

				svg path {
					stroke: variables.$color_black;
				}
			}
		}
	}
}