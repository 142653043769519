@use '../base/mixins';

.page_not_found_graphic {
	position: relative;
	width: calc(100% - 3rem);
	max-width: 41.9375rem;
	height: 0;
	margin: 3rem auto 1rem auto;
	padding-bottom: 62.4441132637854%;

	@media (min-width: 42em) {
		height: 26.1875rem;
		padding-bottom: 0;
	}

	svg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;
	}

	& + .interior h2 {
		margin-top: 4rem;
		margin-bottom: 0;
		font-weight: 600;
		text-align: center;
		@include mixins.scale(font-size, 1.5rem, 20em, 3rem, 40em);

	}

	& + .interior section {
		text-align: center;
		@include mixins.scale(font-size, 1rem, 20em, 1.125rem, 40em);

		.max_content_width {
			padding-top: 0;
		}
	}
}