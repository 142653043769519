@use '../base/mixins';
figure {
	margin: 0;

	img {
		display: block;
		width: 100%;
	}

	&.aspect {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;

		&.portrait,
		&.Portrait		{ padding-bottom: 160%; }
		&.square,
		&.Square		{ padding-bottom: 100%; }
		&.traditional,
		&.Traditional	{ padding-bottom: 75%; }
		&.computer,
		&.Computer		{ padding-bottom: 62.5%; }
		&.hd,
		&.HD,
		&.video,
		&.Video,
		&.landscape,
		&.Landscape		{ padding-bottom: 56.25%; }
		&.widescreen,
		&.Widescreen	{ padding-bottom: 54%; }
		&.cinema,
		&.Cinema		{ padding-bottom: 42%; }
		&.banner,
		&.Banner		{ padding-bottom: 25%; }

		img {
			position: absolute;
			height: 100%;
			@include mixins.object-fit(cover); // Use ofi.browser.js polyfill for IE/Edge support
			&.contain {
				@include mixins.object-fit(contain);
			}
		}
	}
}
