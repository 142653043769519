#update_browser {
	background-color: #f2dede;
	border-bottom: 1px solid #eed3d7;
	color: #b94a48;
	margin: 0;
	padding: 0;
}

#update_browser p {
	margin: 0 auto;
	padding: 1em;
	width: 90%;
}

#update_browser a {
	color: #b94a48;
}