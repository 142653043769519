@use '../base/variables';

body[data-contrast-mode=on] {
	abbr.glossarizer_replaced {
		border-color: variables.$color_white;
	}
}

.tooltip {
	color: variables.$color_dove_gray !important;
	background: variables.$color_white !important;
	border-bottom: .6875rem solid variables.$color_comet;
	border-radius: 0 !important;
	box-shadow: 0 0 .9375rem rgba(variables.$color_black, .2);
	cursor: pointer;
	pointer-events: none;
	z-index: 1000 !important;

	&:before {
		top: 0 !important;
		right: 0 !important;
		padding: .5rem 1.125rem;
		color: variables.$color_carrot_orange !important;
		font-size: 1.125rem !important;
		cursor: pointer;
		pointer-events: auto;
	}

	&:after {
		bottom: -1.25rem !important;
		border-top: 10px solid rgba(variables.$color_comet, 1) !important;
	}

	h2 {
		margin-top: .6875rem;
		margin-bottom: .5rem;
		font-size: 1.125rem;
		text-align: left;
		text-transform: capitalize;
	}

	p {
		margin-top: 0;
		margin-bottom: .5rem;
		font-size: .875rem;
		line-height: 1.5;
		text-align: left;
	}
}

.careToolTip {
	margin-top: -6em;
	transform: translate(10%,-10%);
	font-weight: 400;

	&:before {
		font-size: 1rem !important;
		padding: 0 1.125rem !important;

	}
}