@use '../base/variables';
@use '../base/mixins';

body.prior-authorization {
	.bloq.media.content ul {
		list-style: none;
		margin: 0 0 3rem 0;
		padding: 0;

		li {
			display: block;
			padding: .625rem 0;
			font-weight: 600;
			border-bottom: 1px solid variables.$color_mercury;

			a {
				&::after {
					content: url('../svgs/arrows.svg');
					width: .875rem;
					height: .625rem;
					margin-left: .625rem;
				}

				&:hover,
				&:focus {
					&::after {
						content: url('../svgs/arrows-hover.svg');
						color: variables.$color_comet
					}
				}
			}
		}
	}

	section[class^=Wave].blend_Top .bottom {
		fill: white;
	}
}



body.prior-authorization {
	.sticky_nav {
		.max_content_width {
			display: flex;
			justify-content: stretch;

			div {
				flex: auto;
				display: flex;

				a {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: .5rem 1rem;
					line-height: 1.5 !important;
					text-align: center;

					@media (min-width: 30em) {
						display: inline-block;
						padding: 0 1rem;
						line-height: inherit !important;
					}

					&.back {
						border-right: 1px solid #dbdbdb;

						@media (min-width: 30em) {
							border-right: 0;
						}

						svg {
							display: inline-block;
							width: 18px;
							height: 14px;
							margin-bottom: .5rem;

							@media (min-width: 30em) {
								position: relative;
								top: 3px;
								margin-right: .5rem;
								margin-bottom: 0;
							}
						}
					}

					&.new-search svg {
						display: inline-block;
						margin-bottom: .5rem;

						@media (min-width: 30em) {
							position: relative;
							top: 3px;
							margin-right: .5rem;
							margin-bottom: 0;
						}
					}
				}
			}

			.export {
				float: right;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 10rem !important;
				color: variables.$color_white !important;
				line-height: 1.5 !important;
				text-align: center;
				background-color: variables.$color_mantis !important;

				@media (min-width: 30em) {
					line-height: inherit !important;
				}
			}
		}
	}
}