@use '../base/variables';
@use '../base/mixins';
@use '../base/placeholders' as *;

.faq_listing {
	padding: 3rem 1.5rem;
	background-color: variables.$color_alabaster;
}

body[data-contrast-mode='on'] {
	.faq_listing {
		background-color: variables.$color_black;
	}
}

.faq {
	letter-spacing: .039375em;

	.question {
		position: relative;
		display: block;
		padding: 1rem 3rem 1rem .125rem;
		color: variables.$color_comet;
		font-weight: 400;
		font-size: 1.125rem;
		line-height: 1.5;
		border-bottom: 1px solid variables.$color_mercury;

		&:hover,
		&.is_open {
			color: variables.$color_carrot_orange;
		}

		svg {
			position: absolute;
			top: 50%;
			right: .125rem;
			margin: 0;
			@include mixins.transform(translateY(-50%));

			&:nth-of-type(1) {
				margin-top: -.375rem;
				@include mixins.transform(rotate(-90deg));
			}

			&:nth-of-type(2) {
				display: none;
				width: 1rem;
				height: 1rem;

				path {
					fill: variables.$color_carrot_orange;
				}
			}
		}

		&:hover {
			svg {
				&:nth-of-type(1) {
					path {
						fill: variables.$color_carrot_orange !important;
					}
				}

				&:nth-of-type(2) {
					path {
						fill: variables.$color_froly !important;
					}
				}
			}
		}

		&.is_open {
			svg {
				&:nth-of-type(1) {
					display: none;
				}

				&:nth-of-type(2) {
					display: block;
				}
			}
		}
	}

	.answer {
		@extend %clearfix;
		display: none;
		padding-top: .75rem;
		padding-bottom: 1.75rem;
		line-height: 1.6875;
		background: variables.$color_white;
		border: 1px solid variables.$color_mercury;
		border-top: 0;
		@include mixins.scale(padding-right, 1.5rem, 20em, 2.625rem, 40em);
		@include mixins.scale(padding-left, 1.5rem, 20em, 2.625rem, 40em);

		& > br {
			clear: both;
		}

		& > p:first-of-type {
			margin-top: 0;
		}

		& > p:last-of-type {
			margin-bottom: 0;
		}

		figure {
			max-width: 100%;

			@media (min-width: 40em) {
				float: left;
				max-width: 50%;
				margin-right: 1.5rem;

				& + p {
					margin-top: 0;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

body[data-contrast-mode='on'] {
	.faq {
		.question {
			color: variables.$color_white;
			border-color: variables.$color_white;
		}

		.answer {
			color: variables.$color_white;
			background: variables.$color_black;
			border-color: variables.$color_white;
		}
	}
}