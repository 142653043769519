@use '../base/mixins';
@use '../base/variables';

.blog_landing_intro + [class^=Wave] .bottom {
	fill: variables.$color_alabaster;
}

.blog-featured-slider {
	height: 40rem;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			> div {
				height: 40rem;
				background: #ddd center / cover no-repeat scroll;
				@include mixins.flexbox();
				@include mixins.justify-content(center);
				@include mixins.align-items(center);

				> div {
					color: variables.$color_white;
					text-align: center;
					//text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

					.categories {}

					h2 {
						margin-top: .25rem;
						color: variables.$color_white;
						z-index: 3;
					}

					.author {
						font-style: italic;
						font-size: 1.25rem;
						position: relative;
						z-index: 3;
					}

					.button {
						margin-top: 5rem;
						text-shadow: none;
						position: relative;
					}
				}
			}
		}
	}
}

.blog_featured_post {
	max-width: 73rem;
	margin: 0 auto;
	padding: 1.5rem;
	@include mixins.scale(padding-bottom, 5rem, 20em, 7rem, 48em);

	@media (min-width: 48em) {
		padding-top: 5.5rem;
		padding-bottom: 0;
		@include mixins.flexbox();
		@include mixins.justify-content(space-between);
	}

	figure {
		padding-bottom: 83%;

		@media (min-width: 48em) {
			width: 47%;
			max-width: 47%;
			padding-bottom: calc((100vw - 3rem) * .71);
			@include mixins.flex(1 1 auto);
		}

		@media (min-width: 53em) {
			padding-bottom: calc((100vw - 3rem) * .64);
		}

		@media (min-width: 58em) {
			padding-bottom: calc((100vw - 3rem) * .57);
		}

		@media (min-width: 63em) {
			padding-bottom: calc((100vw - 3rem) * .51);
		}

		@media (min-width: 68em) {
			padding-bottom: calc((100vw - 3rem) * .44);
		}

		@media (min-width: 73em) {
			padding-bottom: calc(22rem + 12vw);
		}

		img {

		}
	}

	.content {
		@media (min-width: 48em) {
			max-width: 47%;
			@include mixins.flex(1 1 auto);
		}

		h2 {
			line-height: 1.21875;
			letter-spacing: .0315625em;
		}

		.date {
			letter-spacing: .031875em;
		}

		a {
			font-weight: 600;
		}
	}

	& + [class^=Wave] .bottom {
		fill: variables.$color_alabaster;
	}
}

body[data-contrast-mode=on] {
	.blog_featured_post + [class^=Wave] .bottom {
		fill: variables.$color_black;
	}
}

.blog_listing {
	padding: 3rem 1.5rem;
	border-bottom: 1px solid #dbdbdb;

	& > h2 {
		margin-bottom: .75rem;
	}


	.max_content_width {
		max-width: 73rem;
	}

	.col3_blog {
		padding: 2.5rem 0 4rem 0;

		@media (min-width: variables.$breakpoint_col3_to2) {
			@include mixins.flexbox();
			@include mixins.flex-wrap(wrap);
		}

		&:not(:last-of-type) {
			margin-bottom: 2.75rem;
			border-bottom: 1px solid variables.$color_alto;
		}

		.load_more a {
			@include mixins.justify-content(center);

			@media (min-width: 40em) {
				@include mixins.justify-content(flex-end);
			}
		}
	}

	.col {
		position: relative;
		width: 100%;
		min-height: 23rem;
		margin-bottom: variables.$margin_rows;
		color: variables.$color_nevada;
		font-weight: 400;
		font-size: 1rem;
		text-align: center;

		@media (min-width: 40em) {
			width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			max-width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			@include mixins.flex(1 1 auto);

			&:nth-of-type(2n + 1) {
				margin-right: variables.$margin_columns;
			}
		}

		@media (min-width: 45em) {
			width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			max-width: calc((100% - #{variables.$margin_columns}) / 2.0001);
			@include mixins.flex(1 1 auto);

			&:nth-of-type(2n + 1) {
				margin-right: variables.$margin_columns;
			}
		}

		@media (min-width: 60em) {
			width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);
			max-width: calc((100% - #{variables.$margin_columns * 2}) / 3.0001);

			&:nth-of-type(2n + 1) {
				margin-right: 0;
			}

			&:nth-of-type(8n + 1),
			&:nth-of-type(8n + 2),
			&:nth-of-type(8n + 4),
			&:nth-of-type(8n + 5),
			&:nth-of-type(8n + 7) {
				margin-right: variables.$margin_columns;
			}

			&:nth-of-type(8n + 7),
			&:nth-of-type(8n + 8) {
				width: calc((100% - #{variables.$margin_columns}) / 2.0001);
				max-width: calc((100% - #{variables.$margin_columns}) / 2.0001)
			}
		}

		figure {
			margin-bottom: 1.25rem;
			background: #ddd url('../images/php-icon-gray.png') center no-repeat scroll;

			&.aspect {
				img {
					background-color: variables.$color_white;
					@include mixins.object-fit(scale-down);
				}
			}
		}

		.categories {
			margin-bottom: .25rem;
			padding: 0 1rem;
			color: #8c8c8c;
		}

		.categories.home {
			font-weight: 600;
		}

		h3 {
			position: relative;
			margin-top: 0;
			margin-bottom: .5rem;
			padding: 0 1rem;
			@include mixins.scale(font-size, 1.125rem, 20em, 1.25rem, 60em);
		}

		.author {
			position: relative;
			margin-bottom: 1rem;
			padding: 0 1rem;
			font-style: italic;
			font-size: .875rem;
		}

		.summary {
			font-size: .825rem;
		}
	}
}

.interior .blog_listing {
	background-color: variables.$color_white;
}

body[data-contrast-mode=on] {
	.blog_listing {
		background-color: variables.$color_black;

		.col {
			color: variables.$color_white;

			.categories {
				color: variables.$color_white;
			}
		}
	}
}