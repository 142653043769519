@use '../base/mixins';
@use '../base/variables';

.blog-detail-header {
	max-width: 76.75rem;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 1.5rem 0;
	text-align: center;

	.categories {
		color: #8c8c8c;
		@include mixins.scale(font-size, 1rem, 20rem, 1.25rem, 60rem);
	}

	h1 {
		color: variables.$color_comet;
	}

	.author {
		margin-bottom: 2rem;
		@include mixins.flexbox();
		@include mixins.justify-content(center);
		@include mixins.align-items(center);

		figure {
			width: 3.25rem;
			height: 3.25rem;
			margin-right: 1.5rem;
			padding-bottom: 0;
			border-radius: 50%;
		}

		div {
			strong {
				font-weight: 500;

				&::before {
					content: ',\00a0';
				}
			}
		}
	}

	.summary {
		font-style: italic;
	}
}

.blog_detail {
	position: relative;
	padding: 2rem 1.5rem;

	.blog_share {
		width: 100%;
		max-width: 68rem;
		margin: 3rem auto;
		padding-top: 2rem;
		text-align: center;
		border-top: 1px solid #e0e0e0;

		.button {
			margin: 1rem 0;
		}
	}

	& + .blog_listing {
		padding-top: 4rem;
		background-color: variables.$color_white;
		border-top: 1px solid variables.$color_alto;
	}
}

body[data-contrast-mode=on] {
	.blog-detail-header .categories {
		color: variables.$color_white;
	}

	.blog_detail {
		&:after {
			border-color: variables.$color_white;
		}

		.blog_share {
			border-top-color: variables.$color_white;
		}

		.button svg path {
			fill: variables.$color_black;
		}
	}
}